import {
  AnonymizerHashing,
} from '../libs'
import { AnonymizerError } from './anonymizerError';
import { asteriksingSpecial } from './anonymizerSlackHelper';
import { langErrorMsg } from './lang';
import { emailAliasRE, emailRE } from './patterns';

const emailCheckFormat = (value: any) => (typeof value === 'string') && emailRE.test(value.trim());
const emailAliasCheckFormat = (value: any) => (typeof value === 'string') && emailAliasRE.test(value.trim()) && value.split(',').every(e => emailRE.test(e.trim()));

export class AnonymizerZoom
{
  private readonly anonymizeTextColumns = [/^Topic$/, /User Name$/, /^Name.*/];
  private anonymizeTextColumnsIndexes: number[] = [];
  private hashing: AnonymizerHashing;

  constructor(hashing: AnonymizerHashing)
  {
    this.hashing = hashing;
  }

  anonymizeRow = async (dataRow: any[]): Promise<any[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const dataRowAnonymized: any[] = [];
        for(let index = 0, len = dataRow.length; index < len; index++) {
          const value = dataRow[index];
          if(this.anonymizeTextColumnsIndexes.indexOf(index) !== -1) {
            const valueAsteriksed = asteriksingSpecial(value);
            dataRowAnonymized.push(valueAsteriksed);
            continue;
          }
          if(emailCheckFormat(value)) {
            const valueHashed = await this.hashing.hashEmail(value)
            dataRowAnonymized.push(valueHashed);
            continue;
          }
          if(emailAliasCheckFormat(value)) {
            const valueHashed = await this.hashing.hashEmails(value)
            dataRowAnonymized.push(valueHashed);
            continue;
          }
          dataRowAnonymized.push(value);
        }
        resolve(dataRowAnonymized);
      } catch(error) {
        reject(langErrorMsg.anonymizeErrorGeneral);
      }
    })
  }
  
  anonymize = async (data: any[][]): Promise<any[][]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const dataAnonymized: any[][] = [];
        this.anonymizeTextColumnsIndexes = [];
        data[0].forEach((column: string, index: number) => {
          for(const re of this.anonymizeTextColumns) {
            if(re.test(column)) {
              this.anonymizeTextColumnsIndexes.push(index);
              return;
            }
          }
        });

        dataAnonymized.push([...data[0]]);
        if(data.length > 1) {
          for(let row = 1, rows = data.length; row < rows; row++)
          {
            const dataRow = await this.anonymizeRow(data[row]);
            dataAnonymized.push(dataRow);
          }
        }
        resolve(dataAnonymized);
      } catch(error) {
        reject((error instanceof AnonymizerError) ? error.message : langErrorMsg.anonymizeErrorGeneral);
      }
    })
  }
}