import React, { FC, Fragment } from 'react';
import { LoggerModal } from './LoggerModal'
import { useState } from 'react'

interface Prop {
  title: string;
  loggerEnabled?: boolean;
}


export const Title: FC<Prop> = ({ 
  title,
  loggerEnabled = true,
  children 
}) => {
  const [logger, setLogger] = useState<boolean>(false)
  return (
  <Fragment>
    <div className={`current-step`}>
      <p className={`title`}>
        {title}
        {loggerEnabled ? (
          <span className={`logger`} onClick={() => {
            setLogger(true);
          }}>{'(Log)'}</span>
        ) : null}
      </p>
      {children}
    </div>
    {logger ? (
      <>
        <LoggerModal onClose={() => {
          setLogger(false);
        }} />
      </>
    ) : null}
    <style>{`
      div.current-step {
        width: 100%;
        margin: 33px 0 30px;
        user-select: none;
      }

      div.current-step span.logger {
        margin-left: 10px;
        font-weight: normal;
        font-size: 12px;
        color: gray;
        cursor: pointer;
      }

      div.current-step p.title {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
      }
    `}</style>
  </Fragment>
  )
}