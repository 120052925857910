declare global {
  interface Window {
    chooseFileSystemEntries: (options?: FileSystemOptions) => Promise<FileSystemFileHandle>;
    showSaveFilePicker: (options?: SaveFilePickerOptions) => Promise<FileSystemFileHandle>;
  }

  interface Navigator {
    deviceMemory: number;
  }
}

export type FileSystemExtension = 'zip';

export const FileSystemMIMETypes: { [key in FileSystemExtension]: string } = {
  'zip': 'application/zip'
}

export type FileSystemType = 'save-file';

export interface FileSystemAccepts
{
  description: string;
  extensions: string[];
  mimeTypes: string[];
}

export interface FileSystemOptions
{
  type: FileSystemType;
  name: string;
  accepts: FileSystemAccepts[];
}

export interface SaveFilePickerType
{
  description: string;
  accept: {[key in string]: string[]};
}

export interface SaveFilePickerOptions
{
  excludeAcceptAllOption: boolean;
  types: SaveFilePickerType[];
}

export interface FileSystemQueryPermissionOptions 
{
  writable?: boolean;
}

export type FileSystemPermission = 'granted' | 'prompt' | 'denied';

export type FileSystemData = Uint8Array | ArrayBuffer | Blob | string;

export interface FileSystemFileHandle
{
  isDirectory: boolean;
  isFile: boolean;
  name: string;
  queryPermission: (options: FileSystemQueryPermissionOptions) => Promise<FileSystemPermission>;
  requestPermission: (options: FileSystemQueryPermissionOptions) => Promise<FileSystemPermission>;
  createWritable: () => Promise<FileSystemWritableFileStream>;
  close: () => Promise<void>;
}

export interface FileSystemWritableFileStream
{
  locked: boolean;
  write: (data: FileSystemData) => Promise<void>;
  seek: (offset: number) => Promise<void>;
  truncate: (size: number) => Promise<void>;
  close: () => Promise<void>;
}

export interface FileSystemWriteHandle
{
  handle: FileSystemFileHandle;
  handleWrite: FileSystemWritableFileStream;
}

export const isFileAPISupported = (): boolean => {
  return ('chooseFileSystemEntries' in window || 'showSaveFilePicker' in window);
}

export const isFileAPIFlagSupported = (): boolean => {
  const isMobile = !!(navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i));
  if(isMobile) return false;
  const isSupportedVersion = !!(navigator.userAgent.match(/Chrome\/(83|84|85)/i));
  return isSupportedVersion;
}

export const getFileWriteHandle = (name: string, description: string, extensions: FileSystemExtension): Promise<FileSystemWriteHandle> => {
  return new Promise(async (resolve, reject) => {
    if(!isFileAPISupported())
    {
      reject(`Unable to choose file - FileAPI not supported!`);
      return;
    }
    const MIMEType = FileSystemMIMETypes[extensions];
    try {
      let fileHandle: FileSystemFileHandle;
      if('showSaveFilePicker' in window)
      {
        const acceptFilePicker: {[k: string]: string[]} = {};
        acceptFilePicker[MIMEType] = [`.${extensions}`];
        const opts = {
          excludeAcceptAllOption: true,
          types: [{
            description: description,
            accept: acceptFilePicker
          }]
        }
        fileHandle = await window.showSaveFilePicker(opts);
      } else {
        const opts = {
          type: 'save-file' as FileSystemType,
          name,
          accepts: [{
            description: description,
            extensions: [extensions],
            mimeTypes: [MIMEType],
          }],
        };
        fileHandle = await window.chooseFileSystemEntries(opts);
      }
      if(!fileHandle)
      {
        reject(`Unable to choose file!`);
        return;
      }
      if(!(await fileHandle.queryPermission({ writable: true }) === 'granted')) 
      {
        if(!(await fileHandle.requestPermission({ writable: true }) === 'granted')) 
        {
          reject(`Unable to create writable - permission not granted!`);
          return;
        }
      }
      const fileHandleWritable = await fileHandle.createWritable();
      if(!fileHandleWritable)
      {
        reject(`Unable to create writable - handle invalid!`);
        return;
      }
      resolve({ handle: fileHandle, handleWrite:fileHandleWritable });
    } catch {
      reject(`Unable to create writable - user aborted!`);
    }
  })
}

export const getZIPFileWriteHandle = (name: string, description: string): Promise<FileSystemWriteHandle> => getFileWriteHandle(name, description, 'zip');