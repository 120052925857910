import { Logger, Timer, Memory } from '../libs';
export interface Progress {
  ratio  : number;
  percent: number;
}

export interface ProgressCounterListener
{
  (progress: Progress): any
}

export class ProgressCounter
{
  private counter                  : number;
  private counterAll               : number;
  private listener                 : ProgressCounterListener;
  private lastPercent              : number = -1;
  private memory                   : Memory;
  private timer                    : Timer;

  private accumulator              : number = 0;

  constructor(counterAll: number, listener: ProgressCounterListener)
  {
    this.counter                   = 0;
    this.counterAll                = counterAll;
    this.listener                  = listener;
    this.memory = new Memory();
    this.timer = new Timer();
  }

  countAcc(acc: number)
  {
    this.accumulator += acc;
    this.count();
  }

  count(items: number = 1)
  {
    this.counter += items;
    const ratio = this.counter / this.counterAll;
    const percent = Math.floor(ratio * 100);
    if(percent === this.lastPercent)
    {
      return;
    }
    this.lastPercent = percent;
    this.listener({
      ratio,
      percent: percent
    })
    if(!this.memory.isEnabled()) return;
    Logger.addMessage(`[Mem] ${this.memory.getMemory()} (diff: ${this.timer.diff().toFixed(2)})`);
    if(percent === 100) Logger.addMessage(`[Mem Max] ${this.memory.getMemoryMax()}`);
    this.accumulator = 0;
  }
}