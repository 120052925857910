import React, { FC } from 'react';
import {
  UploadedFile,
  lang,
  
} from '../../../libs'
import {
  Modal,
  defaultTheme
} from '../../../components'

interface Prop
{
  uploadedFile: UploadedFile;
  onCancel: () => any;
  onConfirm: () => any;
  onClose: () => any;
}

export const DeleteFileModal: FC<Prop> = ({ 
  uploadedFile,
  onCancel,
  onConfirm,
  onClose
}) => {
  return (
    <>
      <Modal 
        title={lang.titleDeleteFile}
        leftButton={{title: lang.buttonCancel, theme: defaultTheme.buttons.default, onClick: onCancel}}
        rightButton={{title: lang.buttonDelete, theme: defaultTheme.buttons.red, onClick: onConfirm}}
        justify={`left`}
        onClose={onClose}
      >
        <div className={`modal-uploaded-wrapper`}>
          <h2>{lang.descDeleteFile}</h2>
          <p className={`title-description`}>{`${lang.askDeleteFile}${uploadedFile.name}’?`}</p>
        </div>
        <style>{`
        div.modal-uploaded-wrapper {
        }

        div.modal-uploaded-wrapper > h2 {
          font-size: 13px;
          font-weight: normal;
          margin-bottom: 22px;
        }

        div.modal-uploaded-wrapper p.title-description {
          font-size: 15px;
          font-weight: bold;
        }
        `}</style>
      </Modal>
    </>
  )
}