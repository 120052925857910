export type Listener = (value: any) => any;

let listeners: Listener[] = [];

export class Store
{
  static StoreData: { [key: string]: any } = {};

  static reset(key: string): void {
    delete this.StoreData[key];
  }

  static get(key: string): any {
    return (key in this.StoreData) ? this.StoreData[key] : null;
  }

  static set(key: string, value: any): void {
    this.StoreData[key] = value;
  }

  static addListener(listener: Listener): void {
    listeners.push(listener);
  }

  static removeListener(listener: Listener): boolean {
    const index = listeners.indexOf(listener);
    if(index === -1)
    {
      return false;
    }
    listeners.splice(index, 1);
    return true;
  }
}

