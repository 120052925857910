import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteList, historyHashReplace } from '../../screens'
import {
  Container,
  Header,
  Title,
  ProgressBar,
  Screen
} from '../../components'
import * as zip from "../../libs/ziptools";
import {
  lang,
  UploadedFile,
  StoreANON,
  Logger,
  formatBytes,
  ContentType
} from '../../libs'
import {
  SummaryContent,
  ShowFilesModal,
  FilterChannelsModal,
} from './parts'
import { userKey } from '../../user';

type Tree = { [key in string]: zip.ZipLocalFileHeader[] };
type FolderResult = {
  folder: string;
  maxFileName: string;
  maxFileSize: number;
  totalFiles: number;
  avgFileSize: number;
}
const getAvgFolders = (uploadedFile: UploadedFile) => {
  const files = [...(uploadedFile.data as zip.ZipArchiveReader).getFiles()];
  const tree: Tree = {};
  files.forEach((file: zip.ZipLocalFileHeader) => {
    const [folder, fileName] = file.fileName.split('/');
    if(fileName === undefined) return;
    if(folder === 'teams') {
      const [, team, channel, fileName] = file.fileName.split('/');
      if(fileName === undefined) return;
      const folderTeam = `teams/${team}/${channel}`;
      if(!(folderTeam in tree)) tree[folderTeam] = [];
      tree[folderTeam].push(file);
      return;
    }
    if(!(folder in tree)) tree[folder] = [];
    tree[folder].push(file);
  });
  const folderResults: FolderResult[]  = [];
  (Object.keys(tree)).forEach((folder: string) => {
    tree[folder].sort((a: zip.ZipLocalFileHeader, b: zip.ZipLocalFileHeader) => {
      if(a.uncompsize === b.uncompsize) return 0;
      return a.uncompsize > b.uncompsize ? -1 : 1;
    })
    if(tree[folder].length > 0)
    {
      const count = tree[folder].length;
      const folderResult: FolderResult = {
        folder: folder,
        maxFileName: tree[folder][0].fileName,
        maxFileSize: tree[folder][0].uncompsize,
        totalFiles: count,
        avgFileSize: (tree[folder][0].uncompsize + tree[folder][count - 1].uncompsize) / 2
      }
      folderResults.push(folderResult);
    }
  })
  folderResults.sort((a: FolderResult, b: FolderResult) => {
    if(a.maxFileSize === b.maxFileSize) return 0;
    return a.maxFileSize > b.maxFileSize ? -1 : 1;
  }).forEach(folderResult => {
    Logger.addMessage(JSON.stringify({ ...folderResult, maxFileSize: formatBytes(folderResult.maxFileSize), avgFileSize: formatBytes(folderResult.maxFileSize) }));
  })
}

interface SummaryProps extends RouteComponentProps<{
}> 
{
}

interface SummaryState
{
  loaded        : boolean;
  showFiles     : boolean;
  filterChannels: boolean;
}

export class Summary extends React.Component<SummaryProps, SummaryState>
{
  readonly type: ContentType | null;

  constructor(props: Readonly<SummaryProps>)
  {
    super(props);
    this.type = userKey.getType();
    this.state = this.getDefaultState();
  }

  protected getDefaultState(): SummaryState
  {
    return {
      loaded        : false,
      showFiles     : false,
      filterChannels: false
    }
  }

  componentDidMount()
  {
    if(!userKey.getInitialized()) {
      historyHashReplace(RouteList.source.path, this.props.history);
      return;
    }
    const uploadedFile: UploadedFile | null = StoreANON.getUploadedFile();
    Logger.addMessage(`:: Screen Summary did Mount`)
    if(uploadedFile === null)
    {
      Logger.addMessage(`Uploaded file missing? redirect to Welcome`);
      StoreANON.resetUploadedFileStatus();
      historyHashReplace(RouteList.welcome.path, this.props.history);
      return;
    }
    // LOG - Find Biggest Files
    Logger.addMessage(`Biggest Files in Archive`)
    if(userKey.getType() === 'slack') getAvgFolders(uploadedFile);
    // LOG - Find Biggest Files END
    this.setState({ 
      loaded: true
    })
  }

  private showFiles = () => {
    Logger.addMessage(`:: Screen Summary show files`)
    /* @TODO @DISABLED-FILES this.setState({ showFiles: true }); */
  }

  private filterChannels = () => {
    this.setState({ filterChannels: true });
  }

  private getSummaryContent()
  {
    const uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    const filter: string | null = StoreANON.getFilter();
    return (
      <SummaryContent 
        uploadedFile={uploadedFile}
        type={this.type}
        onShowFiles={this.showFiles}
        onFilterChannels={this.filterChannels}
        onNextStep={() => historyHashReplace(RouteList.anonymization.path, this.props.history)}
      >
        {this.state.showFiles ? this.getShowFilesModal(uploadedFile) : null}
        {this.state.filterChannels ? this.getFilterChannelsModal(filter) : null}
      </SummaryContent>
    )
  }

  private getShowFilesModal(uploadedFile: UploadedFile)
  {
    return (
      <ShowFilesModal
        uploadedFile={uploadedFile}
        onClose={() => {
          this.setState({ showFiles: false });
        }}
        onCancel={() => {
          this.setState({ showFiles: false });
        }}
      />
    )
  }

  private getFilterChannelsModal(filter: string | null)
  {
    return (
      <FilterChannelsModal
        filterInitial={filter}
        onClose={() => {
          this.setState({ filterChannels: false });
        }}
        onConfirm={(filter: string) => {
          StoreANON.setFilter(filter);
          this.setState({ filterChannels: false });
        }}
        onCancel={() => {
          this.setState({ filterChannels: false });
        }}
      />
    )
  }

  render()
  {
    if(!this.state.loaded)
    {
      return null;
    }
    let prevButton = { 
      onClick: () => {
        historyHashReplace(RouteList.welcome.path, this.props.history);
      }, 
      disabled: false
    }
    let nextButton = { 
      onClick: () => {
        historyHashReplace(RouteList.anonymization.path, this.props.history);
      }, 
      disabled: false
    }
    const content = this.getSummaryContent();
    return(
      <Container>
        <Header title={lang.title} />
        <ProgressBar step={2} prevButton={prevButton} nextButton={nextButton} />
        <Screen>
          <div className={`summary-wrapper`}>
            <Title title={`2. ${lang.progressStep2Title}`}/>
            <div className={`summary`}>
            {content}
            </div>
            <style>{`
            .summary-wrapper {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              align-content: flex-start;
              background-color: #F8F9FB;
              height: 100%;
              overflow: auto;
            }

            .summary {
              width: 460px;
              height: auto;
              border-radius: 5px;
              overflow: hidden;
              box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
              background-color: #FFFFFF;
            }
            `}</style>
          </div>
        </Screen>
      </Container>
    )
  }
}

