import React, { FC } from 'react';
import { publicURL } from '../libs';

interface Prop {
  title: string;
}

export const Header: FC<Prop> = ({ title }) => (
  <div className='header-wrapper'>
    <div className='header'>
      <div className={'header-logo'} />
    </div>
    <style>{`
      .header-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 54px;
        position: fixed;
        background: #36006F;
        z-index: 100;
        top: 0;
      }
      
      .header {
        width: calc(100% - 20px);
      }

      .header-logo,
      .logout-wrapper {
        display: inline-block;
        height: 100%;
        float: left;
      }

      .header-logo {
        width: 90%;
        background-image: url("${publicURL}/images/logo.svg");
        background-repeat: no-repeat;
        background-position: 15px 8px;
      }
    `}</style>
  </div>
);