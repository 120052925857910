import {
  BufferLike,
  toBytes,
  ZipArchiveReaderProgressCallback,
  LOCAL_FILE_SIGNATURE,
  END_SIGNATURE,
  ZIP64_CENTRAL_DIRECTORY_LOCATOR,
  ZIP64_CENTRAL_DIRECTORY_END,
  MinTime,
} from './common';
import {
  ZipArchiveReader,
  ZipLocalFileHeader,
  ZipCentralDirHeader,
  ZipEndCentDirHeader,
  readLocalFileHeader,
  readCentralDirHeader,
  readEndCentDirHeader,
  readEndCentDirHeaderLocatorZip64,
  readEndCentDirHeaderZip64,
  isZIP64
} from './zip_archive_reader';

export interface ZipArchiveReaderConstructorParams {
  buffer: BufferLike;
  encoding?: string;
  chunkSize?: number;
  progressCallback?: ZipArchiveReaderProgressCallback;
}

export class ZipBufferArchiveReader extends ZipArchiveReader {
  private bytes: Uint8Array;

  constructor(params: ZipArchiveReaderConstructorParams);
  constructor(buffer: BufferLike, encoding?: string, progressCallback?: ZipArchiveReaderProgressCallback | null, chunkSize?: number);
  constructor(buffer: any, encoding?: string, progressCallback?: ZipArchiveReaderProgressCallback | null, chunkSize?: number) {
    super();
    this.buffer = buffer;
    if(encoding) this.encoding = encoding;
    if(progressCallback) this.progressCallback = progressCallback;
    if(chunkSize) this.chunkSize = chunkSize;
    this.bytes = toBytes(this.buffer);
    this.init = this.init.bind(this);
  }

  init() {
    // let signature: number;
    let localFileHeader: ZipLocalFileHeader;
    let centralDirHeader: ZipCentralDirHeader;
    let endCentDirHeader: ZipEndCentDirHeader;
    let i: number;
    let n: number;
    let bytes = this.bytes;
    let localFileHeaders: ZipLocalFileHeader[] = [];
    let centralDirHeaders: ZipCentralDirHeader[] = [];
    let files: ZipLocalFileHeader[] = [];
    let folders: ZipLocalFileHeader[] = [];
    let offset = bytes.byteLength - 4;
    let view = new DataView(bytes.buffer, bytes.byteOffset, bytes.byteLength);
    const minTime = new MinTime(20);

    this.files = files;
    this.folders = folders;
    this.localFileHeaders = localFileHeaders;
    this.centralDirHeaders = centralDirHeaders;

    // check the first local file signature
    if (view.getUint32(0, true) !== LOCAL_FILE_SIGNATURE) {
      throw new Error('zip.unpack: invalid zip file');
    }

    // read the end central dir header.
    while (true) {
      if (view.getUint32(offset, true) === END_SIGNATURE) {
        endCentDirHeader = readEndCentDirHeader(this.bytes.buffer, offset);
        break;
      }
      offset--;
      if (offset === 0) throw new Error('zip.unpack: invalid zip file');
    }
    this.comment = endCentDirHeader.comment;

    let zip64 = isZIP64(endCentDirHeader);
    if(zip64) {
      
      // ZIP64 Locator
      let zip64CentralEndLocator = null;
      while (true) {
        if (view.getUint32(offset, true) === ZIP64_CENTRAL_DIRECTORY_LOCATOR) {
          zip64CentralEndLocator = readEndCentDirHeaderLocatorZip64(this.bytes.buffer, offset);
          break;
        }
        offset--;
        if (offset === 0) throw new Error('zip.unpack: invalid zip64 file - EndCentralDirectory Locator not found - offset overflow!');
      }
      if (zip64CentralEndLocator === null) throw new Error('zip.unpack: invalid zip64 file - EndCentralDirectory Locator not found!');

      // ZIP64 Header
      offset = zip64CentralEndLocator.relativeOffsetEndOfZip64CentralDir;

      if(view.getUint32(offset, true) !== ZIP64_CENTRAL_DIRECTORY_END)
      {
        throw new Error('zip.unpack: invalid zip64 file - EndCentralDirectory signature invalid!');
      }
      let zip64CentralEnd = readEndCentDirHeaderZip64(this.bytes.buffer, offset);
      
      endCentDirHeader.direntry = zip64CentralEnd.centralDirRecords;
      endCentDirHeader.startpos = zip64CentralEnd.centralDirOffset;
    }

    // read central dir headers.
    offset = endCentDirHeader.startpos;
    for (i = 0, n = endCentDirHeader.direntry; i < n; ++i) {
      centralDirHeader = readCentralDirHeader(this.bytes.buffer, this.bytes.byteOffset + offset);
      centralDirHeader.index = i;
      centralDirHeaders.push(centralDirHeader);
      offset += centralDirHeader.allsize;
    }
    // read local file headers.
    const offsetTotal          = bytes.byteLength;
    let   lastProgress: number = 0;
    const progressCallback     = this.progressCallback;
    for (i = 0; i < n; ++i) {
      offset = centralDirHeaders[i].headerpos;
      localFileHeader = readLocalFileHeader(this.bytes.buffer, this.bytes.byteOffset + offset);
      localFileHeader.index = i;
      localFileHeader.crc32 = centralDirHeaders[i].crc32;
      localFileHeader.compsize = centralDirHeaders[i].compsize;
      localFileHeader.uncompsize = centralDirHeaders[i].uncompsize;
      localFileHeaders.push(localFileHeader);
      if(!progressCallback || !minTime.is()) continue;
      let progress = Math.floor((offset / offsetTotal) * 100);
      if(lastProgress === progress) continue;
      progressCallback({ progress });
      lastProgress = progress;
    }
    if(progressCallback) progressCallback({ progress: Math.floor((offset / offsetTotal) * 100) });
    return this._completeInit();
  }

  protected async _decompressFile(fileName: string) {
    let info = this._getFileInfo(fileName);
    return this._decompress(this.bytes.subarray(info.offset, info.offset + info.length), info.isCompressed);
  }

  protected async _decompressFileByIndex(index: number) {
    let info = this._getFileInfoByIndex(index);
    return this._decompress(this.bytes.subarray(info.offset, info.offset + info.length), info.isCompressed);
  }

  protected _decompressFileSync(fileName: string) {
    let info = this._getFileInfo(fileName);
    return this._decompress(this.bytes.subarray(info.offset, info.offset + info.length), info.isCompressed);
  }
}
