import { emojisRegExp } from "../constants";
import { AnonymizerHashing } from "./anonymizerUtils";

export interface AnonymizedText { 
  emojis : string[];
  smileys: string[];
  users  : string[];
  text   : string | AnonymizedText;
};

const findAll = (value: string, re: RegExp): string[] => Array.from(value.matchAll(re)).map(part => part[0]);
const defaultAnonymizedText = '*** removed for privacy ***';

export const asteriksing = (value: string): string => value.replace(/\S/g, '*');
export const asteriksingSpecial = (value: string): string => value.replace(/[a-zA-Z0-9@\u00C0-\u024F\u1E00-\u1EFF]/g, '*');
export const extractEmojis = (value: string): string[] => findAll(value, emojisRegExp).map(user => user.replace(/^:|:$/g, ''));
// eslint-disable-next-line
export const extractSmileys = (value: string): string[] => findAll(value, /\(?[:;]-?(?:[(|){\[\]]+|\*\)*)/g);
export const extractUsers = (value: string): string[] => findAll(value, /<@([A-Z0-9]+)[^A-Z0-9]/g).map(user => user.replace(/[^A-Z0-9]/g, ''));
export const removeObjectAndString = (value: { [key in string]: any }, key: string): void => {
  if((typeof value[key] === 'string' || value[key] instanceof String) || Array.isArray(value[key]) || (typeof value[key] === 'object'))
  {
    value[key] = defaultAnonymizedText;
  }
}

export class AnonymizerSlackHelper
{
  readonly anonymizerHashing: AnonymizerHashing | null;

  constructor(anonymizerHashing: AnonymizerHashing | null) {
    this.anonymizerHashing = anonymizerHashing;
  }

  anonymizeChannelItem = (item: any) => {
    if(typeof item !== 'object') return item;
    const keys = Object.keys(item);
    const anonymizeKeys = ['topic', 'purpose'];
    for(const key of keys) {
      if(!anonymizeKeys.includes(key)) {
        continue;
      }
      const subkeys = Object.keys(item[key]);
      for(const subkey of subkeys) {
        switch(subkey) {
          case 'value': {
            item[key][subkey] = typeof item[key][subkey] === 'string' ? asteriksingSpecial(item[key][subkey]) : defaultAnonymizedText;
            break;
          }
          case 'creator':
          case 'last_set': {
            continue;
          }
  
          default: {
            item[key][subkey] = defaultAnonymizedText;
          }
        }
      }
    }
  }

  anonymizeUser = async (user: { [key in string]: any }): Promise<{ [key in string]: any }> => {
    const skipKeys = ['id', 'team_id', 'deleted', 'color', 'tz', 'tz_label', 'tz_offset', 'is_admin', 'is_owner', 'is_primary_owner', 'is_restricted', 'is_ultra_restricted', 'is_bot', 'is_stranger', 'updated', 'is_app_user', 'has_2fa', 'locale'];
    const keys = Object.keys(user).filter(key => !skipKeys.includes(key));
    for(const key of keys)
    {
      switch(key)
      {
        case 'real_name':
        case 'name': {
          user[key] = asteriksing(user[key]);
          break;
        }
  
        case 'profile': {
          user[key] = await this.anonymizeUserProfile(user[key]);
          break;
        }
  
        default: {
          removeObjectAndString(user, key);
          break;
        }
      }
    }
    return Promise.resolve(user);
  }

  anonymizeOrgUser = async (user: { [key in string]: any }): Promise<{ [key in string]: any }> => {
    const allowKeys = ['profile', 'real_name', 'name', 'avatar_hash', 'enterprise_user'];
    const keys = Object.keys(user).filter(key => allowKeys.includes(key));
    for(const key of keys)
    {
      switch(key)
      {
        case 'real_name':
        case 'name': {
          user[key] = asteriksing(user[key]);
          break;
        }
  
        case 'profile': {
          user[key] = await this.anonymizeUserProfile(user[key]);
          break;
        }
  
        default: {
          removeObjectAndString(user, key);
          break;
        }
      }
    }
    return Promise.resolve(user);
  }

  anonymizeMessage = async (message: { [key in string]: any }): Promise<{ [key in string]: any }> => {
    const skipKeys = ['type', 'subtype', 'ts', 'user', 'reactions', 'latest_reply', 'replies', 'reply_users', 'reply_count', 'reply_users_count', 'subscribed', 'thread_ts', 'ts', 'upload', 'client_msg_id', 'bot_id', 'display_as_bot', 'inviter', 'edited', 'room', 'channel', 'user_team', 'source_team', 'team', 'last_read', 'parent_user_id'];
    const attachmentKeys = ['attachments'];
    const asteriksingKeys = ['username'];
    const anonymizeKeys = ['text', 'topic', 'purpose'];
    const commentKeys = ['comment'];
    const anonymizeLinkKeys = ['permalink', 'bot_link'];
    const anonymizeFileKeys = ['files'];
    const userProfileKeys = ['user_profile'];
    const rootKeys = ['root'];
    const keys = Object.keys(message).filter(key => !skipKeys.includes(key));
    for(const key of keys)
    {
      if(attachmentKeys.includes(key))
      {
        for(const attachment of message[key]) anonymizeAttachment(attachment);
      }
      else if(asteriksingKeys.includes(key))
      {
        message[key] = asteriksing(message[key]);
      }
      else if(anonymizeKeys.includes(key))
      {
        message[key] = anonymizeText(message[key]);
      }
      else if(commentKeys.includes(key))
      {
        message[key] = anonymizeComment(message[key]);
      }
      else if(anonymizeLinkKeys.includes(key))
      {
        message[key] = anonymizeLink(message[key]);
      }
      else if(anonymizeFileKeys.includes(key))
      {
        for(const file of message[key]) anonymizeFile(file);
      }
      else if(userProfileKeys.includes(key))
      {
        message[key] = await this.anonymizeUserProfile(message[key]);
      }
      else if(rootKeys.includes(key))
      {
        message[key] = await this.anonymizeMessage(message[key]);
      }
      else
      {
        removeObjectAndString(message, key);
      }
    }
    return Promise.resolve(message);
  }

  private anonymizeUserProfile = async (userProfile: { [key in string]: any }): Promise<{ [key in string]: any }> => {
    const asteriksKeys  = ['name', 'last_name', 'first_name', 'real_name', 'display_name', 'real_name_normalized', 'display_name_normalized', 'skype', 'phone'];
    const anonymizeKeys = ['status_text', 'status_text_canonical', 'title'];
    const removeKeys    = ['is_admin', 'is_owner', 'is_primary_owner', 'has_2fa'];
    const skipKeys      = ['id', 'team_id', 'bot_id', 'deleted', 'color', 'tz', 'tz_label', 'tz_offset', 'is_admin', 'is_owner', 'is_primary_owner', 'is_restricted', 'is_ultra_restricted', 'is_bot', 'is_stranger', 'updated', 'is_app_user', 'has_2fa', 'locale', 'status_emoji', 'team', 'avatar_hash', 'guest_channels', 'guest_invited_by', 'api_app_id'];
    if(!this.anonymizerHashing) {
      skipKeys.push('email');
    }
    const imageKeysRE   = /image_.*/;
    const keys = Object.keys(userProfile).filter(key => !skipKeys.includes(key));
    for(const key of keys)
    {
      if(asteriksKeys.includes(key))
      {
        userProfile[key] = asteriksing(userProfile[key]);
      }
      else if(anonymizeKeys.includes(key))
      {
        userProfile[key] = anonymizeText(userProfile[key]);
      }
      else if(key.match(imageKeysRE))
      {
        userProfile[key] = anonymizeLink(userProfile[key]);
      }
      else if(removeKeys.includes(key))
      {
        userProfile[key] = '** removed for safety reasons **'; //@TODO - replace to "*** removed for privacy ***" ?
      }
      else if(key === 'email')
      {
        if(!this.anonymizerHashing) {
          return Promise.reject('Authorization Error - Encryption library missing!');
        }
        userProfile[key] = await this.anonymizerHashing?.hashEmail(userProfile[key]);
      }
      else
      {
        removeObjectAndString(userProfile, key);
      }
    }
    return Promise.resolve(userProfile);
  }
}

export const anonymizeText = (value: string | AnonymizedText): AnonymizedText => {
  if(typeof value === 'string' || value instanceof String)
  {
    return {
      emojis : extractEmojis(value as string),
      smileys: extractSmileys(value as string),
      users  : extractUsers(value as string),
      text   : asteriksingSpecial(value as string)
    }
  }
  if(value.text) {
    value.text = anonymizeText(value.text);
  }
  return value;
}

export const anonymizeLink = (value: string): string => {
  if(!value)
  {
    return value;
  }
  const results = Array.from(value.matchAll(/([a-z]*:)?(.*)/g));
  for(let [, protocol, body] of results)
  {
    protocol = (!protocol) ? '' : protocol;
    return `${protocol}${body.replace(/[^./]/g, '*')}`;
  }
  return asteriksing(value);
}

export const anonymizeFile = (file: { [key in string]: any }): void => {
  const skipKeys          = ['id', 'user', 'created', 'timestamp', 'filetype', 'size', 'mimetype', 'mode', 'external_type'];
  const anonymizeTextKeys = ['username', 'name', 'title', 'plain_text'];
  const anonymizeLinkKeys = ['url_private', 'external_url', 'url_private_download', 'deanimate_gif', 'pjpeg', 'permalink_public', 'permalink', 'edit_link', 'thumb_video', 'thumb_pdf', 'converted_pdf'];
  const keys              = Object.keys(file).filter(key => !skipKeys.includes(key));
  for(const key of keys)
  {
    if(anonymizeTextKeys.includes(key) || key.match(/.*preview.*/))
    {
      file[key] = anonymizeText(file[key]);
    }
    else if(anonymizeLinkKeys.includes(key) || key.match(/^thumb_[0-9]+(?:_[^wh]*){0,1}$/))
    {
      file[key] = anonymizeLink(file[key]);
    }
    else
    {
      removeObjectAndString(file, key);
    }
  }
}

export const anonymizeAttachment = (attachment: { [key in string]: any }): void => {
  const skipKeys          = ['author_id', 'channel_id', 'id', 'is_msg_unfurl', 'mrkdwn_in', 'ts', 'color'];
  const anonymizeTextKeys = ['text', 'title', 'fallback', 'footer'];
  const anonymizeLinkKeys = ['author_icon', 'author_link', 'original_url', 'from_url', 'image_url'];
  const asteriksingKeys   = ['author_name', 'author_name', 'author_subname', 'channel_name'];
  const keys              = Object.keys(attachment).filter(key => !skipKeys.includes(key));
  for(const key of keys)
  {
    if(anonymizeTextKeys.includes(key))
    {
      attachment[key] = anonymizeText(attachment[key]);
    }
    else if(anonymizeLinkKeys.includes(key))
    {
      attachment[key] = anonymizeLink(attachment[key]);
    }
    else if(asteriksingKeys.includes(key))
    {
      attachment[key] = asteriksing(attachment[key]);
    }
    else
    {
      removeObjectAndString(attachment, key);
    }
  }
}

export const anonymizeComment = (value: { [key in string]: any }): { [key in string]: any } => {
  if(value && value.comment)
  {
    value.comment = anonymizeText(value.comment);
  }
  return value;
}
