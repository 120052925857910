import { AnonymizationSettingsDomains, ContentType } from "../libs";

export interface User
{
	identity        : string;
	organizationName: string;
}

class UserKey
{
	private initialized   : boolean = false;
	private type          : ContentType | null = null;
	private anonymizationRequired: boolean = false;
	private anonymizationKey: string                                  = '';
	private anonymizationDomains: AnonymizationSettingsDomains | null = null;

	private key           : string = '';
	private user          : User | null = null;
	private done          : boolean = false;

	constructor()
	{
		this.clear();
	}

	public getInitialized()
	{
		return this.initialized;
	}

	private setInitialized(initialized: boolean)
	{
		this.initialized = initialized;
	}

	public getType(): ContentType | null
	{
		return this.type;
	}

	public setType(type: ContentType)
	{
		this.type = type;
		this.setInitialized(true);
	}

	public getAnonymizationRequired()
	{
		return this.anonymizationRequired;
	}

	public setAnonymizationRequired(anonymizationRequired?: boolean)
	{
		this.anonymizationRequired = Boolean(anonymizationRequired);
	}

  getAnomymizationKey(): string
  {
    return this.anonymizationKey;
  }

  setAnomymizationKey(anonymizationKey: string)
  {
    this.anonymizationKey = anonymizationKey;
  }

  getAnonymizationSettingsDomains(): AnonymizationSettingsDomains | null
  {
    return this.anonymizationDomains;
  }

  setAnonymizationSettingsDomains(anonymizationDomains: AnonymizationSettingsDomains)
  {
    this.anonymizationDomains = anonymizationDomains;
  }


	public getKey()
	{
		return this.key;
	}

	public setKey(key: string)
	{
		this.key = key;
	}

	public getUser(): User | null
	{
		return this.user;
	}

	public setUser(user: User)
	{
		this.user = user;
	}

	public isDone(): boolean
	{
		return this.done;
	}

	public setDone()
	{
		this.done = true;
	}

	public unsetDone()
	{
		this.done = false;
	}

	public clear()
	{
		this.anonymizationRequired  = false;
		this.anonymizationKey     = '';
		this.anonymizationDomains = null;
		this.type                 = null;

		this.key                    = '';
		this.user                   = null;
		this.done                   = false;
	}
}

export const userKey = new UserKey();
