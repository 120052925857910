import React, { FC } from 'react';

export const Container: FC = ({ children }) => (
  <div className='background'>
    <div className='container'>{children}</div>
    <style>{`
      .background {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        position: relative;
        background: #fff;
        z-index: 1;
      }
      
      .container {
        font-family: "Roboto", sans-serif;
        width: 100%;
        height: auto;
        background-image: linear-gradient(-180deg, #f7f7f7 0%, #efefef 100%);
        font-size: 16px;
      }

      button {
        background-color: #7fc53b;
        border: 0;
        padding: 10px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        margin: 5px;
      }

      button:hover, button[disabled] {
        background-color: #a5d96e;
      }

      button.warning {
        background-color: #bc3e3a;
      }

      button.warning:hover, button.warning[disabled] {
        background-color: #fc7e7a;
      }

      button[disabled] {
        cursor: default;
      }
    `}</style>
  </div>
);