import * as React from 'react'
import { Fragment } from 'react'
import * as H from 'history'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Welcome } from './welcome'
import { Anonymization } from './anonymization'
import { Source } from './source'
import { Summary } from './summary'
import { Init } from './auth'


export interface RouteDefinition {
  name: string;
  path: string;
  parent: null | string;
}

export const RouteList: { [key: string]: RouteDefinition; } = {
  init: {
    path: '/',
    name: 'Init',
    parent: null
  },
  source: {
    path: '/select-source',
    name: 'Select source',
    parent: null
  },
  welcome: {
    path: '/import',
    name: 'Welcome',
    parent: null
  },
  summary: {
    path: '/summary',
    name: 'Summary',
    parent: null
  },
  anonymization: {
    path: '/anonymization',
    name: 'Anonymization',
    parent: null
  },
}

export const historyHashReplace = (pathname: string, history: H.History) => {
  history.replace({ pathname, hash: history.location.hash });
}

export const historyHashPush = (pathname: string, history: H.History) => {
  history.push({ pathname, hash: history.location.hash });
}

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Switch>
          <Route 
            exact
            path={RouteList.source.path}
            component={Source} 
          />
          <Route 
            exact
            path={RouteList.welcome.path}
            component={Welcome} 
          />
          <Route 
            exact
            path={RouteList.summary.path}
            component={Summary} 
          />
          <Route 
            exact
            path={RouteList.anonymization.path}
            component={Anonymization} 
          />
          <Route 
            path={RouteList.init.path}
            component={Init} 
          />
          <Redirect path={'*'} to={RouteList.welcome.path} />
        </Switch>
      </Fragment>
    </BrowserRouter>
  )
}

export const Router = () => {
  return (
    <Routes />
  )
}
