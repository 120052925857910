import { isDev } from './index';

export const lang: { [key in string]: string } = {
  title: `Anonymization Tool (BETA)`,
  titleFileUpload: `File Transfer`,
  titleDeleteFile: `Delete file`,
  titleAnonymizeFile: `Anonymize file`,
  titleDownloadFile: `Download file`,
  titleFilterChannels: `Filter files`,
  titleSelectSource: `Select source`,
  titleShowFiles: `Show files`,
  titleAnonymizationProgress: `Anonymization in progress…`,
  titleProgressPercent: `%f% completed`,
  titleProgressFinished: `Finished`,
  titleLogin: `Log in to your account`,
  titleLoading: `Loading…`,
  titleFilename: `Filename`,

  descDeleteFile: `File which is already mapped will be deleted.`,
  descShowFiles: `Showing files list from source archive.`,
  descUploaded: `HR table has successfully uploaded to our server.`,
  descUpload: `Just one last step. Upload safely your mapped table to our system.`,
  descFileUploaded: `Your file has successfully uploaded to our server.`,
  descFileUpload: `Just one last step. Upload safely your file to our system.`,

  askDeleteFile: `Do you wish to delete file `,

  buttonPrev: `Previous step`,
  buttonNext: `Next step`,
  buttonCancel: `Cancel`,
  buttonConfirm: `Confirm`,
  buttonSave: `Save`,
  buttonDelete: `Delete`,
  buttonLoadAnother: `Load again`,
  buttonGoAnonymization: `Go to Anonymization`,
  buttonFinishUpload: `Upload to Time is Ltd.`,
  buttonFinishUploadCancel: `Cancel upload`,


  progressStep1: `Import`,
  progressStep2: `Summary`,
  progressStep3: `Anonymization`,
  progressStep1Title: `Import file for anonymization`,
  progressStep2Title: `Summary`,
  progressStep3Title: `Anonymization`,

  anonymizedFileSaved: `File successfully saved!`
}

export const langByCount: (key: string, count: number) => string = (key: string, count: number): string => {
  switch(key)
  {
    case 'error':
      return (count === 1) ? 'error' : 'errors';

    case 'warning':
      return (count === 1) ? 'warning' : 'warnings';

    default:
      if(isDev) console.warn(`langByCount key "${key}" don't exists`);
      return key;
  }
}

export const langErrorMsg: { [key in string]: string } = {
  invalidType: `**Cannot detect type of uploaded file.** Please try to reupload it again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidSize: `**Size of file you uploaded is bigger than limit.** Please check file and try to reupload it again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  unableInitialize: `Unable to initialize`,
  unableLogin: `Unable to initialize`,
  pageNotFound: `Page not found`,
  slackCompressedManually: `System files users.json or channels.json are missing. Root export folder required.`,
  alreadyAnonymized: `File already anonymized`,
  anonymizeErrorGeneral: `**Unable to anonymize the file.** Please check file and try to reupload it again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`
}