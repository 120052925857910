import React, { FC } from 'react';
import {
  defaultTheme,
  Button
} from '../../../components'
import {
  UploadedFile,
  FileSystemWritableFileStream,
  lang,
  formatBytes
} from '../../../libs'

interface Prop
{
  uploadedFile: UploadedFile;
  outputFileHandle: FileSystemWritableFileStream | null;
  onDownload: () => any;
}

export const ArchivedContent: FC<Prop> = ({ 
  uploadedFile,
  onDownload,
  outputFileHandle,
  children
}) => {
  const fileSize = uploadedFile.dataOutput === null ? null : (typeof uploadedFile.dataOutput === 'string' ? uploadedFile.dataOutput.length : uploadedFile.dataOutput.size);
  return (
    <>
      <div className={`archived-wrapper`}>
        <p className={`archived-filename`}>{uploadedFile.name}</p>
        <p className={`archived-filename-size`}>{outputFileHandle && fileSize !== null ? `File size: ${formatBytes(fileSize)}` : null}</p>
        {!outputFileHandle ? (
          <Button onClick={onDownload} text={lang.titleDownloadFile} disabled={false} theme={defaultTheme.buttons.purple} />
        ) : <p className={`archived-file-saved`}>{lang.anonymizedFileSaved}</p>}
      </div>
      <style>{`
      .archived-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        margin: 60px 40px;
      }

      .archived-filename,
      .archived-filename-size {
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid gray;
      }

      .archived-filename {
        color: #4D009D;
        margin-bottom: 25px;
        padding-bottom: 3px;
      }

      .archived-filename-size {
        font-size: 13px;
        color: #390074;
        margin-bottom: 37px;
        padding-bottom: 2px;
      }

      .archived-file-saved {
        color: #4D009D;
        font-size: 14px;
        text-align: center;
      }
      `}</style>
      {children}
    </>
  )
}