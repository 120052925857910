export * from './ziptools';
export * from './constants';
export * from './crypto';
export * from './types';
export * from './lang';
export * from './store';
export * from './memory';
export * from './storeANON';
export * from './Unzip';
export * from './patterns';
export * from './anonymizerHashing';
export * from './anonymizerWriter';
export * from './anonymizerSlack';
export * from './anonymizerTable';
export * from './anonymizerZoom';
export * from './progressCounter';
export * from './logger';
export * from './fileSystemApi';
export * from './reader';
export * from './utils';
export * from './utilsUTF8';
export * from './anonymizerError';

export const publicURL  = process.env.PUBLIC_URL.trim() === '' ? '' : process.env.PUBLIC_URL;
export const isDev      = process.env.NODE_ENV === 'development'
export const localDebug = false;