import XLSX from 'xlsx';
import {
  AnonymizerTable,
  AnonymizerHashing,
  StoreANON,
} from '../libs';
import { AnonymizerError } from './anonymizerError';
import { AnonymizerZoom } from './anonymizerZoom';
import { langErrorMsg } from './lang';
import { AnonymizationSettingsDomains, ContentType } from './types';

export interface WriterState
{
  progress: boolean;
}

export type AllowedWriterContentType = Omit<ContentType, 'slack'>;

export class Writer 
{
  private anonymizerHashing    : AnonymizerHashing;
  private type                 : AllowedWriterContentType;

  constructor(anonymizationKey: string, anonymizationSettingsDomains: AnonymizationSettingsDomains | null, type: AllowedWriterContentType)
  {
    this.anonymizerHashing = new AnonymizerHashing(anonymizationKey, anonymizationSettingsDomains);
    this.type              = type;
  }

  private prepareData = async (): Promise<any[][]> =>
  {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) =>
    {
      const uploadedFile = StoreANON.getUploadedFile();
      const data         = uploadedFile?.data;

      const anonymizer = (this.type === 'table') ? new AnonymizerTable(this.anonymizerHashing) : new AnonymizerZoom(this.anonymizerHashing);
      const anonymizerPromise = anonymizer.anonymize(data);

      return anonymizerPromise.then((dataProcessed: any[][]) => {
        resolve(dataProcessed);
      }).catch(error => {
        reject(error);
      })
    })
  }

  prepareCSV = async (delimiter: string = ';'): Promise<string | null> => {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadedFile = StoreANON.getUploadedFile();
        if(!uploadedFile) return null;
        const data = await this.prepareData();
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {skipHeader: true, dateNF: 'YYYY-MM-DD'});
        const resultCSV = XLSX.utils.sheet_to_csv(worksheet, { FS: delimiter });
        resolve(resultCSV);
      } catch(error) {
        reject((error instanceof AnonymizerError) ? error.message : langErrorMsg.anonymizeErrorGeneral);
      }
    })
  }
}