import React, { FC, Fragment } from 'react';
import classNames from 'classnames';
import NavigateBefore from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNext from '@material-ui/icons/NavigateNextOutlined';
import Check from '@material-ui/icons/Check';
import { defaultTheme } from './theme'
import { Button } from '../components'
import { lang } from '../libs'

interface ButtonProps {
  onClick: () => any;
  title?: string;
  disabled: boolean;
}

interface Prop {
  step: number;
  finished?: boolean;
  prevButton?: ButtonProps;
  nextButton?: ButtonProps;
}

const steps: string[] = [lang.progressStep1, lang.progressStep2, lang.progressStep3];
const checkMark = <div><Check fontSize={'inherit'} /></div>;

export const ProgressBar: FC<Prop> = ({ 
  step, 
  finished,
  prevButton,
  nextButton,
  children 
}) => (
  <div className={`progress-bar-wrapper`}>
    <div className={`button-part`}>
      {prevButton ? <Button onClick={prevButton.onClick} text={lang.buttonPrev} disabled={prevButton.disabled} theme={defaultTheme.buttons.default}><div className={'icon'}><NavigateBefore fontSize={'inherit'} /></div></Button> : null}
    </div>
    <div className={`progress-bar`}>
      <div className={'progress-bar-items'}>
        {steps.map((title: string, index: number) => {
          const stepCurrent = index + 1;
          let stepTitle: string | JSX.Element = stepCurrent.toString();
          const classes = [`step`];
          const isDone = (stepCurrent < step) || finished;
          if(isDone)
          {
            classes.push(`done`);
            stepTitle = checkMark;
          }
          else if(stepCurrent === step)
          {
            classes.push(`active`);
          }
          return (
            <Fragment key={`progress-bar-step-${index}`}>
              <div className={classNames(classes)}>{stepTitle}</div>
              <div className={`text`}>{title}</div>
              {stepCurrent < 3 ? <div className={'separator'}>{`....`}</div> : null}
            </Fragment>
          )
        })}
      </div>
      {children}
    </div>
    <div className={`button-part right`}>
      {nextButton ? <Button onClick={nextButton.onClick} text={lang.buttonNext} disabled={nextButton.disabled} theme={defaultTheme.buttons.purple}><div className={'icon'}><NavigateNext fontSize={'inherit'} /></div></Button> : null}
    </div>
    <style>{`
      .progress-bar-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 59px;
        position: fixed;
        background: #FFFFFF;
        z-index: 100;
        border-bottom: 1px solid #E5E8EF;
        top: 54px;
        user-select: none;
      }
      
      .button-part {
        margin-left: 15px;
        width: calc(25% - 15px);
      }

      .button-part.right {
        text-align: right;
        margin-right: 15px;
        margin-left: 0;
      }

      .progress-bar {
        display: block;
        width: 50%;
        padding: 19px 0 10px;
        font-size: 16px;
        color: #fff;
      }

      .progress-bar-items {
        font-weight: bold;
        text-align: center;
        margin: 0 auto;
      }

      .progress-bar-items .step,
      .progress-bar-items .text,
      .progress-bar-items .separator {
        font-size: 13px;
        display: inline-block;
        height: 20px;
        line-height: 20px;
        vertical-align: middle;
      }

      .progress-bar-items .step {
        border-radius: 15px;
        color: #000000;
        border: 1px solid gray;
        text-align: center;
        width: 16px;
        height: 16px;
        padding: 1px 2px 3px 2px;
      }

      .progress-bar-items .step.active, 
      .progress-bar-items .step.done {
        color: #FFFFFF;
      }

      .progress-bar-items .step.active {
        background-color: #530EAD;
        border-color: #530EAD;
      }

      .progress-bar-items .step.done {
        background-color: #7EC53C;
        border-color: #7EC53C;
        padding: 2px 2px 2px 2px;
      }

      .progress-bar-items .step.done div {
        font-size: 16px;
      }

      .progress-bar-items .text {
        margin-left: 10px;
        color: #333;
      }

      .progress-bar-items .separator {
        color: gray;
        margin: 0 10px;
      }

      .progress-bar-wrapper .button-part .icon {
        font-size: 21px;
        display: block;
        height: 20px;
        float: left;
        margin-left: -4px;
        margin-right: 12px;
        margin-top: -1px;
      }
    `}</style>
  </div>
);