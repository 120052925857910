import React, { FC } from 'react';
import * as zip from "../../../libs/ziptools";
import {
  UploadedFile,
  lang,
} from '../../../libs'
import {
  Modal,
  defaultTheme
} from '../../../components'

interface Prop
{
  uploadedFile: UploadedFile;
  onCancel: () => any;
  onClose: () => any;
}

const isRootFile = (filename: string) => filename.match(/(\\|\/)/) === null ? true : false;

export const ShowFilesModal: FC<Prop> = ({ 
  uploadedFile,
  onCancel,
  onClose
}) => {
  const files = [...(uploadedFile.data as zip.ZipArchiveReader).getFileNames()].sort((a: string, b: string) => {
    if(isRootFile(a) && isRootFile(b)) return 0;
    return isRootFile(a) ? -1 : 1;
  });
  const filesList = files.map((entry: string, index: number) => (<tr key={`filelist-row-${index}`}><td>{entry}</td></tr>))
  return (
    <>
      <Modal 
        title={lang.titleShowFiles}
        leftButton={{title: lang.buttonCancel, theme: defaultTheme.buttons.default, onClick: onCancel}}
        justify={`left`}
        onClose={onClose}
        width={800}
      >
        <div className={`modal-show-files-wrapper`}>
          <h2>{lang.descShowFiles}</h2>
          <div className={`modal-show-files-table-wrapper`}>
            <table className={`files-table`}>
              <thead>
                <tr>
                  <th>{lang.titleFilename}</th>
                </tr>
              </thead>
              <tbody>
                {filesList}
              </tbody>
            </table>
          </div>
        </div>
        <style>{`
        div.modal-show-files-wrapper {
          width: 100%;
        }

        div.modal-show-files-wrapper div.modal-show-files-table-wrapper {
          max-height: 60vh;
          overflow-y: scroll;
        }

        div.modal-show-files-wrapper div.modal-show-files-table-wrapper table {
          width: 100%;

        }

        div.modal-show-files-wrapper div.modal-show-files-table-wrapper table tr td {
          padding: 1px 5px;
          border-bottom: 1px solid #ccc;
        }

        div.modal-show-files-wrapper > h2 {
          font-size: 13px;
          font-weight: normal;
          margin-bottom: 22px;
        }

        div.modal-show-files-wrapper p.title-description {
          font-size: 15px;
          font-weight: bold;
        }

        div.modal-show-files-wrapper table.files-table td {
          cursor: default;
        }
        `}</style>
      </Modal>
    </>
  )
}