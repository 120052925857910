export type WindowPerformance = {
  memory?: {
    jsHeapSizeLimit: number;
    totalJSHeapSize: number;
    usedJSHeapSize : number;
  }
}

export interface MemoryValue
{
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize : number;
}

export const isSupportedPerformanceMemory = (): boolean => {
  if(!('performance' in window)) return false;
  const windowPerformance = (window.performance as WindowPerformance);
  if(!('memory' in windowPerformance)) return false;
  return true;
}

export const getPerformanceMemory = (): MemoryValue | null => {
  if(!isSupportedPerformanceMemory()) return null;
  return ((window.performance as WindowPerformance).memory! as MemoryValue);
}

export class Memory
{
  private  enabled: boolean = false;
  private  memoryMax: MemoryValue;
  readonly MB: number = 1000000; //MB in bytes
  
  constructor()
  {
    this.memoryMax = { jsHeapSizeLimit: -1, totalJSHeapSize: -1, usedJSHeapSize: -1 };
    if(!isSupportedPerformanceMemory()) return;
    this.enabled = true;
  }

  isEnabled()
  {
    return this.enabled;
  }

  getMemory(verbose: boolean = true): string | MemoryValue
  {
    if(!this.enabled)
    {
      return (verbose) ? `Memory management not enabled!` : { ...this.memoryMax };
    }
    const memory = (window.performance as WindowPerformance).memory!;
    const jsHeapSizeLimit = memory.jsHeapSizeLimit;
    const totalJSHeapSize = memory.totalJSHeapSize;
    const usedJSHeapSize  = memory.usedJSHeapSize;
    this.memoryMax.jsHeapSizeLimit = Math.max(this.memoryMax.jsHeapSizeLimit, jsHeapSizeLimit);
    this.memoryMax.totalJSHeapSize = Math.max(this.memoryMax.totalJSHeapSize, totalJSHeapSize);
    this.memoryMax.usedJSHeapSize  = Math.max(this.memoryMax.usedJSHeapSize, usedJSHeapSize);
    if(!verbose) return { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize };
    return `Memory Limit: ${this.bytes2Mb(jsHeapSizeLimit)}MB, Heap: ${this.bytes2Mb(totalJSHeapSize)}MB, Used: ${this.bytes2Mb(usedJSHeapSize)}MB`;
  }

  getMemoryMax(verbose: boolean = true): string | MemoryValue
  {
    if(!this.enabled)
    {
      return (verbose) ? `Memory management not enabled!` : { ...this.memoryMax };
    }
    const jsHeapSizeLimit = this.memoryMax.jsHeapSizeLimit;
    const totalJSHeapSize = this.memoryMax.totalJSHeapSize;
    const usedJSHeapSize  = this.memoryMax.usedJSHeapSize;
    if(!verbose) return { ...this.memoryMax };
    return `Memory Limit: ${this.bytes2Mb(jsHeapSizeLimit)}MB, Heap: ${this.bytes2Mb(totalJSHeapSize)}MB, Used: ${this.bytes2Mb(usedJSHeapSize)}MB`;
  }

  private bytes2Mb = (bytes: number): string => (bytes / this.MB).toFixed(2);
}