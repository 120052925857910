import {
  BufferLike,
  concatBytes,
  detectEncoding,
  bytesToString,
  ZipArchiveReaderProgressCallback,
  MAX_VALUE_16BITS,
  MAX_VALUE_32BITS
} from './common';
import * as pako from 'pako';

export interface ZipLocalFileHeader {
  index: number;
  signature: number;
  needver: number;
  option: number;
  comptype: number;
  filetime: number;
  filedate: number;
  crc32: number;
  compsize: number;
  uncompsize: number;
  fnamelen: number;
  extralen: number;
  headersize: number;
  allsize: number;
  fileNameAsBytes: Uint8Array;
  fileName: string;
}

export interface ZipCentralDirHeader extends ZipLocalFileHeader {
  madever: number;
  commentlen: number;
  disknum: number;
  inattr: number;
  outattr: number;
  headerpos: number;
  allsize: number;
}

export interface ZipEndCentDirHeader {
  signature: number;
  disknum: number;
  startdisknum: number;
  diskdirentry: number;
  direntry: number;
  dirsize: number;
  startpos: number;
  commentlen: number;
  comment: Uint8Array | null;
}

export interface Zip64EndCentDirHeaderLocator {
  diskWithZip64CentralDirStart: number;
  relativeOffsetEndOfZip64CentralDir: number;
  disksCount: number;
}

export interface Zip64EndCentDirHeader {
  zip64EndOfCentralSize: number;
  versionMadeBy: number;
  versionNeeded: number;
  diskNumber: number;
  diskWithCentralDirStart: number;
  centralDirRecordsOnThisDisk: number;
  centralDirRecords: number;
  centralDirSize: number;
  centralDirOffset: number;
}

export const bytes2Ascii = (fileNameAsBytes: Uint8Array): string | null => {
  if(Array.from(fileNameAsBytes).find(code => code > 127))
  {
    // throw new Error(`bytes2Ascii Error - unsupported encoding!`);
    return null;
  }
  const fileName: string[] = Array.from(fileNameAsBytes).map(code => String.fromCharCode(code));
  return fileName.join('');
}

/**
 * ZipArchiveReader
 *
 * @example
 * jz.zip.unpack(buffer).then(reader => {
 *   const fileNames = reader.getFileNames();
 *   reader.readFileAsText(fileNames[0]).then(text => {
 *     console.log(text);
 *   });
 *   // You can use sync methods in web worker.
 *   console.log(reader.readFileAsTextSync(fileNames[0]));
 * });
 */
export abstract class ZipArchiveReader {
  protected d: Date = new Date();
  protected buffer: BufferLike = new ArrayBuffer(0);
  protected chunkSize: number = 512;
  protected encoding: string = 'UTF-8';
  protected headerSpeedup: boolean = false;
  protected progressCallback: ZipArchiveReaderProgressCallback | null = null;

  protected files: ZipLocalFileHeader[] = [];
  protected folders: ZipLocalFileHeader[] = [];
  protected localFileHeaders: ZipLocalFileHeader[] = [];
  protected centralDirHeaders: ZipCentralDirHeader[] = [];
  protected comment: Uint8Array | null = null;

  abstract init(): Promise<this>;
  protected abstract _decompressFile(fileName: string): Promise<Uint8Array>;
  protected abstract _decompressFileByIndex(index: number): Promise<Uint8Array>;

  constructor() {
    this.d = new Date();
  }

  t(output: boolean = false) {
    const d = new Date();
    const diff = d.getTime() - this.d.getTime();
    this.d = d;
    if(output) console.log('diff', diff);
    return diff;
  }

  setHeaderSpeedup(headerSpeedup: boolean) {
    this.headerSpeedup = headerSpeedup;
  }

  getFileNames() {
    return this.files.map(f => f.fileName);
  }

  getFiles() {
    return this.files;
  }

  getFolders() {
    return this.folders;
  }

  getComment() {
    return this.comment;
  }

  readFileAsArrayBuffer(fileName: string) {
    return this._decompressFile(fileName).then(bytes => bytes.buffer);
  }

  readFileAsBlob(fileName: string, contentType?: string) {
    return this._decompressFile(fileName).then(bytes => new Blob([bytes], { type: contentType }));
  }

  readFileAsText(fileName: string, encoding = 'UTF-8'): Promise<string> {
    return new Promise((resolve, reject) => {
      this._decompressFile(fileName).then(bytes => {
        const fr = new FileReader();
        fr.onload = () => resolve(fr.result as string);
        fr.onerror = err => reject(err);
        fr.readAsText(new Blob([bytes]), encoding);
      });
    })
  }

  readFileAsTextByIndex(index: number, encoding = 'UTF-8'): Promise<string> {
    return new Promise((resolve, reject) => {
      this._decompressFileByIndex(index).then(bytes => {
        const fr = new FileReader();
        fr.onload = () => resolve(fr.result as string);
        fr.onerror = err => reject(err);
        fr.readAsText(new Blob([bytes]), encoding);
      });
    })
  }

  protected async _completeInit() {
    let files = this.files;
    let folders = this.folders;
    let localFileHeaders = this.localFileHeaders;
    localFileHeaders.forEach(header => {
      // Is the last char '/'.
      (header.fileNameAsBytes[header.fileNameAsBytes.length - 1] !== 47 ? files : folders).push(header);
    });
    // detect encoding. cp932 or utf-8.
    let encoding = this.encoding;
    if (encoding === null) {
      encoding = detectEncoding(concatBytes(localFileHeaders.slice(0, 100).map(header => header.fileNameAsBytes)));
    }
    // @SPEED-UP bytes2Ascii when possible, otherwise old way bytesToString
    for(const h of localFileHeaders)
    {
      let fileName = bytes2Ascii(h.fileNameAsBytes);
      h.fileName = (fileName === null) ? await bytesToString(h.fileNameAsBytes, encoding) : fileName;
    }
    return this;
  }

  protected _getFileInfo(fileName: string) {
    const i = this.localFileHeaders.findIndex(localFileHeader => localFileHeader.fileName === fileName);
    if(i === -1) throw new Error('File is not found.');
    let centralDirHeader = this.centralDirHeaders[i];
    let localFileHeader = this.localFileHeaders[i];
    return {
      offset: centralDirHeader.headerpos + localFileHeader.headersize,
      length: centralDirHeader.compsize,
      isCompressed: !!centralDirHeader.comptype,
    };
  }

  protected _getFileInfoByIndex(index: number) {
    if(index < 0 || this.localFileHeaders.length < (index - 1)) throw new Error('File is not found.');
    let centralDirHeader = this.centralDirHeaders[index];
    let localFileHeader = this.localFileHeaders[index];
    return {
      offset: centralDirHeader.headerpos + localFileHeader.headersize,
      length: centralDirHeader.compsize,
      isCompressed: !!centralDirHeader.comptype,
    };
  }

  protected _decompress(bytes: Uint8Array, isCompressed: boolean) {
    if(!isCompressed) return new Uint8Array(bytes);
    return pako.inflateRaw(bytes);
  }
}

export function readEndCentDirHeader(buffer: ArrayBuffer, offset: number) {
  let view = new DataView(buffer, offset);
  const commentlen = view.getUint16(20, true)
  const comment = commentlen ? new Uint8Array(buffer.slice(offset + 22)) : null;
  return {
    signature: view.getUint32(0, true),
    disknum: view.getUint16(4, true),
    startdisknum: view.getUint16(6, true),
    diskdirentry: view.getUint16(8, true),
    direntry: view.getUint16(10, true),
    dirsize: view.getUint32(12, true),
    startpos: view.getUint32(16, true),
    commentlen,
    comment
  };
}

export function isZIP64(endCentDirHeader: ZipEndCentDirHeader) {
  if(
    endCentDirHeader.disknum === MAX_VALUE_16BITS || 
    endCentDirHeader.startdisknum === MAX_VALUE_16BITS || 
    endCentDirHeader.diskdirentry === MAX_VALUE_16BITS || 
    endCentDirHeader.direntry === MAX_VALUE_16BITS || 
    endCentDirHeader.dirsize === MAX_VALUE_32BITS || 
    endCentDirHeader.startpos === MAX_VALUE_32BITS
  ) {
    return true;
  }
  return false;
}

export function readEndCentDirHeaderLocatorZip64(buffer: ArrayBuffer, offset: number) {
  let view = new DataView(buffer, offset);
  let zip64CentralDirHeaderLocator: Zip64EndCentDirHeaderLocator = {
    diskWithZip64CentralDirStart: view.getUint32(4, true),
    relativeOffsetEndOfZip64CentralDir: view.getUint32(8, true),
    disksCount: view.getUint32(16, true)
  };
  // signature: view.getUint32(0, true),
  // zip64CentralDirHeaderLocator.relativeOffsetEndOfZip64CentralDir32 = view.getUint32(12, true); //@Skiped 64bit - cut to 32bits
  return zip64CentralDirHeaderLocator;
}

export function readEndCentDirHeaderZip64(buffer: ArrayBuffer, offset: number) {
  let view = new DataView(buffer, offset);
  let zip64CentralDirHeader: Zip64EndCentDirHeader = {
    zip64EndOfCentralSize: view.getUint32(4, true),
    versionMadeBy: 0,
    versionNeeded: 0,
    diskNumber: view.getUint32(16, true),
    diskWithCentralDirStart: view.getUint32(20, true),
    centralDirRecordsOnThisDisk: view.getUint32(24, true),
    centralDirRecords: view.getUint32(32, true),
    centralDirSize: view.getUint32(40, true),
    centralDirOffset: view.getUint32(48, true)
  };
  // signature: view.getUint32(0, true),
  // zip64CentralDirHeader.zip64EndOfCentralSize32 = view.getUint32(8, true);
  // zip64CentralDirHeader.versionMadeBy = view.getUint16(12, true);
  // zip64CentralDirHeader.versionNeeded = view.getUint16(14, true);
  // zip64CentralDirHeader.centralDirRecordsOnThisDisk32 = view.getUint32(28, true);
  // zip64CentralDirHeader.centralDirRecords32 = view.getUint32(36, true);
  // zip64CentralDirHeader.centralDirSize32 = view.getUint32(44, true);
  // zip64CentralDirHeader.centralDirOffset32 = view.getUint32(52, true);
  return zip64CentralDirHeader;
}

export function readCentralDirHeader(buffer: ArrayBuffer, offset: number) {
  let view = new DataView(buffer, offset);
  let bytes = new Uint8Array(buffer, offset);
  const fnamelen = view.getUint16(28, true);
  let centralDirHeader: ZipCentralDirHeader = {
    index: -1,
    signature: view.getUint32(0, true),
    madever: view.getUint16(4, true),
    needver: view.getUint16(6, true),
    option: view.getUint16(8, true),
    comptype: view.getUint16(10, true),
    filetime: view.getUint16(12, true),
    filedate: view.getUint16(14, true),
    crc32: view.getUint32(16, true),
    compsize: view.getUint32(20, true),
    uncompsize: view.getUint32(24, true),
    fnamelen,
    extralen: view.getUint16(30, true),
    commentlen: view.getUint16(32, true),
    disknum: view.getUint16(34, true),
    inattr: view.getUint16(36, true),
    outattr: view.getUint32(38, true),
    headerpos: view.getUint32(42, true),
    fileNameAsBytes: bytes.subarray(46, 46 + fnamelen),
    fileName: '',
    allsize: 0,
    headersize: 0    
  };
  centralDirHeader.allsize = 46 + centralDirHeader.fnamelen + centralDirHeader.extralen + centralDirHeader.commentlen;
  // console.log('centralDirHeader.extralen', centralDirHeader.extralen, centralDirHeader.compsize, centralDirHeader.uncompsize);
  /*
  // console.log('Extra?', centralDirHeader.extralen, centralDirHeader.compsize, centralDirHeader.uncompsize, centralDirHeader.headerpos);
  if(centralDirHeader.extralen > 0) // FORCE ZIP64
  {
    let seek = 46 + centralDirHeader.fnamelen;
    const extraId  = view.getUint16(seek, true);
    seek += 2;
    const extraLen = view.getUint16(seek, true);
    seek += 2;
    const uncompressedSize = view.getUint32(seek, true);
    seek += 8; //64 bit, read only 32
    const compressedSize = view.getUint32(seek, true);
    seek += 8; //64 bit, read only 32
    const localHeaderOffset = view.getUint32(seek, true);
    console.log('Extra!', extraId, extraLen, uncompressedSize, compressedSize, localHeaderOffset);
  }
  */
  return centralDirHeader;
}

export function readLocalFileHeader(buffer: ArrayBuffer, offset: number) {
  let view = new DataView(buffer, offset);
  let bytes = new Uint8Array(buffer, offset);
  let localFileHeader: ZipLocalFileHeader = {
    index: -1,
    signature: view.getUint32(0, true),
    needver: view.getUint16(4, true),
    option: view.getUint16(6, true),
    comptype: view.getUint16(8, true),
    filetime: view.getUint16(10, true),
    filedate: view.getUint16(12, true),
    crc32: view.getUint32(14, true),
    compsize: view.getUint32(18, true),
    uncompsize: view.getUint32(22, true),
    fnamelen: view.getUint16(26, true),
    extralen: view.getUint16(28, true),
    headersize: 0,
    allsize: 0,
    fileNameAsBytes: new Uint8Array(),
    fileName: ''
  };
  localFileHeader.headersize = 30 + localFileHeader.fnamelen + localFileHeader.extralen;
  localFileHeader.allsize = localFileHeader.headersize + localFileHeader.compsize;
  localFileHeader.fileNameAsBytes = bytes.subarray(30, 30 + localFileHeader.fnamelen);
  return localFileHeader;
}
