import React, { FC } from 'react';
import { lang } from '../../../libs'
import { BoxLoader } from '../../../components'

interface Prop
{
  filename: string | null;
  percent : number;
}

const loader = <BoxLoader />;

export const LoaderContent: FC<Prop> = ({ 
  filename,
  percent
}) => {
  const progress = percent < 100 ? lang.titleProgressPercent.replace('%f', percent.toString()) : lang.titleProgressFinished;
  return (
    <>
      <div className={`loader-wrapper`}>
        <div className={`loader`}>
          {loader}
        </div>
        <p className={`loader-progress`}>{progress}</p>
        <p className={`loader-filename`}>{filename}</p>
      </div>
      <style>{`
      .loader-wrapper {
        width: 460px;
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
      }
      .loader-wrapper .loader {
        margin: 128px 0 26px;
      }

      .loader-progress,
      .loader-filename {
        font-size: 16px;
        text-align: center;
        margin-bottom: 12px;
      }
      .loader-filename {
        margin-bottom: 108px;
      }
      `}</style>
    </>

  )
}