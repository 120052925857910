import React, { FC } from 'react';
import {
  UploadedFile,
  AnonymizationProgress,
  lang
} from '../../../libs'

interface Prop
{
  uploadedFile: UploadedFile;
  progress: AnonymizationProgress;
}

const ucfirst = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

export const ProgressContent: FC<Prop> = ({ 
  uploadedFile,
  progress,
  children
}) => {
  const percent = (progress.percent === null) ? null : progress.percent < 100 ? lang.titleProgressPercent.replace('%f', progress.percent.toString()) : lang.titleProgressFinished;
  return (
    <>
      <div className={`loaded-wrapper`}>
        <p className={`loaded-filename`}>{uploadedFile.name}</p>
        <p className={`anonymization-phase`}>{ucfirst(progress.phase)}</p>
        <p className={`anonymization-percent`}>{percent ? percent : '\u00A0'}</p>
      </div>
      <style>{`
      .loaded-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        margin: 60px 40px;
      }

      .loaded-wrapper .loaded-extension {
        font-size: 16px;
        background-color: blue;
        color: white;
        padding: 26px 20px;
        border-radius: 35px;
        text-transform: uppercase;
      }

      .loaded-filename {
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid gray;
      }

      .loaded-filename {
        color: blue;
        margin-bottom: 25px;
        padding-bottom: 3px;
      }

      .anonymization,
      .anonymization-phase,
      .anonymization-percent {
        font-size: 13px;
        color: #4D009D;
        margin-bottom: 35px;
        padding-bottom: 2px;
      }

      .anonymization-percent {
        margin-bottom: 60px;
      }
      `}</style>
      {children}
    </>
  )
}