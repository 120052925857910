import {
  BufferLike,
  toBytes,
  LOCAL_FILE_SIGNATURE,
  DATA_DESCRIPTOR_SIGNATURE,
  CENTRAL_DIR_SIGNATURE,
  END_SIGNATURE,
  ZIP64_CENTRAL_DIRECTORY_LOCATOR,
  ZIP64_CENTRAL_DIRECTORY_END,
  MAX_VALUE_16BITS,
  MAX_VALUE_32BITS
} from './common';
import { crc32 } from './core';
// import { deflate } from '../stream/core';
import * as pako from 'pako';

export interface ZipArchiveWriterConstructorParams {
  shareMemory?: boolean;
  chunkSize?: number;
  comment?: Uint8Array;
}

/**
 * ZipArchiveWriter
 *
 * @example
 * const writer = new ZipArchiveWriter();
 * writer.on("data", chunk => console.log(chunk));
 * writer.on("end", () => console.log(chunk));
 * writer.writeFile("foo.mp3", mp3Buffer);
 * writer.writeFile("bar.txt", "hello world!");
 * wirter.writeEnd();
 */
export class ZipArchiveWriter {
  private dirs: { [key: string]: boolean } = {};
  private centralDirHeaders: Uint8Array[] = [];
  private offset = 0;
  private date = new Date();
  private listeners: { [key: string]: Function[] } = {};
  readonly shareMemory: boolean;
  readonly chunkSize: number;
  readonly comment: Uint8Array;

  constructor(params: ZipArchiveWriterConstructorParams = {}) {
    this.shareMemory = !!params.shareMemory;
    this.chunkSize = params.chunkSize || 512;
    this.comment = params.comment ?? new Uint8Array(0);
  }

  write(path: string, buffer: BufferLike, level?: number) {
    path.split('/').reduce((parent, child) => {
      this.writeDir(parent + '/');
      return `${parent}/${child}`;
    });
    this.writeFile(path, buffer, level);
  }

  writeSync(path: string, buffer: BufferLike, level?: number) {
    const dirOutput: Uint8Array[] = [];
    path.split('/').reduce((parent, child) => {
      dirOutput.push(this.writeDirSync(parent + '/'));
      return `${parent}/${child}`;
    });
    const outputFile = this.writeFileSync(path, buffer, level);
    let dirOutputSize = dirOutput.reduce(((sum, dir) => sum + dir.length), 0);
    const output = new Uint8Array(outputFile.length + dirOutputSize);
    let offset = 0;
    for(const dir of dirOutput)
    {
      output.set(dir, offset);
      offset += dir.length;
    }
    output.set(outputFile, offset);
    return output;
  }

  writeDir(path: string) {
    let localFileHeader: Uint8Array;
    path += /.+\/$/.test(path) ? '' : '/';
    if (!this.dirs[path]) {
      this.dirs[path] = true;
      let pathAsBytes = toBytes(path);
      localFileHeader = createLocalFileHeader(pathAsBytes, this.date, false);
      this.centralDirHeaders.push(createCentralDirHeader(pathAsBytes, this.date, false, this.offset, 0, 0, 0));
      this.trigger('data', localFileHeader);
      this.offset += localFileHeader.length;
    }
    return this;
  }

  writeDirSync(path: string): Uint8Array {
    let localFileHeader: Uint8Array = new Uint8Array(0);
    path += /.+\/$/.test(path) ? '' : '/';
    if (!this.dirs[path]) {
      this.dirs[path] = true;
      let pathAsBytes = toBytes(path);
      localFileHeader = createLocalFileHeader(pathAsBytes, this.date, false);
      this.centralDirHeaders.push(createCentralDirHeader(pathAsBytes, this.date, false, this.offset, 0, 0, 0));
      this.offset += localFileHeader.length;
    }
    return localFileHeader;
  }

  writeFile(path: string, buffer: BufferLike, level?: number) {
    let pathAsBytes = toBytes(path);
    let offset = this.offset;
    let localFileHeader = createLocalFileHeader(pathAsBytes, this.date, !!level);
    let compressedSize = 0;
    let dataDescriptor: Uint8Array;
    let _crc32: number;
    let bytes = toBytes(buffer);
    this.trigger('data', localFileHeader);
    if (level) {
      const compressed = pako.deflate(bytes, { raw: true, level: 1 })
      compressedSize += compressed.byteLength;
      this.trigger('data', compressed);
    } else {
      compressedSize = bytes.length;
      this.trigger('data', bytes);
    }
    _crc32 = crc32(bytes);
    dataDescriptor = createDataDescriptor(_crc32, compressedSize, bytes.length);
    this.trigger('data', dataDescriptor);
    this.centralDirHeaders.push(
      createCentralDirHeader(pathAsBytes, this.date, !!level, offset, bytes.length, compressedSize, _crc32),
    );
    this.offset += localFileHeader.length + compressedSize + dataDescriptor.length;
    return this;
  }

  writeFileSync(path: string, buffer: BufferLike, level?: number): Uint8Array {
    let pathAsBytes = toBytes(path);
    let offset = this.offset;
    let localFileHeader = createLocalFileHeader(pathAsBytes, this.date, !!level);
    let compressedSize = 0;
    let dataDescriptor: Uint8Array;
    let _crc32: number;
    let bytes = toBytes(buffer);
    let compressed = bytes;
    this.trigger('data', localFileHeader);
    if (level) {
      compressed = pako.deflate(bytes, { raw: true, level: 1 })
      compressedSize += compressed.byteLength;
      this.trigger('data', compressed);
    } else {
      compressedSize = bytes.length;
      this.trigger('data', bytes);
    }
    _crc32 = crc32(bytes);
    dataDescriptor = createDataDescriptor(_crc32, compressedSize, bytes.length);
    this.trigger('data', dataDescriptor);
    this.centralDirHeaders.push(
      createCentralDirHeader(pathAsBytes, this.date, !!level, offset, bytes.length, compressedSize, _crc32),
    );
    this.offset += localFileHeader.length + compressedSize + dataDescriptor.length;
    const output = new Uint8Array(localFileHeader.length + compressed.length + dataDescriptor.length);
    output.set(localFileHeader);
    output.set(compressed, localFileHeader.length);
    output.set(dataDescriptor, localFileHeader.length + compressed.length);
    return output;
  }

  writeEnd() {
    let centralDirHeaderSize = 0;
    this.centralDirHeaders.forEach(header => {
      centralDirHeaderSize += header.length;
      this.trigger('data', header);
    });
    const zip64 = this.centralDirHeaders.length >= MAX_VALUE_16BITS;
    if(zip64)
    {
      this.trigger('data', createEndCentDirHeader64(this.centralDirHeaders.length, centralDirHeaderSize, this.offset));
    }
    const numberOfCentralDirs = (zip64) ? MAX_VALUE_16BITS : this.centralDirHeaders.length;
    const centralDirSize = (zip64) ? MAX_VALUE_32BITS : centralDirHeaderSize;
    const centralDirStartOffset = (zip64) ? MAX_VALUE_32BITS : this.offset;
    // const headerSize = (zip64) ? MAX_VALUE_16BITS : centralDirHeaderSize
    this.trigger('data', createEndCentDirHeader({ numberOfCentralDirs, centralDirHeaderSize: centralDirSize, centralDirStartOffset, comment: this.comment }));
    this.trigger('data', this.comment);
    this.trigger('end', null);
  }

  writeEndSync(): Uint8Array {
    let offset = 0;
    let centralDirHeaderSize = this.centralDirHeaders.reduce(((sum, header) => sum + header.length), 0);
    const centralDirHeadersOutput = new Uint8Array(centralDirHeaderSize);
    for(const header of this.centralDirHeaders)
    {
      centralDirHeadersOutput.set(header, offset);
      offset += header.length;
    }
    const zip64 = this.centralDirHeaders.length >= MAX_VALUE_16BITS;
    let endCentDirHeader64Output = (zip64) ? createEndCentDirHeader64(this.centralDirHeaders.length, centralDirHeaderSize, this.offset) : new Uint8Array(0);
    const numberOfCentralDirs = (zip64) ? MAX_VALUE_16BITS : this.centralDirHeaders.length;
    const centralDirSize = (zip64) ? MAX_VALUE_32BITS : centralDirHeaderSize;
    const centralDirOffset = (zip64) ? MAX_VALUE_32BITS : this.offset;
    // const headerSize = (zip64) ? MAX_VALUE_16BITS : centralDirHeaderSize
    const endCentDirHeaderOutput = createEndCentDirHeader({ numberOfCentralDirs, centralDirHeaderSize: centralDirSize, centralDirStartOffset: centralDirOffset, comment: this.comment });
    const output = new Uint8Array(centralDirHeadersOutput.length + endCentDirHeader64Output.length + endCentDirHeaderOutput.length + this.comment.byteLength);
    output.set(centralDirHeadersOutput);
    output.set(endCentDirHeader64Output, centralDirHeadersOutput.length);
    output.set(endCentDirHeaderOutput, centralDirHeadersOutput.length + endCentDirHeader64Output.length);
    output.set(this.comment, centralDirHeadersOutput.length + endCentDirHeader64Output.length + endCentDirHeaderOutput.length)
    return output;
  }

  on(name: 'data', callback: (bytes: Uint8Array) => any): this;
  on(name: 'end', callback: () => any): this;
  on(name: string, callback: Function): this;
  on(name: string, callback: Function): this {
    if (!this.listeners[name]) this.listeners[name] = [];
    this.listeners[name].push(callback);
    return this;
  }

  private trigger(name: string, data: any) {
    if (!this.listeners[name]) return;
    this.listeners[name].forEach(listner => listner(data));
  }
}

function createLocalFileHeader(fileName: Uint8Array, date: Date, isDeflated: boolean) {
  let view = new DataView(new ArrayBuffer(30 + fileName.length));
  let bytes = new Uint8Array(view.buffer);
  let offset = 0;
  view.setUint32(offset, LOCAL_FILE_SIGNATURE, true);
  offset += 4; // local file header signature
  view.setUint16(offset, 20, true);
  offset += 2; // version needed to extract
  view.setUint16(offset, 0x0808);
  offset += 2; // general purpose bit flag
  view.setUint16(offset, isDeflated ? 8 : 0, true);
  offset += 2; // compression method
  view.setUint16(offset, createDosFileTime(date), true);
  offset += 2; // last mod file time
  view.setUint16(offset, createDosFileDate(date), true);
  offset += 2; // last mod file date
  // skip below
  // crc-32 4bytes
  // compressed size 4bytes
  // uncompressed size 4bytes
  offset += 12;
  view.setUint16(offset, fileName.length, true);
  offset += 2; // file name length
  offset += 2; // skip extra field length
  bytes.set(fileName, offset);
  return bytes;
}

function createDataDescriptor(crc32: number, compressedSize: number, uncompressedSize: number) {
  let view = new DataView(new ArrayBuffer(16));
  view.setUint32(0, DATA_DESCRIPTOR_SIGNATURE, true);
  view.setUint32(4, crc32, true);
  view.setUint32(8, compressedSize, true);
  view.setUint32(12, uncompressedSize, true);
  return new Uint8Array(view.buffer);
}

function createCentralDirHeader(
  fileName: Uint8Array,
  date: Date,
  isDeflated: boolean,
  fileOffset: number,
  uncompressedSize: number,
  compressedSize: number,
  crc: number,
) {
  let view = new DataView(new ArrayBuffer(46 + fileName.length));
  let bytes = new Uint8Array(view.buffer);
  let offset = 0;
  view.setUint32(offset, CENTRAL_DIR_SIGNATURE, true);
  offset += 4; // central file header signature
  view.setUint16(offset, 20, true);
  offset += 2; // version made by (2.0)
  view.setUint16(offset, 20, true);
  offset += 2; // version needed to extract
  view.setUint16(offset, 0x0808);
  offset += 2; // general purpose bit flag (use utf8, data discriptor)
  view.setUint16(offset, isDeflated ? 8 : 0, true);
  offset += 2; // compression method
  view.setUint16(offset, createDosFileTime(date), true);
  offset += 2; // last mod file time
  view.setUint16(offset, createDosFileDate(date), true);
  offset += 2; // last mod file date
  view.setUint32(offset, crc, true);
  offset += 4; // crc-32
  view.setUint32(offset, compressedSize, true);
  offset += 4; // compressed size
  view.setUint32(offset, uncompressedSize, true);
  offset += 4; // uncompressed size
  view.setUint16(offset, fileName.length, true);
  offset += 2; // file name length
  // skip below
  // extra field length 2bytes
  // file comment length 2bytes
  // disk number start 2bytes
  // internal file attributes 2bytes
  // external file attributes 4bytes
  offset += 12;
  view.setUint32(offset, fileOffset, true);
  offset += 4; // relative offset of local header
  bytes.set(fileName, offset); // file name
  return bytes;
}

function createEndCentDirHeader64(
  numberOfCentralDirs: number,
  centralDirHeaderSize: number,
  centralDirStartOffset: number
) {
  let view = new DataView(new ArrayBuffer(76));
  const eocdrOffset = centralDirHeaderSize + centralDirStartOffset;
  view.setUint32(0, ZIP64_CENTRAL_DIRECTORY_END, true); // end of central dir signature 64
  view.setUint32(4, 44, true); // size of zip64 end of central
  view.setUint32(8, 0, true); // size of zip64 end of central - 64
  view.setUint16(12, 0x002D, true); // version made by
  view.setUint16(14, 0x002D, true); // version extract by
  view.setUint32(16, 0, true); // disks
  view.setUint32(20, 0, true); // disk

  view.setUint32(24, numberOfCentralDirs, true); // numberOfCentralDirs
  view.setUint32(28, 0, true); // numberOfCentralDirs - 64
  view.setUint32(32, numberOfCentralDirs, true); // numberOfCentralDirs
  view.setUint32(36, 0, true); // numberOfCentralDirs - 64

  view.setUint32(40, centralDirHeaderSize, true); // centralDirHeaderSize
  view.setUint32(44, 0, true); // centralDirHeaderSize - 64

  view.setUint32(48, centralDirStartOffset, true); // centralDirStartOffset
  view.setUint32(52, 0, true); // centralDirStartOffset - 64
  
  view.setUint32(56, ZIP64_CENTRAL_DIRECTORY_LOCATOR, true); // end of central dir locator signature 64
  view.setUint32(60, 0, true); // number of the disk with the start of the zip64 end of central directory

  view.setUint32(64, eocdrOffset, true); // eocdrOffset
  view.setUint32(68, 0, true); // eocdrOffset

  view.setUint32(72, 1, true); // total number of disks
  return new Uint8Array(view.buffer);
}

function createEndCentDirHeader(props: {
  numberOfCentralDirs: number,
  centralDirHeaderSize: number,
  centralDirStartOffset: number,
  comment: Uint8Array
}) {
  const { numberOfCentralDirs, centralDirHeaderSize, centralDirStartOffset, comment } = props;

  let view = new DataView(new ArrayBuffer(22));
  view.setUint32(0, END_SIGNATURE, true); // end of central dir signature
  view.setUint16(4, 0, true); // number of this disk
  view.setUint16(6, 0, true); // number of the disk with the start of the central directory
  view.setUint16(8, numberOfCentralDirs, true); // total number of entries in the central directory on this disk
  view.setUint16(10, numberOfCentralDirs, true); // total number of entries in the central directory
  view.setUint32(12, centralDirHeaderSize, true); // size of the central directory
  view.setUint32(16, centralDirStartOffset, true); // offset of start of central directory with respect to the starting disk number
  view.setUint16(20, comment.length, true); // .ZIP file comment length
  return new Uint8Array(view.buffer);
}

function createDosFileDate(date: Date) {
  return ((date.getFullYear() - 1980) << 9) | ((date.getMonth() + 1) << 5) | date.getDay();
}

function createDosFileTime(date: Date) {
  return (date.getHours() << 11) | (date.getMinutes() << 5) | (date.getSeconds() >> 1);
}
