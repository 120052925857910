import React, { FC } from 'react';
import {
  defaultTheme,
  Button
} from '../../../components'
import {
  UploadedFile,
  lang
} from '../../../libs'

interface Prop
{
  uploadedFile: UploadedFile;
  onAnonymize: () => any;
}

const estimatedTime = (size: number): string => {
  const divider = 33.3;
  let seconds = Math.max(2, Math.ceil((size / 1000000) / divider) * 60);
  let messagePrefix = `Estimated time: `;
  if(seconds < 60)
  {
    return `${messagePrefix}${seconds} second${seconds === 1 ? `` : `s`}`;
  }
  if(seconds < 3600)
  {
    const minutes = Math.ceil(seconds / 60);
    return `${messagePrefix}${minutes} minute${minutes === 1 ? `` : `s`}`;
  }
  const hours = Math.ceil(seconds / 3600);
  return `${messagePrefix}${hours} hour${hours === 1 ? `` : `s`}`;
}

export const WaitingContent: FC<Prop> = ({ 
  uploadedFile,
  onAnonymize,
  children
}) => {
  return (
    <>
      <div className={`loaded-wrapper`}>
        <p className={`loaded-filename`}>{uploadedFile.name}</p>
        <p className={`loaded-estimated-time`}>{estimatedTime(uploadedFile.fileSize)}</p>
        <Button onClick={onAnonymize} text={lang.titleAnonymizeFile} disabled={false} theme={defaultTheme.buttons.purple} />
      </div>
      <style>{`
      .loaded-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        margin: 60px 40px;
      }

      .loaded-wrapper .loaded-extension {
        font-size: 16px;
        background-color: #530EAD;
        color: white;
        padding: 26px 20px;
        border-radius: 35px;
        text-transform: uppercase;
      }

      .loaded-filename,
      .loaded-estimated-time {
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid gray;
      }

      .loaded-filename {
        color: #530EAD;
        margin-bottom: 45px;
        padding-bottom: 3px;
      }

      .loaded-estimated-time {
        font-size: 13px;
        color: #390074;
        margin-bottom: 37px;
        padding-bottom: 2px;
      }
      `}</style>
      {children}
    </>
  )
}