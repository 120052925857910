import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, defaultTheme } from '../../../components';
import { lang } from '../../../libs';

interface Prop
{
  error: string;
  onReset: () => void;
}

export const ErrorContent: FC<Prop> = ({ 
  error, 
  onReset
}) => {
  return (
    <>
      <div className={`error-wrapper`}>
        <div className={`error`}><ReactMarkdown>{error}</ReactMarkdown></div>
        <Button onClick={onReset} text={lang.buttonLoadAnother} disabled={false} theme={defaultTheme.buttons.purple} />
      </div>
      <style>{`
      .error-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        margin: 60px 40px;
      }

      .error {
        font-size: 13px;
        margin-bottom: 25px;
        padding-bottom: 3px;
      }

      .error > p {
        line-height: 145%;
        color: #4e4e4e;
      }

      .error > p strong {
        color: #BB3C38;
      }

      .error > a {
        color: #4e4e4e;
      }
     `}</style>
    </>
  )
}