import { ButtonTheme } from '../components';

export type Theme = {
  colors: { [name: string]: string }
  fonts: { [name: string]: string }
  buttons: { [key in string]: ButtonTheme }
}


export const fonts = {
  family: `"Roboto", sans-serif`
}

export const colors = {
  none: 'none',
  clearLight: '#FFFFFFC0',
  clear: '#FFFFFF',
  
  light: '#F8F9FB',
  black: '#000',

  grayLight: 'rgba(69,90,100,0.06)',
  gray: '#DCE0E9',
  grayMedium: '#C3C7D0',
  grayDark: '#4e4e4e',

  blueLight: '#8CBBE9',
  blue: '#1976D2',
  blueDark: '#1564B2',

  purpleLight: '#EBDFF7',
  purple: '#530EAD',
  purpleDark: '#390074',


  // greenLight: '#beff82',
  greenLight: '#7EC53C',
  green: '#71B035',
  greenMedium: '#75AE40',
  greenDark: '#4E7A25',

  // green: '#71B035',
  // greenDark: '#4E7A25',

  redLight: '#FFD9D9',
  red: '#FF0000',
  redDark: '#BB3C38'
}

export const buttons: { [key in string]: ButtonTheme } = {
  default: {
    default: {
      color: colors.black,
      border: colors.gray,
      background: colors.none
    },
    disabled: {
      color: colors.clear,
      border: colors.gray,
      background: colors.gray
    },
    active: {
      color: colors.black,
      border: colors.grayMedium,
      background: colors.grayLight
    }
  },
  green: {
    default: {
      color: colors.clear,
      border: colors.greenMedium,
      background: colors.greenLight
    },
    disabled: {
      color: colors.clear,
      border: colors.greenDark,
      background: colors.greenLight
    },
    active: {
      color: colors.clear,
      border: colors.greenDark,
      background: colors.green
    }
  },
  blue: {
    default: {
      color: colors.clear,
      border: colors.blue,
      background: colors.blue
    },
    disabled: {
      color: colors.clear,
      border: colors.blueLight,
      background: colors.blueLight
    },
    active: {
      color: colors.clear,
      border: colors.blueDark,
      background: colors.blueDark
    }
  },
  red: {
    default: {
      color: colors.red,
      border: colors.red,
      background: colors.clear
    },
    disabled: {
      color: colors.clear,
      border: colors.redLight,
      background: colors.redLight
    },
    active: {
      color: colors.redDark,
      border: colors.redDark,
      background: colors.grayLight
    }
  },
  purple: {
    default: {
      color: colors.clear,
      border: colors.purple,
      background: colors.purple
    },
    disabled: {
      color: colors.clear,
      border: colors.purpleLight,
      background: colors.purpleLight
    },
    active: {
      color: colors.clear,
      border: colors.purpleDark,
      background: colors.purpleDark
    }
  }
}

export const defaultTheme: Theme = {
  colors,
  fonts,
  buttons
}
