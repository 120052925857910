import * as zip from "./ziptools";
import { UploadFile } from './types';
import { 
  ProgressCounterListener,
  Logger
} from './';
import { langErrorMsg } from "./lang";
import { COMMENT_ANONYMIZED_SLACK, COMMENT_ANONYMIZED_SLACK_HASHED } from "../constants";

export const Unzip = (uploadFile: UploadFile, progressCallback: ProgressCounterListener): Promise<zip.ZipArchiveReader> => {
  const progressCallbackProcess = (result: zip.ZipArchiveReaderProgress) => {
    progressCallback({ percent: result.progress, ratio: result.progress / 100 })
  }
  const content  = uploadFile.content ? uploadFile.content as ArrayBuffer : uploadFile.file;
  return new Promise((resolve, reject) => {
    Logger.addMessage(`Processing file: JSZipTools started`);
    zip.unpack({ buffer: content, encoding: 'UTF-8', progressCallback: progressCallbackProcess, headerSpeedUp: true })
    .then((result: zip.ZipArchiveReader) => {
      Logger.addMessage(`Processing file: JSZipTools unpacked`, true);
      const commentEncoded = result.getComment();
      if(isAnonymized(commentEncoded)) {
        throw new Error(langErrorMsg.alreadyAnonymized)
      }
      resolve(result);
    }).catch(error => {
      error = (error && (typeof error === 'object') && 'message' in error) ? error.message : error;
      Logger.addMessage(`Processing file error: ${error}`, true);
      reject(error);
    });
  })
}

const isAnonymized = (commentEncoded: Uint8Array | null) => {
  if(commentEncoded === null) return false;
  const decoder = new TextDecoder();
  const comment = decoder.decode(commentEncoded);
  return [COMMENT_ANONYMIZED_SLACK, COMMENT_ANONYMIZED_SLACK_HASHED].includes(comment);
}