import React, { FC, useState } from 'react';
import {
  lang,
} from '../../../libs'
import {
  Modal,
  defaultTheme
} from '../../../components'

interface Prop
{
  filterInitial: string | null;
  onCancel: () => any;
  onConfirm: (filter: string) => any;
  onClose: () => any;
}

export const FilterChannelsModal: FC<Prop> = ({ 
  filterInitial,
  onCancel,
  onConfirm,
  onClose
}) => {
  const [filter, setFilter] = useState<string>(filterInitial ? filterInitial : '');
  return (
    <>
      <Modal 
        title={lang.titleFilterChannels}
        leftButton={{title: lang.buttonCancel, theme: defaultTheme.buttons.default, onClick: onCancel}}
        rightButton={{title: lang.buttonConfirm, theme: defaultTheme.buttons.purple, onClick: () => onConfirm(filter)}}
        justify={`left`}
        onClose={onClose}
        width={800}
      >
        <div className={`modal-filter-channels-wrapper`}>
          <div className={`modal-filter-channels-table-wrapper`}>
            <table className={`files-table`}>
              <tbody>
                <tr>
                  <td>
                    <textarea defaultValue={filter} spellCheck={false} className={`modal-filter-channels-textarea`} onChange={(e) => setFilter(e.target.value)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <style>{`
        div.modal-filter-channels-wrapper {
          width: 100%;
        }

        div.modal-filter-channels-wrapper div.modal-filter-channels-table-wrapper {
          max-height: 60vh;
        }

        div.modal-filter-channels-wrapper div.modal-filter-channels-table-wrapper table {
          width: 100%;

        }

        div.modal-filter-channels-wrapper div.modal-filter-channels-table-wrapper table tr td {
          padding: 1px 5px;
        }

        div.modal-filter-channels-wrapper > h2 {
          font-size: 13px;
          font-weight: normal;
          margin-bottom: 22px;
        }

        div.modal-filter-channels-wrapper p.title-description {
          font-size: 15px;
          font-weight: bold;
        }

        div.modal-filter-channels-wrapper table.files-table td {
          cursor: default;
        }

        textarea.modal-filter-channels-textarea {
          width: 97%;
          height: 57vh;
        }
        `}</style>
      </Modal>
    </>
  )
}