
const messages: string[] = [];

let lastDt = (new Date()).getTime();

export class Logger
{
  static addMessage(message: string | Error, withDiff: boolean = false)
  {
    const msg = (typeof message === 'string') ? message : message.toString();
    
    const now = (new Date()).getTime();
    const time: string = ((now - lastDt) / 1000).toFixed(2);;
    lastDt = now;
    messages.push(`${msg}${withDiff ? ` Diff: ${time}s` : ``}`);
  }

  static getMessages(lineSeparator: string = "\n"): string
  {
    return messages.join(lineSeparator);
  }
}