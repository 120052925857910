import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteList } from '../../screens'
import { historyHashPush } from '../index'

type InitProps = RouteComponentProps<{
}> & {}

export const Init: React.FC<InitProps> = ({
  history
}) => {
  const SourceRoute = RouteList.source;
  historyHashPush(SourceRoute.path, history);
  return (
    null
  );
}