import React, { FC, Fragment } from 'react';

interface ScreenProps {
  topOffset?: boolean;
}

export const Screen: FC<ScreenProps> = ({ 
  topOffset = true,
  children 
}) => (
  <Fragment>
    <div className='screen-wrapper'>
        {children}
    </div>
    <style>{`
      .screen-wrapper {
        margin-top: ${topOffset ? `60` : `0`}px;
        width: 100%;
        height: calc(100% - ${topOffset ? `114` : `0`}px);
        position: absolute;
        top: 54px;
      }
    `}</style>
  </Fragment>
);