import React, { FC, Fragment } from 'react';

type FlexDirection = 'row' | 'column';

interface Prop
{
  mx?: number;
  my?: number;
  mt?: number;
  mb?: number;
  direction?: FlexDirection;
  opacity?: number;
}

export const BoxScreen: FC<Prop> = ({ 
  mx = 0,
  my = 15,
  mt,
  mb,
  direction = 'column',
  opacity = 1.0,
  children 
}) => {
  return (
    <Fragment>
      <div className='box-screen'>
          {children}
      </div>
      <style>{`
        .box-screen {
          margin: ${my}px ${mx}px;
          ${mt ? `margin-top: ${mt}px;` : ``}
          ${mb ? `margin-bottom: ${mb}px;` : ``}
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          flex-direction: ${direction};
          opacity: ${opacity};
        }
      `}</style>
    </Fragment>
  )
};