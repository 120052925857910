import React, { FC } from 'react';
import {
  Logger,
  lang
} from '../libs'
import {
  Modal,
  defaultTheme
} from '../components'

interface Prop
{
  onClose: () => any;
}

export const LoggerModal: FC<Prop> = ({ 
  onClose
}) => {
  const width = window.innerWidth < 800 ? Math.floor(window.innerWidth * 0.98) : Math.min(Math.floor(window.innerWidth * 0.8), 1600);
  return (
    <>
      <Modal 
        title={`Logger`}
        leftButton={{title: lang.buttonCancel, theme: defaultTheme.buttons.default, onClick: onClose}}
        justify={`left`}
        onClose={onClose}
        width={width}
      >
        <div className={`modal-logger-wrapper`}>
          <h2>{`Logger`}</h2>
          <div className={`modal-logger-messages-wrapper`}>
            <textarea defaultValue={Logger.getMessages()} spellCheck={false} />
          </div>        
        </div>
        <style>{`
        div.modal-logger-wrapper {
          width: 100%;
        }

        div.modal-logger-wrapper div.modal-logger-messages-wrapper {
          min-height: 50vh;
          max-height: 60vh;
        }

        div.modal-logger-wrapper > h2 {
          font-size: 13px;
          font-weight: normal;
          margin-bottom: 22px;
        }

        div.modal-logger-wrapper textarea {
          font-size: 12px;
          width: 100%;
          min-height: 50vh;
          max-height: 60vh;
          overflow-y: scroll;
        }
        `}</style>
      </Modal>
    </>
  )
}