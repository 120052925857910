import React, { Fragment } from 'react';
import { defaultTheme } from './theme';

export interface ButtonColor {
  color: string;
  border?: string;
  background: string;
}

export interface ButtonTheme {
  default: ButtonColor;
  disabled: ButtonColor;
  active: ButtonColor;
}

type ButtonProps = {
  id?         : string;
  onClick?    : (event: any) => void;
  text        : string;
  disabled?   : boolean;
  theme?      : ButtonTheme;
  prepend?    : JSX.Element
  fullWidth?  : boolean;
  gapBefore?  : boolean;
  gapAfter?   : boolean;
  borderWidth?: number;
}



export const Button: React.FC<ButtonProps> = ({ 
  id = `button-${Math.random().toString(36).slice(2)}`,
  onClick,
  text,
  disabled = false,
  theme = defaultTheme.buttons.default,
  prepend = null,
  fullWidth = false,
  gapBefore = true,
  gapAfter = true,
  borderWidth = 1,
  children
}) => {
  const props: any = {};
  const classes = [`button-wrapper`, id];
  if(disabled)
  {
    classes.push('disabled');
  }

  const borderWidthDouble = (borderWidth * 2);
  const onClickCallback = disabled ? undefined : onClick;
  return (
    <Fragment>
      <div
        className={classes.join(' ')}
        onClick={onClickCallback ? onClickCallback : undefined}
        {...props}
      >
        <div className={`button-text`}>
          {prepend}
          {children}
          <div className={`text`}>
            {text}
          </div>
        </div>
      </div>
      <style>{`
        .button-wrapper.${id} {
          display: inline-block;
          position: relative;
          margin: ${gapBefore ? `9px` : `0`} 5px ${gapAfter ? `9px` : `0`} 5px;
          background-color: ${theme.default.background};
          padding: 1px 7px;
          cursor: pointer;
          ${theme.default.border ? `border: ${borderWidth}px solid ${theme.default.border};` : ''}
          border-radius: 3px;
          font-weight: bold;
          color: ${theme.default.color};
          ${fullWidth ? `width: calc(100% - ${28 - borderWidthDouble}px);` : ``}
        }

        .button-wrapper.${id}:hover {
          color: ${theme.active.color};
          background-color: ${theme.active.background};
          ${theme.active.border ? `border-color: ${theme.active.border};` : ''}
        }

        .button-wrapper.${id}.disabled {
          color: ${theme.disabled.color};
          background-color: ${theme.disabled.background};
          ${theme.disabled.border ? `border-color: ${theme.disabled.border};` : ''}
          cursor: default;
        }

        .button-wrapper.${id} div.button-text {
          margin: 9px 9px 9px 5px;
          float: left;
        }

        .button-wrapper.${id} div.button-text div.text {
          float: left;
          white-space: nowrap;
        }
      `}</style>
    </Fragment>
  )
}