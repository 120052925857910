import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import * as zip from "../../libs/ziptools";
import { RouteList, historyHashReplace } from '../../screens'
import {
  Container,
  Header,
  Title,
  ProgressBar,
  Screen,
  notification,
  UploaderAnonymizationOverlay,
  UploaderWrapper,
} from '../../components'
import {
  LoaderContent,
  LoadedContent,
  UploaderContent,
  DeleteFileModal
} from './parts'
import {
  lang,
  langErrorMsg,
  AllowedMIMETypesSlack,
  AllowedMIMETypesTable,
  AllowedMIMETypesZoom,
  UploadFile,
  UploadedFile,
  ContentType,
  Unzip,
  Progress,
  StoreANON,
  Logger,
  getDeviceMemory,
  getUserAgentAdvanced,
  isFileAPISupported,
  DIRECTORY_SEPARATOR_REGEX,
  SYSTEM_FILE_REGEX,
  AnonymizationSettings,
  isNotUTF8,
  getEncodingFromBrowser,
  prependArrayBufferBOM,
  Reader,
} from '../../libs'
import { AnonymizationKey } from '../../components/AnonymizationKey';
import { userKey } from '../../user';
import styled from 'styled-components';

interface WelcomeProps extends RouteComponentProps<{
}> 
{
}

interface UploadingProgress
{
  started: boolean;
  percent: number;
}

type AnonymizationKeyProcessingType = 'disabled' | 'waiting' | 'processed';

type AllowedMIMETypesMERGED = (AllowedMIMETypesSlack | AllowedMIMETypesTable);

interface WelcomeState
{
  loading: boolean;
  loaded: boolean;
  dragging: boolean;
  uploadFile: null | UploadFile[];
  uploadFileDelete: boolean;
  uploadFilename: null | string;
  uploadError: null | string;
  uploading: UploadingProgress;
  anonymizationIndex: number;
  anonymizationKeyProcessing: AnonymizationKeyProcessingType;
  anonymizationKeyError: null | boolean;
}

const typeExtensionsSlack: { [key in AllowedMIMETypesSlack]: string } = {
  'application/zip': '.zip'
}

const typeExtensionsTable: Record<AllowedMIMETypesTable, string> = {
  'text/csv'                                                         : '.csv',
  'application/vnd.ms-excel'                                         : '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
}

const typeExtensionsZoom: Record<AllowedMIMETypesZoom, string> = {
  'text/csv'                                                         : '.csv'
}

declare global {
  interface Navigator {
    deviceMemory: number;
  }
}

export class Welcome extends React.Component<WelcomeProps, WelcomeState>
{
  private  dragCounter: number                   = 0;
  private  uploadArrayBuffer: boolean            = true;
  private  uploadEncodig: string                 = 'UTF-8';
  private  uploadLimitMB: number                 = 700;
  private  uploadLimitBufferMB: number           = 120;
  readonly columnsLimitTable: number             = 100;
  readonly uploadMIMEAllowed: (AllowedMIMETypesMERGED)[] = [];
  readonly uploadExtensionsAllowed: string[]     = [];
  readonly type: ContentType                     = 'slack';
  private  loadingTimer: Date                    = new Date();
  private  loadingTimerLimit: number             = 1500;

  private  tabSelected: number                   = 0;

  constructor(props: Readonly<WelcomeProps>)
  {
    super(props);
    const type = userKey.getType();
    if(type === 'slack') {
      this.uploadMIMEAllowed = (Object.keys(typeExtensionsSlack) as AllowedMIMETypesSlack[]);
      this.uploadExtensionsAllowed = (Object.values(typeExtensionsSlack));
    }
    if(type === 'table') {
      this.uploadMIMEAllowed = (Object.keys(typeExtensionsTable) as AllowedMIMETypesTable[]);
      this.uploadExtensionsAllowed = (Object.values(typeExtensionsTable));
    }
    if(type === 'zoom') {
      this.uploadMIMEAllowed = (Object.keys(typeExtensionsTable) as AllowedMIMETypesZoom[]);
      this.uploadExtensionsAllowed = (Object.values(typeExtensionsZoom));
      this.uploadArrayBuffer = false;
    }
    if(type !== null) this.type = type;
    const deviceMemory = getDeviceMemory();
    if(deviceMemory !== null) 
    {
      let uploadLimitMB = 700; 
      let uploadLimitBufferMB = 120;
      if(deviceMemory >= 8) 
      {
        uploadLimitMB = (isFileAPISupported()) ? 2500 : 1600;
        uploadLimitBufferMB = 210;
      }
      this.uploadLimitMB = uploadLimitMB;
      this.uploadLimitBufferMB = uploadLimitBufferMB;
    }
    if(type === 'table' || type === 'zoom')
    {
      this.uploadLimitMB = 25;
      this.uploadLimitBufferMB = 25;
    }
    this.state = this.getDefaultState();
  }

  protected getDefaultState(): WelcomeState
  {
    return {
      loading         : false,
      loaded          : false,
      dragging        : false,
      uploadFile      : null,
      uploadFileDelete: false,
      uploadFilename  : null,
      uploadError     : null,
      uploading       : {
        started: false,
        percent: 0
      },
      anonymizationIndex: 0,
      anonymizationKeyProcessing: (userKey && userKey.getAnonymizationRequired()) ? 'waiting' : 'disabled',
      anonymizationKeyError: null
    }
  }

  componentDidMount()
  {
    if(!userKey.getInitialized()) {
      historyHashReplace(RouteList.source.path, this.props.history);
      return;
    }
    Logger.addMessage(`:: Screen Welcome did Mount`)
    Logger.addMessage(`UserAgentAdvanced: ${getUserAgentAdvanced()}`);
    const anonymizationKeyProcessing = (userKey && userKey.getAnonymizationRequired()) ? 'waiting' : 'disabled';
    Logger.addMessage(`:: Screen Welcome anonymizationKeyProcessing ${anonymizationKeyProcessing}`)
    const data: UploadedFile | null = StoreANON.getUploadedFile();
    if(data !== null)
    {
      this.setState({ loaded: true, anonymizationKeyProcessing });
    }
  }

  private processAnonymizationKey = (anonymizationSettings: AnonymizationSettings) =>
  {
    window.setTimeout(() => {
      const { anonymizationSalt, rsaPublicKey, ...anonymizationDomainSettings } = anonymizationSettings;
      userKey.setAnomymizationKey(anonymizationSalt);
      userKey.setAnonymizationSettingsDomains(anonymizationDomainSettings);
      this.setState({ anonymizationKeyProcessing: 'processed' });
    }, 250);
  }

  private onUploadedSlack = () =>
  {
    historyHashReplace(RouteList.summary.path, this.props.history);
  }

  private onUploadedTable = (uploadFile: UploadFile) =>
  {
    const contentReader = new Reader({ columnsLimit: this.columnsLimitTable });
    let data;
    try {
      contentReader.load(uploadFile.content!);
      data = contentReader.getSheetById(this.tabSelected, true);
    } catch(e: unknown) {
      const uploadError = (e instanceof Error && e.message === 'Invalid columns count') ? langErrorMsg.invalidColumnsCount.replace('%1', this.columnsLimitTable.toString()) : langErrorMsg.invalidFile;
      notification(uploadError, {type: `error`});
      this.dragCounter = 0;
      this.setState({loading: false, dragging: false, uploadError});
      return;
    }
    const uploadedFile = {
      name: uploadFile.name,
      namePart: uploadFile.namePart,
      extPart: uploadFile.extPart,
      dataType: 'table',
      data,
      dataOutput: null,
      dataSize: uploadFile.file.size,
      fileSize: uploadFile.file.size
    };
    StoreANON.setUploadedFile(uploadedFile as UploadedFile);
    historyHashReplace(RouteList.summary.path, this.props.history);
  }

  private onUploadingProgress = (progress: Progress) => {
    this.setState({ uploading: { ...this.state.uploading, percent: progress.percent }});
  }

  private processContent = (uploadFile: UploadFile) =>
  {
    const dataType = 'slack' as ContentType;
    Logger.addMessage(`Processing file type: ${dataType}`)
    this.setState({ uploading: { started: true, percent: 0 }});
    const from = (new Date()).getTime();
    Logger.addMessage(`Processing file from time: ${(new Date()).toISOString()}`);
    Unzip(uploadFile, this.onUploadingProgress).then((result: zip.ZipArchiveReader) => {
      const diff = (((new Date()).getTime() - from) / 1000);
      Logger.addMessage(`Processing file JSZIPTOOLS processed in: ${diff} seconds, time: ${(new Date()).toISOString()}`)
      const files = result.getFiles()
      if(!isSupportedSlackArchive(files)) {
        throw langErrorMsg.slackCompressedManually;
      }
      const size = files.reduce((a, b) => a + b.uncompsize, 0);
      const uploadedFile = {
        name: uploadFile.name,
        namePart: uploadFile.namePart,
        extPart: uploadFile.extPart,
        dataType,
        data: result,
        dataAnonymized: null,
        dataOutput: null,
        dataSize: size,
        fileSize: uploadFile.file.size
      };
      StoreANON.setUploadedFile(uploadedFile);
      StoreANON.setUploadedFileStatus('waiting');
      this.onUploadedSlack();
    }).catch((error: any) => {
      const errorMsg = `Cannot process ${dataType} file! ${error ? error : ``}`;
      Logger.addMessage(`Unzip Error ${error}`);
      Logger.addMessage(errorMsg);
      StoreANON.resetUploadedFile();
      this.setState({ loading: false, dragging: false, uploadError: errorMsg, uploading: { started: false, percent: 0 } });
    })
  }

  private getUploadFile = (fileRef: File): UploadFile => 
  {
    let nameParts = fileRef.name.split('.');
    let extension = '';
    if(nameParts.length > 1)
    {
      extension = nameParts[nameParts.length - 1];
      nameParts.pop();
    }
    let uploadFile: UploadFile = {
      name: fileRef.name,
      namePart: nameParts.join('.'),
      extPart: extension,
      file: fileRef,
      content: null,
      type: null
    }
    switch(extension.toLowerCase())
    {
      case 'zip':
        uploadFile.type = 'application/zip';
        break;

      case 'xls':
        uploadFile.type = 'application/vnd.ms-excel';
        break;

      case 'xlsx':
        uploadFile.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;

      case 'csv':
        uploadFile.type = 'text/csv';
        break;
    }
    return uploadFile;
  }

  private readFileSlack = (fileRef: File) =>
  {
    if(this.state.loading)
    {
      console.warn('dropHandler Bussy!');
      return;
    }
    Logger.addMessage(`Selected file: name: ${fileRef.name}, size: ${fileRef.size}, MIME: ${fileRef.type}`);
    const uploadFile: UploadFile = this.getUploadFile(fileRef);
    Logger.addMessage(`Detected file for upload: name: ${uploadFile.name}, extension: ${uploadFile.extPart}, type: ${uploadFile.type}`);
    if(!this.uploadMIMEAllowed.includes(uploadFile.type as AllowedMIMETypesMERGED))
    {
      Logger.addMessage(`Detected file not supported!`)
      notification(`Unable to upload not supported files`, {type: `error`});
      this.dragCounter = 0;
      this.setState({loading: false, dragging: false, uploadError: langErrorMsg.invalidType});
      return;
    }
    if(fileRef.size > (this.uploadLimitMB * 1000000))
    {
      Logger.addMessage(`Detected file bigger than limit ${this.uploadLimitMB}!`);
      notification(`File is bigger than ${this.uploadLimitMB} MB`, {type: `error`});
      this.dragCounter = 0;
      this.setState({loading: false, dragging: false, uploadError: langErrorMsg.invalidSize});
      return;
    }
    const debugForceBLOB = false;
    if(debugForceBLOB || fileRef.size > (this.uploadLimitBufferMB * 1000000))
    {
      Logger.addMessage(`Detected file bigger than ArrayBuffer limit ${this.uploadLimitBufferMB} - processing as Blob (File)`);
      this.dragCounter = 0;
      this.setState({ loading: true, loaded: false, uploadError: null, uploadFilename: fileRef.name });
      this.processContent(uploadFile);
      return;
    }
    
    const from = (new Date()).getTime(); //@DEBUG - remove
    const isCSV = uploadFile.type === 'text/csv';
    const reader = new FileReader();
    reader.onload = () => {
      if(this.uploadArrayBuffer)
      {
        Logger.addMessage(`Detected file loaded as ArrayBuffer, size: ${(reader.result as ArrayBuffer).byteLength} bytes`);
        const buffer = (reader.result as ArrayBuffer);
        if(isCSV)
        {
          this.uploadArrayBuffer = false;
          this.uploadEncodig = (isNotUTF8(buffer)) ? getEncodingFromBrowser(buffer) : 'UTF-8';
          this.readFileSlack(fileRef);
          return;
        }
        if(this.type === 'slack') uploadFile.content = buffer;
        if(this.type === 'table' || this.type === 'zoom') uploadFile.content = (!isCSV || isNotUTF8(buffer)) ? new Uint8Array(buffer) : prependArrayBufferBOM(buffer);
      }
      else
      {
        Logger.addMessage(`Detected file loaded as Text with, size: ${(reader.result as string).length}`);
        uploadFile.content = reader.result;
      }
      const to = (new Date()).getTime(); //@DEBUG - remove
      Logger.addMessage(`READER :: result  ${((to - from) / 1000)} seconds`);

      const diff = (new Date().getTime()) - this.loadingTimer.getTime();
      Logger.addMessage(`Detected file loaded in ${(diff / 1000)} seconds`);
      let delay = (diff <= this.loadingTimerLimit) ? this.loadingTimerLimit - diff : 0;
      setTimeout(() => {
        if(this.type === 'slack') this.processContent(uploadFile);
        if(this.type === 'table' || this.type === 'zoom') this.processTabs(uploadFile);
      }, delay);
    }
    if(this.uploadArrayBuffer)
    {
      Logger.addMessage(`Detected file loading as ArrayBuffer`);
      reader.readAsArrayBuffer(fileRef);
    }
    else
    {
      Logger.addMessage(`Detected file loading as Text`);
      reader.readAsText(fileRef, this.uploadEncodig);
    }
    this.loadingTimer = new Date();
    this.setState({ loading: true, loaded: false, uploadError: null, uploadFilename: fileRef.name });
  }

  private processTabs = (file: UploadFile) =>
  {
    if(file === null) // @File missing
    {
      this.setState({loading: false, dragging: false});
      return;
    }
    this.tabSelected = 0;
    const contentReader = new Reader({ columnsLimit: this.columnsLimitTable });
    try {
      contentReader.load(file.content!);
    } catch(e) {
      const errorMsg = (e instanceof Error && e.message === 'Invalid columns count') ? langErrorMsg.invalidColumnsCount.replace('%1', this.columnsLimitTable.toString()) : langErrorMsg.invalidFile;
      this.setState({loading: false, dragging: false, uploadError: errorMsg});
      return;
    }
    if(contentReader.getSheetCount() === 0)
    {
      // @No Tabs found - reset
      this.setState({loading: false, dragging: false, uploadError: langErrorMsg.invalidFile});
      return;
    }
    this.onUploadedTable(file);
    return;
  }

  private dropHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    event.preventDefault();
    if(this.state.loading) return;
    let droppedFile = null;
    if(event.dataTransfer.items)
    {
      for(let i = 0; i < event.dataTransfer.items.length; i++)
      {
        if(event.dataTransfer.items[i].kind !== 'file')
        {
          continue;
        }
        const file = event.dataTransfer.items[i].getAsFile();
        if(file === null)
        {
          continue;
        }
        droppedFile = file;
      }
    }
    else
    {
      for(let i = 0; i < event.dataTransfer.files.length; i++)
      {
        droppedFile = event.dataTransfer.files[i];
      }
    }
    if(droppedFile === null)
    {
      this.setState({dragging: false});
      return;
    }
    if(this.type === 'slack') this.readFileSlack(droppedFile);
    if(this.type === 'table' || this.type === 'zoom') this.readFileSlack(droppedFile);
  }

  private dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(this.state.loading) return;
    if(this.dragCounter++ === 0) this.setState({dragging: true});
    event.preventDefault();
  }

  private dragExitHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(this.state.loading) return;
    if(--this.dragCounter === 0) this.setState({dragging: false});
    event.preventDefault();
    return;
  }

  private getUploderContent()
  {
    const anonymizationKeyProcessing = this.state.anonymizationKeyProcessing;
    const readFile = (this.type === 'slack') ? this.readFileSlack : this.readFileSlack;
    return (
      <UploaderOuter>
        {anonymizationKeyProcessing === 'waiting' ? (
          <AnonymizationKey
            key={`anonymization-key-${this.state.anonymizationIndex}`}
            publicKey={null}
            onUploaded={this.processAnonymizationKey}
          />
        ) : null}
        <UploaderWrapper
          onDrop={this.dropHandler} 
          onDragEnter={this.dragEnterHandler} 
          onDragOver={(event: any) => event.preventDefault()}
          onDragLeave={this.dragExitHandler}
          overflow="visible"
        >
          <UploaderContent 
            dragging={this.state.dragging}
            disabled={this.state.loading}
            uploadArrayBuffer={this.uploadArrayBuffer}
            uploadSizeLimit={this.uploadLimitMB}
            uploadError={this.state.uploadError}
            type={this.type}
            allowedExtensions={this.uploadExtensionsAllowed}
            onChange={readFile}
            onLoading={() => {
              this.setState({loading: true});
            }}
          />
          {anonymizationKeyProcessing === 'waiting' ? (
            <UploaderAnonymizationOverlay className="uploader-anonymization-overlay" />
          ) : null}
        </UploaderWrapper>
      </UploaderOuter>
    )
  }

  private getLoaderContent()
  {
    return (
      <UploaderWrapper
        overflow="visible"
      >
        <LoaderContent 
          filename={this.state.uploadFilename}
          percent={this.state.uploading.percent}
        />
      </UploaderWrapper>
    )
  }

  private getLoadedContent()
  {
    const uploadedFile: UploadedFile | null = StoreANON.getUploadedFile()!;
    if(uploadedFile === null)
    {
      return this.getUploderContent();
    }
    return (
      <UploaderWrapper
        overflow="visible"
      >
        <LoadedContent 
          uploadedFile={uploadedFile}
          onDelete={() => {
            this.setState({ uploadFileDelete: true });
          }}
        >
          {this.state.uploadFileDelete ? this.getDeleteFileModal(uploadedFile) : null}
        </LoadedContent>
      </UploaderWrapper>
    )
  }

  private getDeleteFileModal(uploadedFile: UploadedFile)
  {
    return (
      <DeleteFileModal
        uploadedFile={uploadedFile}
        onClose={() => {
          this.setState({ uploadFileDelete: false });
        }}
        onCancel={() => {
          this.setState({ uploadFileDelete: false });
        }}
        onConfirm={() => {
          Logger.addMessage(`File deleted`)
          StoreANON.resetUploadedFile();
          this.setState(this.getDefaultState());
        }}
      />
    )
  }

  render()
  {
    const content = (this.state.loaded) ? this.getLoadedContent() : ((this.state.loading) ? this.getLoaderContent() : this.getUploderContent());
    let nextButton = undefined;
    if(this.state.loaded)
    {
      nextButton = { 
        onClick: () => {
          historyHashReplace(RouteList.summary.path, this.props.history);
        },
        disabled: false
      }
    }
    return (
      <Container>
        <Header title={lang.title} />
        <ProgressBar step={1} nextButton={nextButton} />
        <Screen>

        <div className={`uploader-wrapper`}>
          <Title title={`1. ${lang.progressStep1Title}`}/>
          <>
            {content}
            <style>{`
            .uploader-wrapper {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              align-content: flex-start;
              background-color: #F8F9FB;
              height: 100%;
              overflow: auto;
            }
            `}</style>
          </>
        </div>
        </Screen>
    </Container>
    )
  }
}

const UploaderOuter = styled.div`
  width: 460px;
  height: auto;
`;

const isSupportedSlackArchive = (entries: zip.ZipLocalFileHeader[]) => {
  const rootFiles = entries.map(entry => entry.fileName).filter(filename => filename.match(SYSTEM_FILE_REGEX) || !filename.match(DIRECTORY_SEPARATOR_REGEX));
  return rootFiles.includes('channels.json');
}