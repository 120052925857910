import * as React from 'react';
import { 
  ToastContainer, 
  toast
} from 'react-toastify'

const defaultOtions: NotificationProps = {
  type: 'default',
  position: 'bottom-right',
  hideProgressBar: true
}

type NotificationProps = {
  type?: "info" | "success" | "warning" | "error" | "default" | undefined;
  position?: "bottom-right" | "top-right" | "top-center" | "top-left" | "bottom-center" | "bottom-left" | undefined;
  hideProgressBar?: boolean;
}

export const Notification: React.FC<NotificationProps> = () => {
  return (
    <ToastContainer
      position="bottom-right"
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick={true}
      rtl={false}
      draggable
      pauseOnHover={true}
    />
  )    
}

Notification.defaultProps = defaultOtions;

export const notification = (message: string, options: NotificationProps) => {
  const toastOptions = Object.assign(defaultOtions, options);
  toast(message, toastOptions);
}