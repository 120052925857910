export const LOCAL_FILE_SIGNATURE = 0x04034b50;
export const DATA_DESCRIPTOR_SIGNATURE = 0x08074b50;
export const CENTRAL_DIR_SIGNATURE = 0x02014b50;
export const END_SIGNATURE = 0x06054b50;
export const ZIP64_CENTRAL_DIRECTORY_LOCATOR = 0x07064b50; //"PK\x06\x07";
export const ZIP64_CENTRAL_DIRECTORY_END = 0x06064b50; //"PK\x06\x06";
export const MAX_VALUE_16BITS = 65535;
export const MAX_VALUE_32BITS = -1;

export type BufferLike = string | number[] | ArrayBuffer | Uint8Array | Int8Array | Uint8ClampedArray;

export interface ZipArchiveReaderProgress {
  progress: number;
  debug?: any;
}

export type ZipArchiveReaderProgressCallback = (progress: ZipArchiveReaderProgress) => any;

/**
 * Converts array-like object to an array.
 *
 * @example
 * jz.common.toArray(document.querySelectorAll("div"));
 */
export function toArray<T>(x: any): T[] {
  return Array.prototype.slice.call(x);
}

/**
 * Converts string to an Uint8Array. its encoding is utf8.
 */
export function stringToBytes(str: string) {
  let n = str.length;
  let idx = -1;
  let byteLength = 32;
  let bytes = new Uint8Array(byteLength);
  let _bytes: Uint8Array;

  for (let i = 0; i < n; ++i) {
    let c = str.charCodeAt(i);
    if (c <= 0x7f) {
      bytes[++idx] = c;
    } else if (c <= 0x7ff) {
      bytes[++idx] = 0xc0 | (c >>> 6);
      bytes[++idx] = 0x80 | (c & 0x3f);
    } else if (c <= 0xffff) {
      bytes[++idx] = 0xe0 | (c >>> 12);
      bytes[++idx] = 0x80 | ((c >>> 6) & 0x3f);
      bytes[++idx] = 0x80 | (c & 0x3f);
    } else {
      bytes[++idx] = 0xf0 | (c >>> 18);
      bytes[++idx] = 0x80 | ((c >>> 12) & 0x3f);
      bytes[++idx] = 0x80 | ((c >>> 6) & 0x3f);
      bytes[++idx] = 0x80 | (c & 0x3f);
    }
    if (byteLength - idx <= 4) {
      _bytes = bytes;
      byteLength *= 2;
      bytes = new Uint8Array(byteLength);
      bytes.set(_bytes);
    }
  }
  return bytes.subarray(0, ++idx);
}

/**
 * Converts Array, ArrayBuffer or String to an Uint8Array.
 *
 * @example
 * var bytes = jz.common.toBytes('foo');
 * var bytes = jz.common.toBytes([1, 2, 3]);
 */
export function toBytes(buffer: BufferLike): Uint8Array {
  switch (Object.prototype.toString.call(buffer)) {
    case '[object String]':
      return stringToBytes(buffer as string);
    case '[object Array]':
    case '[object ArrayBuffer]':
      return new Uint8Array(buffer as ArrayBuffer);
    case '[object Uint8Array]':
      return buffer as Uint8Array;
    case '[object Int8Array]':
    case '[object Uint8ClampedArray]':
    case '[object CanvasPixelArray]':
      const b = buffer as Uint8Array;
      return new Uint8Array(b.buffer, b.byteOffset, b.byteLength);
    default:
      throw new Error('jz.utils.toBytes: not supported type.');
  }
}


/**
 * Concats bytes.
 *
 * @example
 * let bytes = jz.common.concatBytes(bytes1, bytes2, bytes3);
 * let bytes = jz.common.concatBytes([bytes1, bytes2, bytes3]);
 */
export function concatBytes(buffers: (Uint8Array | ArrayBuffer)[]): Uint8Array;
export function concatBytes(...buffers: (Uint8Array | ArrayBuffer)[]): Uint8Array;
export function concatBytes(_buffers: any): Uint8Array {
  let size = 0;
  let offset = 0;
  let ret: Uint8Array;
  let i: number;
  let n: number;
  let buffers = (Array.isArray(_buffers) ? _buffers : toArray(arguments)).map(toBytes);

  for (i = 0, n = buffers.length; i < n; ++i) size += buffers[i].length;
  ret = new Uint8Array(size);
  for (i = 0; i < n; ++i) {
    ret.set(buffers[i], offset);
    offset += buffers[i].length;
  }
  return ret;
}

/**
 * Read a file as selected type. This is a FileReader wrapper.
 */
export function readFileAs(type: 'ArrayBuffer', blob: Blob): Promise<ArrayBuffer>;
export function readFileAs(type: 'Text', blob: Blob, encoding?: string): Promise<string>;
export function readFileAs(type: 'DataURL', blob: Blob): Promise<string>;
export function readFileAs(type: 'ArrayBuffer' | 'Text' | 'DataURL', blob: Blob, encoding = 'UTF-8'): Promise<any> {
  let executor: (resolve: Function, reject: Function) => void;
  executor = (resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => resolve(fr.result);
    fr.onerror = err => reject(err);
    switch (type) {
      case 'ArrayBuffer':
        fr.readAsArrayBuffer(blob);
        break;
      case 'Text':
        fr.readAsText(blob, encoding);
        break;
      case 'DataURL':
        fr.readAsDataURL(blob);
        break;
    }
  };
  return new Promise(executor);
}

/**
 * Read a file as an ArrayBuffer object.
 */
export const readFileAsArrayBuffer = (blob: Blob) => readFileAs('ArrayBuffer', blob);

/**
 * Read a file as a string.
 */
export const readFileAsText = (blob: Blob, encoding?: string) => readFileAs('Text', blob, encoding);

/**
 * Converts Uint8Array to a string.
 *
 * @example
 * jz.common.bytesToString(bytes).then(str => {
 *     console.log(str);
 * });
 *
 * jz.common.bytesToString(bytes, 'UTF-8').then(str => {
 *     console.log(str);
 * });
 */
export function bytesToString(bytes: Uint8Array, encoding = 'UTF-8') {
  return readFileAsText(new Blob([bytes]), encoding);
}

/**
 * Detects encoding of the buffer like object.
 *
 * @example
 * const encoding = jz.common.detectEncoding(bytes);
 * jz.common.detectEncoding(bytes).then(type => {
 *     console.log(type);
 * });
 */
export function detectEncoding(bytes: BufferLike) {
  let b = toBytes(bytes);
  for (let i = 0, n = b.length; i < n; ++i) {
    if (b[i] < 0x80) {
      continue;
    } else if ((b[i] & 0xe0) === 0xc0) {
      if ((b[++i] & 0xc0) === 0x80) continue;
    } else if ((b[i] & 0xf0) === 0xe0) {
      if ((b[++i] & 0xc0) === 0x80 && (b[++i] & 0xc0) === 0x80) continue;
    } else if ((b[i] & 0xf8) === 0xf0) {
      if ((b[++i] & 0xc0) === 0x80 && (b[++i] & 0xc0) === 0x80 && (b[++i] & 0xc0) === 0x80) continue;
    } else {
      return 'Shift_JIS';
    }
  }
  return 'UTF-8';
}

/**
 * MinTime
 */
export class MinTime
{
  protected msMin: number;
  protected msLast: number;

  constructor(msMin: number = 250)
  {
    this.msMin  = msMin;
    this.msLast = (new Date()).getTime();
  }

  is()
  {
    const msNow = (new Date()).getTime();
    if((msNow - this.msLast) >= this.msMin)
    {
      this.msLast = msNow;
      return true;
    }
    return false;
  }
}
