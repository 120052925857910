import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteList, historyHashReplace } from '../../screens'
import {
  Container,
  Header,
  Title,
  ProgressBar,
  Screen,
} from '../../components'
import {
  WaitingContent,
  ProgressContent,
  ArchivedContent,
  ErrorContent
} from './parts'
import {
  lang,
  UploadedFile,
  DownloadBlob,
  StoreANON,
  AnonymizerSlack,
  AnonymizationProgress,
  AnonymizationProgressPhase,
  isFileAPISupported,
  getZIPFileWriteHandle,
  getDateByHash,
  Logger,
  isDev,
  ContentType,
  Download,
  langErrorMsg
} from '../../libs'
import { userKey } from '../../user'
import { AllowedWriterContentType, Writer } from '../../libs/writer'

interface AnonymizationProps extends RouteComponentProps<{
}> 
{
}

interface AnonymizationState
{
  loaded      : boolean;
  started     : boolean;
  progress    : AnonymizationProgress;
  uploadedFile: UploadedFile | null;
  error       : string | null;
}

export class Anonymization extends React.Component<AnonymizationProps, AnonymizationState>
{
  readonly type: ContentType | null;

  constructor(props: Readonly<AnonymizationProps>)
  {
    super(props);
    this.type = userKey.getType();
    this.state = this.getDefaultState();
  }

  protected getDefaultState(): AnonymizationState
  {
    return {
      loaded       : false,
      started      : false,
      progress: {
        phase  : 'waiting',
        percent: 0
      },
      uploadedFile : null,
      error        : null
    }
  }

  componentDidMount = async () =>
  {
    Logger.addMessage(`:: Screen Anonymization did Mount`)
    if(!userKey.getInitialized()) {
      historyHashReplace(RouteList.source.path, this.props.history);
      return;
    }
    const uploadedFile: UploadedFile | null = StoreANON.getUploadedFile();
    const uploadedFileStatus: AnonymizationProgressPhase = StoreANON.getUploadedFileStatus();
    if(uploadedFile === null)
    {
      Logger.addMessage(`Uploaded file missing? redirect to Welcome`)
      StoreANON.resetUploadedFileStatus();
      historyHashReplace(RouteList.welcome.path, this.props.history);
    }
    this.setState({ 
      uploadedFile,
      progress: { ...this.state.progress, phase: uploadedFileStatus }
    })
  }

  private anonymizeSlack = async () => {
    Logger.addMessage(`Anonymization started`)
    StoreANON.resetOutputFileHandle();
    Logger.addMessage(`Anonymization - FileAPI Supported: ${isFileAPISupported() ? `yes` : `no`}`);
    if(isFileAPISupported())
    {
      try {
        const { handle, handleWrite } = await getZIPFileWriteHandle('slack-anonymized.zip', 'Slack Export');
        Logger.addMessage(`Anonymization - FileAPI handle: ${handleWrite ? `yes` : `no`}`);
        StoreANON.setFileHandle(handle);
        StoreANON.setOutputFileHandle(handleWrite);
      } catch(error) {
        Logger.addMessage(`Anonymization - Cannot get File handle!`);
        return;
      }
    }

    StoreANON.setUploadedFileStatus('anonymize');
    this.setState({ started: true, progress: { phase: 'anonymize', percent: 0 } });

    try {
      await this.anonymizeContentSlack();
      StoreANON.setUploadedFileStatus('prepared');
      const progress = { ...this.state.progress, phase: 'prepared' as AnonymizationProgressPhase, percent: null };
      this.setState({ progress });
    } catch(error) {
      Logger.addMessage(`Anonymization - Exception this.anonymizeContent! ${error}`);
    }
  }

  private anonymizeTable = async () => {
    try {
      const progressPrepare = { ...this.state.progress, phase: 'anonymize' as AnonymizationProgressPhase, percent: 3 };
      this.setState({ started: true, progress: progressPrepare });
      const uploadedFile = StoreANON.getUploadedFile() as UploadedFile;
      const anonymizationKey = userKey.getAnomymizationKey();
      const anonymizationSettingsDomains = userKey.getAnonymizationSettingsDomains();
      const writer = new Writer(anonymizationKey, anonymizationSettingsDomains, this.type as AllowedWriterContentType);
      const delimiter = this.type === 'zoom' ? ',' : ';';
      const dataOutput = await writer.prepareCSV(delimiter);
      StoreANON.setUploadedFileStatus('prepared');
      StoreANON.setUploadedFile({ ...uploadedFile, dataOutput })
      const progressDone = { ...this.state.progress, phase: 'prepared' as AnonymizationProgressPhase, percent: null };
      this.setState({ progress: progressDone });
    } catch(error) {
      this.setState({ started: false, progress: { phase: 'waiting', percent: 0 }, error: (typeof error === 'string' || error instanceof String) ? error as string : langErrorMsg.anonymizeErrorGeneral });
    }
  }

  private anonymizeContentSlack = async () => {
    let uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    let filterChannels: string | null = StoreANON.getFilter();
    
    const dateRange = getDateByHash(this.props.location.hash);
    const anonymizer = new AnonymizerSlack();
    const outputFileHandle = StoreANON.getOutputFileHandle();
    if(outputFileHandle) {
      anonymizer.setOutputFileHandle(outputFileHandle);
    }
    Logger.addMessage(`Anonymization from time: ${(new Date()).toISOString()}`)
    const from = (new Date()).getTime();
    uploadedFile.dataOutput = await anonymizer.anonymize(uploadedFile, filterChannels, dateRange, (progressCounter) => {
      const progress = { ...this.state.progress, percent: progressCounter.percent };
      this.setState({ progress });
    });
    Logger.addMessage(`Anonymization processed - Anonymize part in: ${((((new Date()).getTime()) - from) / 1000)} seconds`)
    if(isDev) console.log('Anonymize Part', ((new Date()).getTime()) - from);

    const fromAnonymize = (new Date()).getTime();
    Logger.addMessage(`Anonymization processed - Archive part in: ${((((new Date()).getTime()) - fromAnonymize) / 1000)} seconds`)
    const to = (new Date()).getTime();
    Logger.addMessage(`Anonymization processed in: ${((to - from) / 1000)} seconds, time: ${(new Date()).toISOString()}`)
    Logger.addMessage(`Anonymization dataOutput: ${(uploadedFile.dataOutput === null) ? `NULL` : `Blob, size: ${uploadedFile.dataOutput.size}`}`)
    delete uploadedFile.data;
    
    StoreANON.setUploadedFile(uploadedFile);
  }

  private downloadSlack()
  {
    Logger.addMessage(`Anonymization Download started`)
    const uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    if(uploadedFile.dataOutput === null)
    {
      Logger.addMessage(`Anonymization Download error - no dataOutput`)
      return;
    }
    const filenameParts = uploadedFile.name.split('.zip');
    const outputFilename = (filenameParts.length === 2) ? `${filenameParts[0]}-anonymized.zip` : uploadedFile.name;
    Logger.addMessage(`Anonymization Download outputFilename: ${outputFilename}`)
    DownloadBlob(uploadedFile.dataOutput as Blob, outputFilename);
  }

  private downloadTable = async () => {
    const uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    if(uploadedFile.dataOutput === null)
    {
      Logger.addMessage(`Anonymization Download error - no dataOutput`)
      return;
    }
    Download(uploadedFile.dataOutput as string, `${uploadedFile.namePart}-EXPORT.csv`, 'text/csv;encoding:utf-8', false);

  }

  private getWaitingContent()
  {
    const uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    let onAnonymize: () => Promise<void>;
    switch(this.type) {
      case 'slack': {
        onAnonymize = this.anonymizeSlack;
        break;
      }
      case 'zoom': {
        onAnonymize = this.anonymizeTable;
        break;
      }
      default: {
        onAnonymize = this.anonymizeTable;
        break;
      }
    }
    return (
      <WaitingContent 
        uploadedFile={uploadedFile}
        onAnonymize={onAnonymize}
      />
    )
  }

  private getProgressContent()
  {
    const uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    return (
      <ProgressContent 
        uploadedFile={uploadedFile}
        progress={this.state.progress}
      />
    )
  }

  private getArchivedContent()
  {
    const uploadedFile: UploadedFile = StoreANON.getUploadedFile() as UploadedFile;
    const outputFileHandle = StoreANON.getOutputFileHandle();
    return (
      <ArchivedContent 
        uploadedFile={uploadedFile}
        outputFileHandle={outputFileHandle}
        onDownload={this.type === 'slack' ? this.downloadSlack : this.downloadTable}
      />
    )
  }

  private getErrorContent()
  {
    return (
      <ErrorContent 
        error={this.state.error as string} 
        onReset={() => {
          StoreANON.resetUploadedFile();
          historyHashReplace(RouteList.welcome.path, this.props.history);
        }}
      />
    )
  }

  render()
  {
    const uploadedFile: UploadedFile | null = StoreANON.getUploadedFile();
    if(uploadedFile === null)
    {
      return null;
    }
    let content = this.getWaitingContent();;

    if(this.state.started)
    {
      switch(this.state.progress.phase)
      {
        case 'prepared': {
          content = this.getArchivedContent();
          break;
        }
  
        default: {
          content = this.getProgressContent();
          break;
        }
      }
    }
    if(this.state.error) {
      content = this.getErrorContent();
    }
    let prevButton = { 
      onClick: () => {
        historyHashReplace(RouteList.summary.path, this.props.history);
      }, 
      disabled: this.state.started || this.state.progress.phase !== 'waiting'
    }
    return (
      <Container>
        <Header title={lang.title} />
        <ProgressBar step={3} prevButton={prevButton} />
        <Screen>

        <div className={`anon-wrapper`}>
          <Title title={`3. ${lang.progressStep3Title}`}/>
          <div className={`anon`}>
            {content}
            <style>{`
            .anon-wrapper {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              align-content: flex-start;
              background-color: #F8F9FB;
              height: 100%;
              overflow: auto;
            }

            .anon {
              width: 460px;
              height: auto;
              border-radius: 5px;
              overflow: hidden;
              box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
              background-color: #FFFFFF;
            }
            `}</style>
          </div>
        </div>
        </Screen>
    </Container>
    )
  }
}

