// export type AllowedMIMETypes = 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'application/json' | 'application/zip';
export type AllowedMIMETypesSlack = 'application/zip';
export type AllowedMIMETypesTable = 'text/csv' | 'application/vnd.ms-excel' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export type AllowedMIMETypesZoom = 'text/csv';

export type ContentType = 'slack' | 'table' | 'zoom';

export type AnonymizationProgressPhase = 'waiting' | 'anonymize' | 'archive' | 'prepared';
export interface AnonymizationProgress
{
  phase  : AnonymizationProgressPhase;
  percent: number | null;
}

export interface UploadFile
{
  name: string;
  namePart: string;
  extPart: string;
  content: ArrayBuffer | string | null;
  file: File;
  type: AllowedMIMETypesSlack | AllowedMIMETypesTable | null;
}

export interface UploadedFile
{
  name: string;
  namePart: string;
  extPart: string;
  data: any;
  dataOutput: Blob | string | null;
  dataType: ContentType;
  dataSize: number;
  fileSize: number;
}

// eslint-disable-next-line
export type hashAlgorithmsAllowed = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512';
// eslint-disable-next-line
export const hashAlgorithmsAllowedLength: Record<hashAlgorithmsAllowed, number> = {
  'SHA-1': 40,
  'SHA-256': 64,
  'SHA-384': 96,
  'SHA-512': 128
};

export type EncryptionAlgorithmsAllowed = 'RSA-OAEP';

export interface AnonymizationSettingsDomains
{
  internalDomainList       : string[];
  anonymizeExternalUsername: boolean;
  anonymizeExternalDomain  : boolean;
  anonymizeInternalUsername: boolean;
  anonymizeInternalDomain  : boolean;
}

export interface AnonymizationSettings extends AnonymizationSettingsDomains
{
  anonymizationSalt: string;
  rsaPublicKey     : string;
}