import React, { FC } from 'react';
import {
  UploadedFile,
  lang
} from '../../../libs'

interface Prop
{
  uploadedFile: UploadedFile;
  onDelete: () => any;
}

export const LoadedContent: FC<Prop> = ({ 
  uploadedFile,
  onDelete,
  children
}) => {
  return (
    <>
      <div className={`loaded-wrapper`}>
        <div className={`loaded`}>
          <div className={`loaded-extension`}>{uploadedFile.dataType}</div>
        </div>
        <p className={`loaded-filename`}>{uploadedFile.name}</p>
        <p className={`loaded-filename-delete`} onClick={onDelete}>{lang.titleDeleteFile}</p>
      </div>
      <style>{`
      .loaded-wrapper {
        display: flex;
        width: 460px;
        align-content: center;
        align-items: center;
        flex-direction: column;
      }

      .loaded-wrapper .loaded {
        margin: 106px 0 15px;
      }

      .loaded-wrapper .loaded-extension {
        font-size: 16px;
        background-color: #530EAD;
        color: white;
        padding: 26px 20px;
        border-radius: 35px;
        text-transform: uppercase;
      }

      .loaded-filename,
      .loaded-filename-delete {
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid gray;
      }

      .loaded-filename {
        color: #530EAD;
        margin-bottom: 25px;
        padding-bottom: 3px;
      }

      .loaded-filename-delete {
        font-size: 13px;
        color: red;
        margin-bottom: 107px;
        padding-bottom: 2px;
        cursor: pointer;
      }
      `}</style>
      {children}
    </>
  )
}