import {
  ZipArchiveReader,
  ZipLocalFileHeader,
  ZipCentralDirHeader,
  ZipEndCentDirHeader,
  readLocalFileHeader,
  readCentralDirHeader,
  readEndCentDirHeader,
  readEndCentDirHeaderLocatorZip64,
  readEndCentDirHeaderZip64,
  isZIP64
} from './zip_archive_reader';
import { 
  ZipArchiveReaderProgress,
  ZipArchiveReaderProgressCallback,
  LOCAL_FILE_SIGNATURE, 
  ZIP64_CENTRAL_DIRECTORY_LOCATOR,
  ZIP64_CENTRAL_DIRECTORY_END,
  END_SIGNATURE
} from './common';

export interface ZipBlobArchiveReaderConstructorParams {
  blob: Blob;
  encoding?: string;
  chunkSize?: number;
  progressCallback?: (progress: ZipArchiveReaderProgress) => any;
}

/**
 * ZipBlobArchiveReader
 */
export class ZipBlobArchiveReader extends ZipArchiveReader {
  private blob: Blob;

  constructor(params: ZipBlobArchiveReaderConstructorParams);
  constructor(blob: Blob, encoding?: string, progressCallback?: ZipArchiveReaderProgressCallback | null, chunkSize?: number);
  constructor(blob: any, encoding?: string, progressCallback?: ZipArchiveReaderProgressCallback | null, chunkSize?: number) {
    super();
    this.blob = blob;
    if(encoding) this.encoding = encoding;
    if(progressCallback) this.progressCallback = progressCallback;
    if(chunkSize) this.chunkSize = chunkSize;
  }

  async init() {
    const blob = this.blob;
    let endCentDirHeader: ZipEndCentDirHeader;
    let centralDirHeaders: ZipCentralDirHeader[] = [];
    let localFileHeaders: ZipLocalFileHeader[] = [];
    let files: ZipLocalFileHeader[] = [];
    let folders: ZipLocalFileHeader[] = [];
    let offset: number;

    const readChunk = (start: number, end: number): Promise<ArrayBuffer> => {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => resolve(fr.result as ArrayBuffer);
        fr.onerror = err => reject(err);
        fr.readAsArrayBuffer(blob.slice(start, end));
      })
    }

    this.files = files;
    this.folders = folders;
    this.localFileHeaders = localFileHeaders;
    this.centralDirHeaders = centralDirHeaders;
    // validate first local file signature
    {
      const chunk = await readChunk(0, 4);
      if (new DataView(chunk).getUint32(0, true) === LOCAL_FILE_SIGNATURE) {
        offset = Math.max(0, blob.size - 0x8000);
      } else {
        throw new Error('zip.unpack: invalid zip file.');
      }
    }
    // validate end signature
    OUTER: do {
      const chunk = await readChunk(offset, Math.min(blob.size, offset + 0x8000));
      const view = new DataView(chunk);
      for (let i = chunk.byteLength - 4; i--; ) {
        if (view.getUint32(i, true) === END_SIGNATURE) {
          offset += i;
          break OUTER;
        }
      }
      offset = Math.max(offset - 0x8000 + 3, 0);
    } while (offset);
    if (!offset) throw new Error('zip.unpack: invalid zip file.');
    // read end central dir header
    endCentDirHeader = readEndCentDirHeader(await readChunk(offset, blob.size), 0);
    this.comment = endCentDirHeader.comment;
    if(isZIP64(endCentDirHeader)) {

      // ZIP64 Locator
      let zip64CentralEndLocator = null;
      while(true)
      {
        let chunk = await readChunk(offset, offset + 4);
        let view = new DataView(chunk);
        if(view.getUint32(0, true) === ZIP64_CENTRAL_DIRECTORY_LOCATOR) {
          zip64CentralEndLocator = readEndCentDirHeaderLocatorZip64(await readChunk(offset, blob.size), 0);
          break;
        }
        offset--;
        offset--;
        if(offset <= 0) throw new Error('zip.unpack: invalid zip64 file - EndCentralDirectory Locator not found - offset overflow!');
      }
      if(zip64CentralEndLocator === null) throw new Error('zip.unpack: invalid zip64 file - EndCentralDirectory Locator not found!');

      // ZIP64 Header
      offset = zip64CentralEndLocator.relativeOffsetEndOfZip64CentralDir;

      let chunk = await readChunk(offset, offset + 4);
      let view = new DataView(chunk);
      if(view.getUint32(0, true) !== ZIP64_CENTRAL_DIRECTORY_END)
      {
        throw new Error('zip.unpack: invalid zip64 file - EndCentralDirectory signature invalid!');
      }
      let zip64CentralEnd = readEndCentDirHeaderZip64(await readChunk(offset, blob.size), 0);
      
      endCentDirHeader.direntry = zip64CentralEnd.centralDirRecords;
      endCentDirHeader.startpos = zip64CentralEnd.centralDirOffset;
    }
    // read central dir headers
    await readChunk(endCentDirHeader.startpos, offset).then(buffer => {
      let offset = 0;
      let header: ZipCentralDirHeader;

      for (let i = 0; i < endCentDirHeader.direntry; ++i) {
        header = readCentralDirHeader(buffer, offset);
        header.index = i;
        centralDirHeaders.push(header);
        offset += header.allsize;
      }
    });
    // read local file headers //@SPEED-UP - derivate local header from central dir header
    const centralDirHeadersLength = centralDirHeaders.length;
    const offsetTotal             = this.blob.size;
    let   lastProgress: number    = 0;
    const progressCallback        = this.progressCallback;
    let localHeaderExtraLength = 0;
    for (let i = 0; i < centralDirHeadersLength; ++i) {
      if(!this.headerSpeedup || i === 0)
      {
        const offset = centralDirHeaders[i].headerpos;
        const view = new DataView(await readChunk(offset + 26, offset + 30));
        const fnamelen = view.getUint16(0, true);
        const extralen = view.getUint16(2, true);
        const header = readLocalFileHeader(await readChunk(offset, offset + 30 + fnamelen + extralen), 0);
        header.index = i;
        header.crc32 = centralDirHeaders[i].crc32;
        header.compsize = centralDirHeaders[i].compsize;
        header.uncompsize = centralDirHeaders[i].uncompsize;
        localFileHeaders.push(header);
        localHeaderExtraLength = header.extralen;
      }
      else
      {
        const localHeader = { ...centralDirHeaders[i] };
        localHeader.headersize = 30 + localHeader.fnamelen + localHeaderExtraLength;
        localHeader.allsize = localHeader.headersize + localHeader.compsize;
        localFileHeaders.push(localHeader);
      }
      if(!progressCallback) continue;
      const progress = Math.floor((offset / offsetTotal) * 100);
      if(lastProgress === progress) continue;
      progressCallback({ progress });
      lastProgress = progress;
    }
    return this._completeInit();
  }

  protected _decompressFile(fileName: string): Promise<Uint8Array> {
    const info = this._getFileInfo(fileName);
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        const result = new Uint8Array(fr.result as ArrayBuffer);
        resolve(this._decompress(result, info.isCompressed));
      }
      fr.onerror = err => reject(err);
      fr.readAsArrayBuffer(this.blob.slice(info.offset, info.offset + info.length));
    })
  }

  protected _decompressFileByIndex(index: number): Promise<Uint8Array> {
    const info = this._getFileInfoByIndex(index);
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        const result = new Uint8Array(fr.result as ArrayBuffer);
        resolve(this._decompress(result, info.isCompressed));
      }
      fr.onerror = err => reject(err);
      fr.readAsArrayBuffer(this.blob.slice(info.offset, info.offset + info.length));
    })
  }
}
