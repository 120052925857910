import * as React from 'react';
import { Fragment } from 'react';

type UploadProps = {
  arrayBuffer?: boolean;
  onChange: (fileRef: File) => void;
  onLoading?: () => void;
  accept?: string;
  progress?: string;
  buttonText?: string;
  disabled?: boolean;
}

export interface UploadState
{
  loading: boolean;
  loaded: boolean;
  filename: string | null;
}

const getDefaultState = (): UploadState => {
  return {
    loading: false,
    loaded: false,
    filename: null,
  }
}

export class Upload extends React.Component<UploadProps, UploadState> 
{
  constructor(props: Readonly<UploadProps>)
  {
    super(props);
    this.state      = getDefaultState();
  }

  private selectFile = (event: React.ChangeEvent<HTMLInputElement>) =>
  {
    if(this.props.disabled)
    {
      console.warn('File(s) cannot select - disabled');
      return;
    }
    if(!event.target.files || event.target.files.length === 0)
    {
      this.setState(getDefaultState());
      console.warn('No files!');
      return;
    }
    if(this.props.onLoading)
    {
      this.props.onLoading();
    }
    const fileRef = event.target.files[0];
    this.props.onChange(fileRef);
  }

  public render()
  {
    const opts = (this.props.accept) ? { 'accept': this.props.accept } : {};
    return (
      <Fragment>
        <div className={`upload-wrapper`}>
          <p className={`upload-button-text`}>
            {this.props.buttonText ? this.props.buttonText : 'Browse Files'}
          </p>
          <input type="file" {...opts} className={`upload-input`} onChange={this.selectFile} onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            if(this.props.disabled) 
            {
              e.preventDefault();
              return false;
            }
          }} />
        </div>
        <style>{`
          .upload-wrapper {
            display: flex;
            position: relative;
            margin: 0 2px;
            border-radius: 5px;
            background-color: #530EAD;
          }

          .upload-button-text {
            font-weight: bold;
            padding: 12px 17px;
            color: #FFFFFF;
          }

          .upload-input {
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
          }
        `}</style>
      </Fragment>
    )
  }
}