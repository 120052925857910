import * as zip from "./ziptools";
import { FileSystemWritableFileStream } from './';

export interface IAnonymizerWriter
{
  write: (fileName: string, data: Uint8Array) => any;
}

export class AnonymizerWriter implements IAnonymizerWriter
{
  protected zipWriter: zip.ZipArchiveWriter;
  protected compression: number;

  constructor(zipWriter: zip.ZipArchiveWriter, compression: number)
  {
    this.zipWriter = zipWriter;
    this.compression = compression;
  }

  async write(fileName: string, data: Uint8Array)
  {
    this.zipWriter.write(fileName, data, this.compression);
    return Promise.resolve();
  }

  async writeEnd()
  {
    this.zipWriter.writeEnd();
    return Promise.resolve();
  }
}

export class AnonymizerWriterFileAPI implements IAnonymizerWriter
{
  protected outputFileHandle: FileSystemWritableFileStream;
  protected zipWriter: zip.ZipArchiveWriter;
  protected compression: number;
  protected buffer: Uint8Array[] = [];
  protected bufferSize: number = 0;
  readonly  bufferSizeLimit: number = 250000; //bytes

  constructor(zipWriter: zip.ZipArchiveWriter, compression: number, outputFileHandle: FileSystemWritableFileStream)
  {
    this.outputFileHandle = outputFileHandle;
    this.zipWriter = zipWriter;
    this.compression = compression;
    this.buffer = [];
    this.bufferSize = 0;
  }

  private concatUint8(values: Uint8Array[]): Uint8Array
  {
    const length = values.reduce((previous, current) => previous + current.length, 0);
    const output = new Uint8Array(length);
    let offset = 0;
    for(const value of values)
    {
      output.set(value, offset);
      offset += value.length;
    }
    return output;
  }

  async processBuffer(data: Uint8Array, forceWrite: boolean = false)
  {
    this.buffer.push(data);
    this.bufferSize += data.length;
    if(forceWrite || this.bufferSize >= this.bufferSizeLimit)
    {
      await this.outputFileHandle.write(this.concatUint8(this.buffer));
      this.bufferSize = 0;
      this.buffer.length = 0;
    }
  }

  async write(fileName: string, data: Uint8Array)
  {
    const output = this.zipWriter.writeSync(fileName, data, this.compression);
    await this.processBuffer(output);
  }

  async writeEnd()
  {
    const output = this.zipWriter.writeEndSync();
    await this.processBuffer(output, true);
    await this.outputFileHandle.close();
  }
}