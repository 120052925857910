import {
  hashAlgorithmsAllowed,
  hashAlgorithmsAllowedLength,
  AnonymizationSettingsDomains,
} from './types';
import { emailAliasSeparator } from './patterns';
import { encodePolyfill } from './utilsUTF8';

export class AnonymizerHashing
{
  private readonly lengthMin: number = 8;
  private hashAlgorithm: hashAlgorithmsAllowed;
  private salt: string;
  private anonymizationSettingsDomains: AnonymizationSettingsDomains = {
    internalDomainList       : [],
    anonymizeExternalUsername: true,
    anonymizeExternalDomain  : true,
    anonymizeInternalUsername: true,
    anonymizeInternalDomain  : true  
  };
  private anonymizationUnknown: boolean = false;
  private length: number;
  private hashedEmailCache: { [key in string]: string } = {};
  private crypto: Crypto;
  
  constructor(salt: string, anonymizationSettingsDomains: AnonymizationSettingsDomains | null, length: number = 16, hashhashAlgorithm: hashAlgorithmsAllowed = 'SHA-512')
  {
    const allowedLengthMax = hashAlgorithmsAllowedLength[hashhashAlgorithm];
    if(length < this.lengthMin || length > allowedLengthMax)
    {
      console.warn(`Anonymizer: Invalid length ${length} for algorithm ${hashhashAlgorithm}! set default size ${allowedLengthMax}`);
      length = allowedLengthMax;
    }
    if(anonymizationSettingsDomains)
    {
      this.anonymizationSettingsDomains = anonymizationSettingsDomains;
      this.anonymizationUnknown = [anonymizationSettingsDomains.anonymizeExternalDomain, anonymizationSettingsDomains.anonymizeExternalUsername, anonymizationSettingsDomains.anonymizeInternalDomain, anonymizationSettingsDomains.anonymizeInternalUsername].includes(true);
    }
    this.length = length;
    this.salt   = salt;
    this.hashAlgorithm = hashhashAlgorithm;
    this.length = length;
    this.crypto = window.crypto;
  }

  setCrypto(crypto: Crypto)
  {
    this.crypto = crypto;
  }

  process = async (value: string) => {
    const hashedBuff = await this.crypto.subtle.digest(this.hashAlgorithm, this.encode(`${this.salt}${value}`));
    const hashArray = Array.from(new Uint8Array(hashedBuff));
    return (hashArray.map(b => b.toString(16).padStart(2, '0')).join('')).substr(0, this.length);
  }

  hashEmail = async (email: string) => {
    if(email in this.hashedEmailCache)
    {
      return Promise.resolve(this.hashedEmailCache[email]);
    }
    const anonymizationSettingsDomains = this.anonymizationSettingsDomains;
    const emailParts = email.split('@').map(v => v.trim().toLocaleLowerCase());
    if(emailParts.length !== 2)
    {
      if(this.anonymizationUnknown)
      {
        const emailUnknown = typeof email === 'object' ? '[object]' : `${email}`;
        const emailUnknownAnonymized = await this.process(emailUnknown);
        return Promise.resolve(`[InvalidEmail]${emailUnknownAnonymized}`);
      }
      return Promise.resolve(`${email}`.toLocaleLowerCase());
    }
    const isInternalDomain = anonymizationSettingsDomains.internalDomainList.includes(emailParts[1]);
    if((isInternalDomain && anonymizationSettingsDomains.anonymizeInternalUsername) || (!isInternalDomain && anonymizationSettingsDomains.anonymizeExternalUsername))
    {
      emailParts[0] = await this.process(emailParts[0]);
    }
    if((isInternalDomain && anonymizationSettingsDomains.anonymizeInternalDomain) || (!isInternalDomain && anonymizationSettingsDomains.anonymizeExternalDomain))
    {
      const domainHash = await this.process(emailParts[1]);
      emailParts[1] = `${domainHash}.hash`;
    }
    this.hashedEmailCache[email] = emailParts.join('@');
    return this.hashedEmailCache[email];
  }
 
  hashEmails = async (emails: string) => {
    const emailsParts = emails.trim().split(emailAliasSeparator);
    for(let i = 0, len = emailsParts.length; i < len; i++)
    {
      emailsParts[i] = await this.hashEmail(emailsParts[i]);
    }
    return emailsParts.join(emailAliasSeparator);
  }

  private encode = (value: string): Uint8Array => typeof TextEncoder !== 'undefined' ? (new TextEncoder()).encode(value) : encodePolyfill(value);
}
