import React, { FC, Fragment } from 'react';
import Close from '@material-ui/icons/CloseOutlined';
import { 
  Button, 
  ButtonTheme
} from '../components'
import { defaultTheme } from './theme'

interface ButtonProps {
  onClick?: () => any;
  title: string;
  theme?: ButtonTheme;
}

type JustifyContent = 'left' | 'right' | 'center';

interface Prop {
  onClose: () => any;
  title?: string;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  closeOnBackground?: boolean;
  justify?: JustifyContent;
  width?: number;
}

let refModalWrapper: any = null;

export const Modal: FC<Prop> = ({
  onClose,
  title,
  leftButton,
  rightButton,
  closeOnBackground = false,
  justify = 'center',
  width = 600,
  children 
}) => {
  return (
    <Fragment>
      <div className={`modal-wrapper`} ref={(ref:any) => refModalWrapper = ref} onClick={(e:any) => {
          if(e.target === refModalWrapper && closeOnBackground)
          {
            onClose();
          }
        }}>
        <div className={`modal`}>
          {title ? (
            <div className={`title-wrapper`}>
              <p>{title}</p>
              <div className={`close`} onClick={onClose}>
                <Close htmlColor={defaultTheme.colors.grayDark} />
              </div>
            </div>
          ) : null}
          <div className={`content-wrapper`}>
            {children}
          </div>
          {(leftButton || rightButton) ? (
            <div className={`actions-wrapper`}>
              <div className={`action-left`}>
                {leftButton ? <Button onClick={leftButton.onClick ? leftButton.onClick : undefined} text={leftButton.title} theme={leftButton.theme} /> : null}
              </div>
              <div className={`action-right`}>
                {rightButton ? <Button onClick={rightButton.onClick ? rightButton.onClick : undefined} text={rightButton.title} theme={rightButton.theme} /> : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <style>{`
        div.modal-wrapper {
          width: 100vw;
          height: 100vh;
          align-items: center;
          justify-content: center;
          display: flex;
          position: fixed;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1000;
        }

        div.modal {
          width: ${width}px;
          background-color: ${defaultTheme.colors.clear};
          border-radius: 5px;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
        }

        div.modal .title-wrapper,
        div.modal .content-wrapper,
        div.modal .actions-wrapper {
          padding: 20px;
          width: calc(100% - 40px);
        }

        div.modal .title-wrapper {
          border-bottom: 1px solid ${defaultTheme.colors.gray};
          height: calc(62px - 40px);
          user-select: none;
        }

        div.modal .title-wrapper p {
          text-align: left;
          font-size: 18px;
          font-weight: bold;
          float: left;
          display: inline-block;
        }

        div.modal .title-wrapper .close {
          float: right;
          cursor: pointer;
        }

        div.modal .content-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: ${justify};
          align-items: center;
        }

        div.modal .actions-wrapper {
          background-color: ${defaultTheme.colors.light};
          padding-top: 15px;
          padding-bottom: 15px;
          height: 64px;
          border-radius: 0px 0px 5px 5px;
          user-select: none;
        }

        div.modal .actions-wrapper .action-left {
          float: left;
        }

        div.modal .actions-wrapper .action-right {
          float: right;
        }
      `}</style>
    </Fragment>
  
  )
}