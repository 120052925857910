import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  BoxScreen,
  Upload
} from '../../../components'
import {
  publicURL,
  isFileAPISupported,
  isFileAPIFlagSupported,
  ContentType
} from '../../../libs';

interface Prop
{
  dragging: boolean;
  disabled: boolean;
  uploadArrayBuffer: boolean;
  uploadSizeLimit: number;
  uploadError: string | null;
  type: ContentType;
  allowedExtensions: string[];
  onChange: (fileRef: File) => any;
  onLoading: () => any;
}

let showNativeFileSystemAPIFlag = isFileAPISupported() === false && isFileAPIFlagSupported() === true;

export const UploaderContent: FC<Prop> = ({ 
  dragging,
  disabled,
  uploadArrayBuffer,
  uploadSizeLimit,
  uploadError,
  type,
  allowedExtensions,
  onChange,
  onLoading,
}) => {
  const background = dragging ? `#eaebff` : `#FFFFFF`;
  const image = type === 'slack' ? 'Import-slack.svg' : 'Import-HR-table.svg';
  const title = `Drop your ${type === 'slack' ? 'Slack archive' : 'Table'} to import`;
  const errorElement: JSX.Element | null = uploadError ? (
    <div className={`uploader-error`}><ReactMarkdown>{uploadError}</ReactMarkdown></div>
  ) : null;
  const supportedElement: JSX.Element | null = (!uploadError && showNativeFileSystemAPIFlag) ? (
    <div className={`supported-error`}>
      <p>
        {`Your browser is suported `}
        <em>{`Native File System API`}</em>
        {` ability, you can process much bigger files with enabled `}
        <em>{`Native File System API flag: `}</em>
        <strong>{`chrome://flags/#native-file-system-api`}</strong>
        <a href={`${publicURL}/images/chrome-83-filesystem-flag.png`} target="_blank" rel="noopener noreferrer">
          <img src={`${publicURL}/images/chrome-83-filesystem-flag.png`} width="394" alt="how to enable File System API" />
        </a>
      </p>
    </div>
  ) : null;
  return (
    <>
      {errorElement}
      {supportedElement}
      <div className={dragging ? `uploader-content dnd` : `uploader-content`}>
        <BoxScreen mx={50} mt={19} mb={22} opacity={dragging ? 0.3 : 1.0}>
          <div className={`drag-drop-image`} />
          <h2 className={`upload-title`}>{title}</h2>
          <p className={`upload-notes`}>{getNotes(type, uploadSizeLimit)}</p>
          <p className={`upload-divider`}>or</p>
          <div className={`upload-component-wrapper`}>
            <Upload 
              progress={'#7fc53b'}
              accept={allowedExtensions.join(',')}
              onChange={onChange}
              onLoading={onLoading}
              arrayBuffer={uploadArrayBuffer}
              disabled={disabled}
            />
          </div>
        </BoxScreen>
        <style>{`
        div.drag-drop-image {
          background-image: url("${publicURL}/images/${image}");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 180px;
          height: 90px;
        }

        h2.upload-title {
          font-size: 16px;
          margin: 20px 0 10px;
        }

        p.upload-notes {
          text-align: center;
          font-size: 16px;
          border-bottom: 1px solid #DCE0E9;
          padding-bottom: 27px;
        }

        p.upload-divider {
          background-color: #FFF;
          border: 1px solid #DCE0E9;
          border-radius: 18px;
          padding: 7px 9px;
          margin-top: -17px;
        }

        div.upload-component-wrapper {
          margin-top: 20px;
        }

        .uploader-content {
          width: 424px;
          margin: 20px;
          border: 2px dashed #F1F1F2;
          background-color: ${background};
          border-radius: 5px;
        }

        .uploader-content.dnd {
          border: 2px dashed #1976D2;
          background-color: #F8F9FB;
        }

        div.uploader-error,
        div.supported-error {
          margin: 20px 20px 0;
          border: 1px solid #B71C1C;
          padding: 12px 12px 10px;
          background-color: #FFCDD2;
          font-size: 13px;
          color: #B71C1C;
          border-radius: 5px;
        }

        div.uploader-error p,
        div.supported-error p {
          line-height: 145%;
        }

        div.uploader-error p,
        div.uploader-error p a {
          color: #3E3F42;
        }

        div.supported-error {
          background-color: #F4F4F4;
          border-color: #B2B3C1;
        }

        div.supported-error p,
        div.supported-error p a {
          color: #343541;
        }
        `}</style>
      </div>
    </>
  )
}

const getNotes = (type: ContentType, uploadSizeLimit: number): string => {
  let typeName: string;
  switch(type) {
    case 'slack': {
      typeName = 'ZIP file';
      break;
    }
    case 'table': {
      typeName = 'Excel';
      break;
    }
    case 'zoom': {
      typeName = 'CSV file';
      break;
    }
  }
  return `${typeName} file. ${uploadSizeLimit} MB max.`
  
}