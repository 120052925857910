import { Store } from './store';
import { UploadedFile, AnonymizationProgressPhase } from './types';
import { FileSystemWritableFileStream, FileSystemFileHandle } from './fileSystemApi';

const id                    = Math.random().toString(36).slice(2);
const keyUploadedFile       = `${id}-uploaded-file`;
const keyData               = `${id}-data`;
const keyFilter             = `${id}-filter`;
const keyUploadedFileStatus = `${id}-uploaded-file-state`;
const keyFileHandle         = `${id}-file-handle`;
const keyOutputFileHandle   = `${id}-output-file-handle`;

export class StoreANON extends Store
{
  static resetUploadedFile()
  {
    Store.reset(keyUploadedFile);
    StoreANON.resetUploadedFileStatus();
  }

  static getUploadedFile(): UploadedFile | null
  {
    const uploadedFile = Store.get(keyUploadedFile);
    return uploadedFile ? uploadedFile as UploadedFile : null;
  }
  
  static setUploadedFile(uploadedFile: UploadedFile)
  {
    Store.set(keyUploadedFile, uploadedFile);
  }

  static resetUploadedFileStatus()
  {
    Store.set(keyUploadedFileStatus, 'waiting');
  }

  static getUploadedFileStatus(): AnonymizationProgressPhase
  {
    let anonymized = Store.get(keyUploadedFileStatus);
    if(!anonymized)
    {
      Store.set(keyUploadedFileStatus, 'waiting');
      anonymized = Store.get(keyUploadedFileStatus);
    }
    return anonymized as AnonymizationProgressPhase;
  }

  static setUploadedFileStatus(phase: AnonymizationProgressPhase)
  {
    Store.set(keyUploadedFileStatus, phase);
  }

  static resetData()
  {
    Store.reset(keyData);
  }

  static getData(): any[][] | null 
  {
    const data = Store.get(keyData);
    return data ? data as any[][] : null;
  }

  static setData(value: any[][]) 
  {
    Store.set(keyData, value);
  }

  static resetFilter() 
  {
    Store.reset(keyFilter);
  }

  static getFilter(): string | null
  {
    const filter = Store.get(keyFilter);
    return filter ? filter as string : null;
  }

  static setFilter(value: string) 
  {
    Store.set(keyFilter, value);
  }

  static setOutputFileHandle(handle: FileSystemWritableFileStream) {
    Store.set(keyOutputFileHandle, handle);
  }

  static resetOutputFileHandle() {
    Store.reset(keyOutputFileHandle);
  }

  static getOutputFileHandle(): FileSystemWritableFileStream | null {
    const data = Store.get(keyOutputFileHandle);
    return data ? data as FileSystemWritableFileStream : null;
  }

  static setFileHandle(handle: FileSystemFileHandle) {
    Store.set(keyFileHandle, handle);
  }
}
