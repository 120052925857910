import { Logger } from './logger';
import { isSupportedPerformanceMemory, getPerformanceMemory } from './memory';

export const DownloadBlob = (content: Blob, fileName: string) => {
  Logger.addMessage(`DownloadBlob size: ${content.size}, type: ${content.type}, filename: ${fileName}`)
  let a = document.createElement('a');
  if(URL && 'download' in a) //html5 A[download]
  {
    Logger.addMessage(`DownloadBlob as HTML5`)
    a.href = URL.createObjectURL(content);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = document.createElement('a');
    return;
  }
  Logger.addMessage(`DownloadBlob as DataURL`)
  const reader = new FileReader();
  reader.readAsDataURL(content); 
  reader.onloadend = () => {
    window.location.assign('data:application/octet-stream,' + encodeURIComponent(reader.result as string));
  }
}

export const isDeviceMemorySupported = (): boolean => {
  return (`deviceMemory` in navigator);
}

export const getDeviceMemory = (): number | null => {
  return (`deviceMemory` in navigator) ? navigator.deviceMemory : null;
}

export const getUserAgentAdvanced = (): any => {
  const parts: string[] = [navigator.userAgent];
  if(isDeviceMemorySupported()) parts.push(`deviceMemory: ${navigator.deviceMemory.toFixed(2)}`);
  if(`hardwareConcurrency` in navigator) parts.push(`hardwareConcurrency: ${navigator.hardwareConcurrency.toFixed(2)}`);
  try {
    if(isSupportedPerformanceMemory()) parts.push(`memory: ${JSON.stringify(getPerformanceMemory())}`);
  } catch(exception) {
    parts.push(`memory: window.performance.memory not supported`);
  }
  return parts.join(', ');
}

export const JSONFormatted = (data: any) => JSON.stringify(data, null, 2);

export class Timer {
  private d: Date = new Date();

  constructor() {
    this.reset();
  }
  
  diff(output: boolean = false)
  {
    const d = new Date();
    const diff = (d.getTime() - this.d.getTime()) / 1000;
    this.d = d;
    if(output) console.log('diff', diff);
    return diff;
  }

  reset()
  {
    this.d = new Date();
  }
}

export const getDateByHash = (hash?: string): Date => {
  const defaultDate = new Date('1970-01-01');
  if(!hash || typeof hash !== 'string') return defaultDate;
  const parsedDataRange = hash.match(/#date-range=((last)-(\d)-(months|years))/);
  if(!parsedDataRange) return defaultDate;
  const value = parseInt(parsedDataRange[3]);
  const range = parsedDataRange[4];
  const dateByHash = new Date();
  switch(range)
  {
    case 'months': {
      dateByHash.setMonth(dateByHash.getMonth() - value);
      break;
    }

    case 'years': {
      dateByHash.setFullYear(dateByHash.getFullYear() - value);
      break;
    }
  }
  dateByHash.setDate(1); /* make date offset for interleave - date set fixed to 1. day of month */
  dateByHash.setMonth(dateByHash.getMonth() - 1); /* make date offset for interleave - date reserve one month back */
  return dateByHash;
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const ArrayBufferPrepend = (buffer: ArrayBuffer, prependData: number[]): ArrayBuffer => {
  const prependBuffer = new Uint8Array(prependData).buffer;
  const tmpBuffer = new Uint8Array(buffer.byteLength + prependBuffer.byteLength);
  tmpBuffer.set(new Uint8Array(prependBuffer), 0);
  tmpBuffer.set(new Uint8Array(buffer), prependBuffer.byteLength);
  return tmpBuffer;
}

export const prependArrayBufferBOM = (buffer: ArrayBuffer): ArrayBuffer => {
  let isBOMPresent = false;
  const BOM = [0xEF, 0xBB, 0xBF];
  try
  {
    const bufferSliced = Array.from(new Uint8Array(buffer.slice(0, BOM.length)));
    isBOMPresent = JSON.stringify(bufferSliced) === JSON.stringify(BOM);
  } 
  catch(e) 
  {
    isBOMPresent = false;
  }
  return (isBOMPresent) ? new Uint8Array(buffer) : ArrayBufferPrepend(buffer, BOM);
}

export const Download = (content: string, fileName: string, mimeType: string, isBuffer?: boolean) => {
  const a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';
  const realContent = isBuffer ? [Buffer.from(content)] : [content];
  if(URL && 'download' in a) // html5 A[download]
  {
    a.href = URL.createObjectURL(new Blob(realContent, { type: mimeType }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  else 
  {
    window.location.assign('data:application/octet-stream,' + encodeURIComponent(content));
  }
}