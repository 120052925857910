import React, { FC } from 'react';
import {
  defaultTheme,
  Button
} from '../../../components'
import {
  UploadedFile,
  lang,
  formatBytes,
  ContentType
} from '../../../libs'

interface Prop
{
  uploadedFile    : UploadedFile;
  type            : ContentType | null;
  onShowFiles     : () => any;
  onFilterChannels: () => any;
  onNextStep      : () => any;
}

export const SummaryContent: FC<Prop> = ({ 
  uploadedFile,
  // onShowFiles,
  type,
  onFilterChannels,
  onNextStep,
  children
}) => {
  const fileSize = uploadedFile.dataSize === null ? null : uploadedFile.dataSize;
  return (
    <>
      <div className={`summary-content-wrapper`}>
        <p className={`summary-content-filename`}>{uploadedFile.name}</p>
        <p className={`summary-content-filename-size`}>{fileSize === null ? null : `File size: ${formatBytes(fileSize)}`}</p>
        <div className={`summary-content-buttons`}>
        {/* <Button onClick={onShowFiles} text={lang.titleShowFiles} disabled={false} theme={defaultTheme.buttons.purple} /> */}
        {type === 'slack' && <Button onClick={onFilterChannels} text={lang.titleFilterChannels} disabled={false} theme={defaultTheme.buttons.purple} />}
        <Button onClick={onNextStep} text={lang.buttonGoAnonymization} disabled={false} theme={defaultTheme.buttons.purple} />
        </div>
      </div>
      <style>{`
      .summary-content-wrapper {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        margin: 60px 40px;
      }

      .summary-content-buttons {
        display: flex;
        flex-direction: row;
      }

      .summary-content-filename,
      .summary-content-filename-size {
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid gray;
      }

      .summary-content-filename {
        color: #530EAD;
        margin-bottom: 25px;
        padding-bottom: 3px;
      }

      .summary-content-filename-size {
        font-size: 13px;
        color: #390074;
        margin-bottom: 37px;
        padding-bottom: 2px;
      }
      `}</style>
      {children}
    </>
  )
}