import * as zip from "./ziptools";
import {
  Logger,
  UploadedFile,
  FileSystemWritableFileStream
} from '.'
import { AnonymizerSlackHelper } from './anonymizerSlackHelper';
import { AnonymizerWriter, AnonymizerWriterFileAPI } from './anonymizerWriter';
import { ProgressCounter, ProgressCounterListener } from './progressCounter';
import { COMMENT_ANONYMIZED_SLACK, COMMENT_ANONYMIZED_SLACK_HASHED } from "../constants";
import { JSONFormatted } from "./utils";
import { ANONYMIZED_STR, CHANNEL_FILE_REGEX, DIRECTORY_LAST_SEPARATOR_REGEX, DIRECTORY_SEPARATOR_REGEX, HASH_SALT, JSON_REGEX, SYSTEM_FILE_REGEX, TEAM_REGEX } from "./constants";
import { AnonymizerHashing } from "./anonymizerUtils";
import { userKey } from "../user";

type ZipLocalFileHeader = zip.ZipLocalFileHeader;

export interface ZipEntry
{
  item: ZipLocalFileHeader;
  data?: ArrayBuffer | string;
}

type ObjectMap = { [key in string]: string };
type ObjectObjectMap = { [key in string]: ObjectMap };
type hashAlgorithmsAllowed = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512';
const hashAlgorithmsAllowedLength: { [key in hashAlgorithmsAllowed]: number } = {
  'SHA-1'  : 40,
  'SHA-256': 64,
  'SHA-384': 96,
  'SHA-512': 128
};

interface MatchedFilename
{
  file  : string;
  folder: string;
  root  : boolean;
  team  : string | 'root';
}

const isString = (value: any) => (typeof value === 'string' || value instanceof String);
const isObject = (value: any) => (typeof value === 'object' && value !== null);
const getError = (error: any): string => isString(error) ? error : (isObject(error) && 'message' in error) ? error.message : `${error}`;

const isRootFile    = (filename: string) => filename.match(DIRECTORY_SEPARATOR_REGEX) === null ? true : false;
const isSystemFile  = (filename: string) => filename.match(SYSTEM_FILE_REGEX) === null ? false : true;
const isChannelFile = (filename: string) => filename.match(CHANNEL_FILE_REGEX) === null ? false : true;

const getTeamByPath = (filename: string): string | 'root' => {
  const result = filename.match(TEAM_REGEX);
  if(result && result.length === 2)
  {
    return result[1];
  }
  return 'root';
}


const delay = (timems: number): Promise<void> => new Promise((resolve, reject) => setTimeout(() => resolve(), timems));
const concatUint8 = (values: Uint8Array[]): Uint8Array => {
  const length = values.reduce((previous, current) => previous + current.length, 0);
  const output = new Uint8Array(length);
  let offset = 0;
  for(const value of values)
  {
    output.set(value, offset);
    offset += value.length;
  }
  return output;
}

class DateRangeFilter
{
  private day: number;
  private month: number;
  private year: number;

  constructor(dateFrom: Date)
  {
    this.day   = dateFrom.getDay();
    this.month = dateFrom.getMonth() + 1;
    this.year  = dateFrom.getFullYear();
  }

  isFilenameInRange(filename: string): boolean
  {
    const dateParts = filename.match(CHANNEL_FILE_REGEX);
    if(dateParts === null) return false;
    const day = parseInt(dateParts[4]);
    const month = parseInt(dateParts[3]);
    const year = parseInt(dateParts[1]);
    if(this.year > year) return false;
    if(this.year < year) return true;
    if(this.month > month) return false;
    if(this.month < month) return true;
    return (this.day <= day);
  }
}

export class AnonymizerSlack
{
  readonly channelsDelaysCount    : number = 5000;
  private  readonly lengthMin     : number = 8;
  private  readonly zipCompression: number = 1;
  private  channelsDelays         : boolean = false;
  private  hashAlgorithm          : hashAlgorithmsAllowed;
  private  salt                   : string;
  private  length                 : number;
  private  channelsNames2Ids      : ObjectObjectMap = { root: {} };
  private  outputFileHandle       : FileSystemWritableFileStream | null = null;
  readonly comment                : Uint8Array;
  readonly anonymizationRequired  : boolean;
  readonly anonymizerSlackHelper  : AnonymizerSlackHelper;
  
  constructor(salt: string = HASH_SALT, length: number = 20, hashAlgorithm: hashAlgorithmsAllowed = 'SHA-256')
  {
    const allowedLengthMax = hashAlgorithmsAllowedLength[hashAlgorithm];
    if(length < this.lengthMin || length > allowedLengthMax)
    {
      console.warn(`Anonymizer: Invalid length ${length} for algorithm ${hashAlgorithm}! set default size ${allowedLengthMax}`);
      length = allowedLengthMax;
    }
    this.length = length;
    this.salt   = salt;
    this.hashAlgorithm = hashAlgorithm;
    this.length = length;   
    let anonymizerHashing: AnonymizerHashing | null = null;
    this.anonymizationRequired = userKey.getAnonymizationRequired();
    if(this.anonymizationRequired) {
      const anonymizationKey = userKey.getAnomymizationKey();
      const anonymizationSettingsDomains = userKey.getAnonymizationSettingsDomains();
      anonymizerHashing = new AnonymizerHashing(anonymizationKey, anonymizationSettingsDomains);
    }
    this.anonymizerSlackHelper = new AnonymizerSlackHelper(anonymizerHashing);
    const encoder = new TextEncoder();
    this.comment = encoder.encode(this.anonymizationRequired ? COMMENT_ANONYMIZED_SLACK_HASHED : COMMENT_ANONYMIZED_SLACK);
  }

  setOutputFileHandle = (fileHandle: FileSystemWritableFileStream) => {
    this.outputFileHandle = fileHandle;
  }

  encrypt = async (value: string) => {
    const hashedBuff = await window.crypto.subtle.digest(this.hashAlgorithm, new TextEncoder().encode(value + this.salt));
    const hashArray = Array.from(new Uint8Array(hashedBuff));
    return (hashArray.map(b => b.toString(16).padStart(2, '0')).join('')).substr(0, this.length);
  }

  private matchFileName = (filename: string): MatchedFilename | null => {
    const match = filename.match(JSON_REGEX);
    if(match === null) return null;
    return {
      file  : match[0],
      folder: (match.index! > 0) ? filename.substr(0, match.index! - 1): filename,
      root  : isRootFile(filename),
      team  : getTeamByPath(filename)
    }
  }

  private prepareTeamForChannelsMap = (team: string) => {
    if(team in this.channelsNames2Ids)
    {
      return;
    }
    this.channelsNames2Ids[team] = {};
  }

  private anonymizeSystemEntry = (entry: ZipLocalFileHeader, dataJSON: string): Promise<ZipEntry> => {
    return new Promise(async (resolve, reject) => {
      const matchedFilename = this.matchFileName(entry.fileName);
      const entryOut = { item: entry, data: '' };
      let data;
      if(matchedFilename === null) 
      {
        reject(`Unknown file "${entry.fileName}"`);
        return;
      }
      try
      {
        data = JSON.parse(dataJSON);
      }
      catch(error) 
      {
        Logger.addMessage(`Unable to parse JSON "${entry.fileName}"`);
        data = `Unknown filename [invalid JSON] ${entry.fileName}: ${ANONYMIZED_STR}`;
        entryOut.data = JSONFormatted(data);
        resolve(entryOut);
        return;
      }
      if(!Array.isArray(data))
      {
        Logger.addMessage(`Invalid structure in file "${entry.fileName}"`);
        data = `Unknown filename [Root] ${entry.fileName}: ${ANONYMIZED_STR}`;
        entryOut.data = JSONFormatted(data);
        resolve(entryOut);
        return;
      }
      const team = matchedFilename.team;
      switch(matchedFilename.file)
      {
        case 'dms.json': {
          this.prepareTeamForChannelsMap(team);
          for(const item of data)
          {
            this.channelsNames2Ids[team][item.id] = item.id;
          }
          break;
        }

        case 'channels.json':
        case 'groups.json':
        case 'mpims.json': {
          this.prepareTeamForChannelsMap(team);
          for(let item of data) 
          {
            this.channelsNames2Ids[team][item.name] = item.id;
            this.channelsNames2Ids[team][item.id]   = item.id;
            //@TODO - DISABLED item.name    = await this.encrypt(item.name);
            item = this.anonymizerSlackHelper.anonymizeChannelItem(item);
          }
          break;
        }

        case 'users.json': {
          for(let i = 0; i < data.length; i++)
          {
            data[i] = await this.anonymizerSlackHelper.anonymizeUser(data[i]);
          }
          break;
        }

        case 'org_users.json': {
          for(let i = 0; i < data.length; i++)
          {
            data[i] = await this.anonymizerSlackHelper.anonymizeOrgUser(data[i]);
          }
          break;
        }

        default: {
          data = `Unknown filename [not System Entry] ${entry.fileName}: ${ANONYMIZED_STR}`;
          break;
        }
      }
      try
      {
        entryOut.data = JSONFormatted(data);
      }
      catch(e)
      {
        reject(`Unable to stringify JSON "${entry.fileName}"`);
        return;
      }
      resolve(entryOut);
    })
  }

  private anonymizeChannelEntry = async (entry: ZipLocalFileHeader, dataJSON: string): Promise<string> => {
    let data;
    if(!dataJSON) 
    {
      throw new Error(`Unable to process item "${entry.fileName}"`);
    }
    data = JSON.parse(dataJSON);
    if(!Array.isArray(data))
    {
      Logger.addMessage(`Unable to process item "${entry.fileName}" - is not array`);
      throw new Error(`Unable to process item "${entry.fileName}" - is not array`);
    }
    for(let i = 0; i < data.length; i++)
    {
      data[i] = await this.anonymizerSlackHelper.anonymizeMessage(data[i]);
    }
    return JSONFormatted(data);
  }

  anonymize = async (uploadedFile: UploadedFile, filterChannels: string | null, dateRange: Date, progressCallback: ProgressCounterListener): Promise<Blob | null> => {
    this.channelsNames2Ids = { root: {} };
    const filterChannelsList: RegExp[] | null = filterChannels ? filterChannels.split("\n").filter(channel => channel.trim() !== '' && channel.trim() !== '*').map(filterChannel => {
      return filterChannel.match(/\*$/) ? new RegExp(`^${filterChannel.replace(/\*$/, '')}`) : new RegExp(`${filterChannel}`)
    }) : null;
    const folderMap: ObjectObjectMap = { root: {} };
    const zipReader = uploadedFile.data as zip.ZipArchiveReader;
    zipReader.setHeaderSpeedup(true); // Derivate Local header from Central dir header - very speed-up
    const dateRangeFilter = new DateRangeFilter(dateRange);
    const entrieFolders   = zipReader.getFolders().map((entry: zip.ZipLocalFileHeader) => entry.fileName.replace(DIRECTORY_LAST_SEPARATOR_REGEX, ''));
    const entriesSystem   = zipReader.getFiles().filter((entry: zip.ZipLocalFileHeader) => isSystemFile(entry.fileName));
    const entriesChannels = zipReader.getFiles().filter((entry: zip.ZipLocalFileHeader) => isChannelFile(entry.fileName) && dateRangeFilter.isFilenameInRange(entry.fileName));
    const entriesUnknown  = zipReader.getFiles().filter((entry: zip.ZipLocalFileHeader) => !isSystemFile(entry.fileName) && !isChannelFile(entry.fileName));
    Logger.addMessage(`Anonymize summary: Folders: ${entrieFolders.length}, System files: ${entriesSystem.length}, Channel files: ${entriesChannels.length}, Unknown files: ${entriesUnknown.length}`);
    let countAll = entriesSystem.length + entriesChannels.length + entrieFolders.length + entriesUnknown.length + 1; // +1 = log.txt
    // if(this.anonymizationRequired) countAll += 1; // +1 = anonymizationKey.json
    const counter = new ProgressCounter(countAll, progressCallback);
    const zipWriter = new zip.ZipArchiveWriter({ shareMemory: false, comment: this.comment });
    const writer = (this.outputFileHandle) ? new AnonymizerWriterFileAPI(zipWriter, this.zipCompression, this.outputFileHandle) : new AnonymizerWriter(zipWriter, this.zipCompression);
    const encoder = new TextEncoder();
    return new Promise(async (resolve, reject) => {
      const from = (new Date()).getTime(); //@DEBUG - remove
      const directorySeparator = '/';
      if(!this.outputFileHandle)
      {
        const outputZIP: Uint8Array[] = [];
        const outputZIPBuffer: Uint8Array[] = [];
        
        zipWriter.on('data', (data: any) => {
          outputZIPBuffer.push(data);
          if(outputZIPBuffer.length < 250)
          {
            return;
          }
          outputZIP.push(concatUint8(outputZIPBuffer));
          outputZIPBuffer.length = 0;
          return;
        });
        zipWriter.on('end', () => {
          if(outputZIPBuffer.length > 0)
          {
            outputZIP.push(concatUint8(outputZIPBuffer));
            outputZIPBuffer.length = 0;
          }
          resolve(new Blob(outputZIP));
          outputZIP.length = 0;
          counter.count();
        });
      }
      // System entries
      for(let entry of entriesSystem)
      {
        try
        {
          let dataJSON = await zipReader.readFileAsText(entry.fileName);
          let entryOut = await this.anonymizeSystemEntry(entry, dataJSON);
          await writer.write(entry.fileName, encoder.encode(entryOut.data as string));
        } 
        catch(error)
        {
          Logger.addMessage(`Error while processing System entry "${entry.fileName}": ${getError(error)}`);
        }
        finally
        {
          counter.count();
        }
      }

      // Folders map
      const filteredFolders: string[] = [];
      for(let path of entrieFolders)
      {
        counter.count();
        // Add filterdFolders entry if filterChannelsList AND match
        if(filterChannelsList && filterChannelsList.filter(filterChannelRE => filterChannelRE.test(path)).length > 0)
        {
          filteredFolders.push(path);
        }
        const team = getTeamByPath(path);
        if(!(team in folderMap)) folderMap[team] = {};
        const directoryParts = path.split(directorySeparator);
        const folder = directoryParts[directoryParts.length - 1];
        if(folder in this.channelsNames2Ids[team])
        {
          let directoryPartsMapped = [...directoryParts];
          directoryPartsMapped[directoryPartsMapped.length - 1] = this.channelsNames2Ids[team][folder];
          folderMap[team][folder] = directoryPartsMapped.join(directorySeparator);
          continue;
        }
        // Encrypt folder with separator preserve
        const directoryPartsEncrypted: string[] = [];
        const isTeamsFolder = directoryParts[0] === 'teams';
        if(isTeamsFolder && path.replace(TEAM_REGEX, '') === '')
        {
          // Skip teams group folders
          continue;
        }
        for(let i = 0, len = directoryParts.length; i < len; i++)
        {
          const directoryPartEncrypted = isTeamsFolder && i < 2 ? directoryParts[i] : directoryParts[i]; //@TODO - DISABLED await this.encrypt(directoryParts[i]);
          directoryPartsEncrypted.push(directoryPartEncrypted);
        }
        const folderEncrypted = directoryPartsEncrypted.join(directorySeparator);
        const folderNormalized = isTeamsFolder ? path.replace(TEAM_REGEX, '') : path;
        folderMap[team][folderNormalized] = folderEncrypted;
      }

      // Channel entries
      let channelCounter = this.channelsDelaysCount;
      for(const entry of entriesChannels)
      {
        let fileName = entry.fileName;
        let dataJSON = '';
        let dataOut = '';
        let filteredEntry = false;
        try
        {
          const directoryParts = fileName.split(directorySeparator);
          if(directoryParts.length < 2) throw new Error(`Invalid Channel entry`);
          const filePart = directoryParts[directoryParts.length - 1];
          directoryParts.length = directoryParts.length - 1;
          const directory = directoryParts.join(directorySeparator);
          if(filterChannelsList && filteredFolders.includes(directory))
          {
            filteredEntry = true;
            throw new Error(`Filtered channel`);
          }
          const team = getTeamByPath(fileName);
          const isTeamsFolder = directoryParts[0] === 'teams';
          const directoryNormalized = isTeamsFolder ? directory.replace(TEAM_REGEX, '') : directory;
          dataJSON = await zipReader.readFileAsTextByIndex(entry.index);
          dataOut = await this.anonymizeChannelEntry(entry, dataJSON);
          if(!(team in folderMap) || !(directoryNormalized in folderMap[team]))
          {
            throw new Error(`Invalid channel folder - not found in folderMap`);
          }
          fileName = [folderMap[team][directoryNormalized], filePart].join(directorySeparator);
        }
        catch(error) 
        {
          if(filteredEntry)
          {
            Logger.addMessage(`Filtered Channel entry "${entry.fileName}"`);  
          }
          else
          {
            Logger.addMessage(`Error while processing Channel entry "${entry.fileName}": ${getError(error)}`);
            dataOut = JSON.stringify(`Unknown channel filename ${entry.fileName}: ${ANONYMIZED_STR}`);
          }
        }
        finally
        {
          filteredEntry === false && await writer.write(fileName, encoder.encode(dataOut));
          if(this.channelsDelays && --channelCounter === 0)
          {
            await delay(100);
            channelCounter = this.channelsDelaysCount;
          }
          counter.count();
        }
      }

      // Unknown entries
      for(const entry of entriesUnknown)
      {
        const dataOut = JSON.stringify(`Unknown filename ${entry.fileName}: ${ANONYMIZED_STR}`);
        await writer.write(entry.fileName, encoder.encode(dataOut));
      }

      const to = (new Date()).getTime(); //@DEBUG - remove
      Logger.addMessage(`Anonymization FINISH! processed in: ${((to - from) / 1000)} seconds, time: ${(new Date()).toISOString()}`);
      try
      {
        await writer.write('log.txt', encoder.encode(Logger.getMessages()));
        // anonymizationKeyDerivate.json
        if(this.anonymizationRequired)
        {
          const anonymizationKeyDerivate = { ...userKey.getAnonymizationSettingsDomains() }
          const anonymizationKeyDerivateJSON = JSON.stringify(anonymizationKeyDerivate);
          await writer.write('anonymizationKeyDerivate.json', encoder.encode(anonymizationKeyDerivateJSON));
        }
        await writer.writeEnd();
        if(this.outputFileHandle)
        {
          resolve(null);
        }
      }
      catch(error)
      {
        const errorMsg = (error && (typeof error === 'object' && error !== null) && ('message' in error)) ? (error as any).message : error;
        Logger.addMessage(`Anonymization log.txt and End error: "${errorMsg}"!`);
        reject(errorMsg);
      }
    })
  }
}
