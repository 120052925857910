import * as React from 'react';
import { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Button, Container, defaultTheme, Header, Screen } from '../../components';
import TableChartOutlined from '@material-ui/icons/TableChartOutlined';
import { ContentType, lang, publicURL } from '../../libs';
import { historyHashPush, RouteList } from '../../screens'
import { userKey } from '../../user';
import { theme } from '../../components/ui';

type SourceProps = RouteComponentProps<{
}> & {}

export const Source: React.FC<SourceProps> = ({
  history
}) => {

  const handleClick = useCallback((type: ContentType, anonymizationRequired: boolean) => {
    userKey.setType(type);
    userKey.setAnonymizationRequired(anonymizationRequired);
    const WelcomeRoute = RouteList.welcome;
    historyHashPush(WelcomeRoute.path, history);
  }, [history])

  return (
    <Container>
      <Header title={lang.title} />
      <Screen topOffset={false}>
        <Wrapper>
          <SourceHeader>Remove Content</SourceHeader>
          <SourceOuter>
            <SourceFlex>
              <img src={`${publicURL}/images/logo-slack.svg`} alt='Slack' width={48} height={48} />
              <SourceDescriptionWrapper>
                <SourceType>Slack</SourceType>
                <SourceDescription>Remove communication content on Slack WITHOUT anonymizing users and channels</SourceDescription>
              </SourceDescriptionWrapper>
            </SourceFlex>
            <ButtonWrapper>
              <Button onClick={() => handleClick('slack', false)} gapBefore={false} gapAfter={false} text={lang.titleSelectSource} disabled={false} theme={defaultTheme.buttons.purple} />
            </ButtonWrapper>
          </SourceOuter>
          <SourceHeader marginTop={28}>Anonymize Personally Identifiable Information</SourceHeader>
          <SourceOuter>
            <SourceFlex>
              <img src={`${publicURL}/images/logo-slack.svg`} alt='Slack encrypted' width={48} height={48} />
              <SourceDescriptionWrapper>
                <SourceType>Slack encrypted</SourceType>
                <SourceDescription>Remove communication content on Slack AND anonymize users and channels</SourceDescription>
              </SourceDescriptionWrapper>
            </SourceFlex>
            <ButtonWrapper>
              <Button onClick={() => handleClick('slack', true)} gapBefore={false} gapAfter={false} text={lang.titleSelectSource} disabled={false} theme={defaultTheme.buttons.purple} />
            </ButtonWrapper>
          </SourceOuter>
          <SourceOuter>
            <SourceFlex>
              <img src={`${publicURL}/images/logo-zoom.svg`} alt='Zoom encrypted' width={48} height={48} />
              <SourceDescriptionWrapper>
                <SourceType>Zoom encrypted</SourceType>
                <SourceDescription>Anonymize your calls from Zoom</SourceDescription>
              </SourceDescriptionWrapper>
            </SourceFlex>
            <ButtonWrapper>
              <Button onClick={() => handleClick('zoom', true)} gapBefore={false} gapAfter={false} text={lang.titleSelectSource} disabled={false} theme={defaultTheme.buttons.purple} />
            </ButtonWrapper>
          </SourceOuter>
          <SourceOuter>
            <SourceFlex>
              <IconWrapper>
                <TableChartOutlined fontSize={'inherit'} />
              </IconWrapper>
              <SourceDescriptionWrapper>
                <SourceType>Table</SourceType>
                <SourceDescription>Anonymize your emails from Excel or CSV file</SourceDescription>
              </SourceDescriptionWrapper>
            </SourceFlex>
            <ButtonWrapper>
              <Button onClick={() => handleClick('table', true)} gapBefore={false} gapAfter={false} text={lang.titleSelectSource} disabled={false} theme={defaultTheme.buttons.purple} />
            </ButtonWrapper>
          </SourceOuter>
        </Wrapper>
      </Screen>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding-top: 50px;
  background-color: #F8F9FB;
  min-height: calc(100% - 54px);
  overflow: auto;
`;

const SourceHeader = styled.h3.withConfig<{ marginTop?: number; }>({
  shouldForwardProp: prop => !['marginTop'].includes(prop),
})`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: ${({ marginTop }) => marginTop ?? '0'}px;
`;

const SourceOuter = styled.div`
  width: 740px;
  height: 106px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  border-radius: 5px;

  @media (max-width: 900px) {
    width: auto;
    height: auto;
  }
`;

const SourceDescriptionWrapper = styled.div`
  margin-left: 18px;
  display: flex;
  flex-direction: column;
`;

const SourceType = styled.h4`
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const SourceDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #83869C;
`;

const SourceFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const IconWrapper = styled.div`
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  color: ${theme.colors.purple};
`;

const ButtonWrapper = styled.div`
  display: block;
`;