import React, { FC } from 'react';

interface Prop {
  w?: number;
  h?: number;
}

const places: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
const empty: number = 4;

export const BoxLoader: FC<Prop> = ({ 
  w = 50,
  h = 50,
}) => {

  const itemW = (w / 3) - 2;
  const itemH = (h / 3) - 2;
  return (
    <div className='box-loader-wrapper'>
    {places.map((item: any, index: number) => {
      const className = index === empty ? 'empty' : 'item';
      return <div className={`${className} step-${index}`} key={`loader-item-${index}`} />;
    })}
    <style>{`
      .box-loader-wrapper {
        width: ${w}px;
        height: ${h}px;
        top: 0;
        left: 0;
        overflow: hidden;
      }
      
      .box-loader-wrapper > div {
        float: left;
        width: ${itemW}px;
        height: ${itemH}px;
        margin: 0 1px 1px 0;
      }

      .box-loader-wrapper > div.item {
        background-color: #EAEDF3;
      }

      .box-loader-wrapper > div.empty {
        background-color: #FFFFF;
      }

      .box-loader-wrapper > div.active {
        background-color: #216398;
      }

      .box-loader-wrapper > div.item {
        animation: loaderAnim 2.0s infinite;
        animation-timing-function: steps(1, end);
        animation-fill-mode: forwards;
      }

      .box-loader-wrapper > div.step-0 {
        animation-delay: 0s;
      }

      .box-loader-wrapper > div.step-1 {
        animation-delay: 0.25s;
      }

      .box-loader-wrapper > div.step-2 {
        animation-delay: 0.5s;
      }

      .box-loader-wrapper > div.step-5 {
        animation-delay: 0.75s;
      }

      .box-loader-wrapper > div.step-8 {
        animation-delay: 1.0s;
      }

      .box-loader-wrapper > div.step-7 {
        animation-delay: 1.25s;
      }

      .box-loader-wrapper > div.step-6 {
        animation-delay: 1.5s;
      }

      .box-loader-wrapper > div.step-3 {
        animation-delay: 1.75s;
      }

      @keyframes loaderAnim {
        0%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {
          background-color: #EAEDF3;
        }
        10% {
          background-color: #216398;
        }
      }

    `}</style>
    </div>
  )

};